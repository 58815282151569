@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.grid-section {
  background-color: $white;
  padding: 4% 0;
  // float: left;
  width: 100%;
  .row {
    margin: 0;
  }
  .grid-img {
    padding: 4%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    div {
      max-height: 100%;
      width: 100%;
      img {
        z-index: 999999;
        width: 100%;
        height: auto;
        max-width: 459px;
        max-height: 454px;
        object-fit: cover;
      }
    }
  }

  .grid-img:nth-child(1) div,
  .grid-img:nth-child(5) div {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: url(../../assets/images/border.jpg);
      width: 28px;
      //right: -28px;
      height: 80%;
      z-index: 111111;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  .grid-img:nth-child(5) div {
    &::after {
      background: url(../../assets/images/color-border.jpg);
    }
  }

  .grid-info {
    padding: 0 2%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(2n) {
      padding: 4% 5% 4% 4%;
    }

    &:nth-child(2) {
      padding-left: 3%;
    }

    img {
      width: 76px;
      height: 55px;
      padding: 0;
      margin-bottom: 40px;
      object-fit: contain;
    }
    .heading-section {
      text-align: unset;
      width: unset;
      margin: unset;
    }
    .main-title {
      font-family: $font-primary;
      font-size: 48px;
      color: $black-new;
      word-break: break-word;
      margin: 0;
      letter-spacing: 0px;
      font-weight: 500;
      line-height: 50px;
      padding: 0;
    }
    .gray-color {
      color: $gray-new;
    }
    .title-info {
      font-size: 18px;
      color: #545454;
      margin-bottom: 0;
      letter-spacing: 0px;
      line-height: 20px;
      padding-top: 10px;
      p {
        margin: 0;
      }
    }
  }
  .learn-more {
    color: #e5721f;
    border-bottom: 2px solid #e5721f;
    font-size: 18px;
    font-family: $atten-font;
    font-weight: $font-atten-book;

    padding-top: 30px;
    display: inline-block;
    letter-spacing: 3.6px;
    line-height: 30px;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
  @include max-width($desktop-xl) {
    .grid-info {
      .main-title {
        font-size: 52px;
        line-height: 60px;
        letter-spacing: 0px;
      }
    }
  }
  @include max-width($desktop-large) {
    .learn-more {
      font-size: 18px;
      letter-spacing: -1px;
      line-height: 24px;
    }

    .grid-info {
      img {
        width: 76px;
        height: 55px;
        margin-bottom: 30px;
      }
      .main-title {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  @include max-width($macbook) {
    .grid-img {
      height: 433px;
    }
  }
  @include max-width($tablet-landscape) {
    .grid-img {
      height: 380px;
      img {
        min-height: 290px;
      }
    }
    .grid-info {
      .main-title {
        font-size: 35px;
        line-height: 40px;
      }
    }
  }
  @include max-width($iphonex-landscape) {
    .grid-img {
      height: 390px;
      overflow: hidden;
    }
    .grid-info {
      img {
        width: 69px;
        height: 51px;
      }
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .grid-img {
      padding: 15% 4%;
      img {
        height: 100%;
        min-height: auto;
      }
    }

    .grid-info {
      .main-title {
        font-size: 32px;
        line-height: 34px;
      }
    }
  }
  @include max-width($iphone6-landscape) {
    .grid-img:nth-child(1) div,
    .grid-img:nth-child(5) div {
      &::after {
        height: 95%;
        top: 65%;
      }
    }
  }

  @include max-width($iphone4-landscape) {
    .grid-img {
      height: auto;
     // padding: 12% 10% 5% 1%;
    }
    .grid-info {
    //   min-height: 350px;
    //   padding: 5% 1%;
      img {
        width: 66px;
        height: 43px;
        margin-bottom: 20px;
      }
    }
    .grid-img:nth-child(1) div,
    .grid-img:nth-child(5) div {
      &::after {
        height: 80%;
        top: 50%;
      }
    }
  }
 
}
