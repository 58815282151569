@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.neighborhood-main {
  .residence-card-section {
    .sectiontitle-main-div {
      .sectiontitle-headline {
        font-size: 48px !important;
        line-height: 70px;
        color: $black-new;
      }
    }
  }
}

// .global-main {
//   .residence-card-section {
//     .sectiontitle-main-div {
//       padding: 1% 0%;
//       max-width: 1640px;
//       margin: 0 auto;
//     }
//   }
// }
.residence-card-section {
  background-color: $white;
  // float: left;
  width: 100%;
  padding: 5% 0 2%;
  .sectiontitle-main-div {
    padding: 2.8% 0%;
    max-width: 1640px;
    margin: 0 auto;
    .sectiontitle-headline {
      font-size: 68px !important;
      line-height: 70px;
      color: $black-new;
    }
  }
  .heading-section {
    justify-content: left;
    padding: 0.5% 5% 0;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    .main-title {
      font-size: 68px;
      line-height: 70px;
    }
  }
  .plan-card {
    max-width: 1640px;
    width: 100%;
    // padding: 2.6% 4% 0;
    // padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    .card-main-div {
      margin-bottom: 4%;
      // height: 735px;
      width: 30.48%;
      position: relative;
      // padding-right: 50px;
      // &:last-child {
      //     padding-right: 0px;
      // }
      .card-imgs {
        position: relative;
        padding: 25px; // overflow: hidden;
        z-index: 1;
        height: 583px;
        .plan-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .owl-carousel {
            .owl-item {
              overflow: hidden;
            }
            .owl-nav {
              display: none;
              position: absolute;
              bottom: 40px;
              right: 30px;
              color: $white;
              z-index: 2;
              width: 70px;
              height: 25px;
              button {
                &:after {
                  position: absolute;
                  content: "";
                  width: 8px;
                  height: 8px;
                  border-right: 2px solid $white;
                  border-bottom: 2px solid $white;
                  transform: rotate(-45deg);
                  top: 43%;
                  right: 0;
                }
                &:before {
                  position: absolute;
                  content: "";
                  top: 55%;
                  height: 2px;
                  width: 16px;
                  background: $white;
                  right: 0;
                }
                span {
                  display: none;
                }
              }
              .owl-prev {
                &:after {
                  transform: rotate(135deg);
                  left: 25%;
                }
                &:before {
                  left: 25%;
                }
              }
            }
            .owl-dots {
              display: none;
              .owl-dot {
                span {
                  background: $gray-new;
                }
                &.active {
                  span {
                    background: $orange;
                  }
                }
              }
            }
          }
          .overlay {
            height: 100%;
            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
            &:after {
              content: "";
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background: transparent
                linear-gradient(180deg, #00000000 0%, #000000 300%) 0% 0%
                no-repeat padding-box;
            }
          }
        }
        .card-snipe {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 100%;
          padding: 8px;
          background-color: $orange;
          font-family: $font-primary;
          color: $white;
          font-size: 12px;
          text-align: center;
          text-transform: uppercase;
        }
        .card-status {
          background: #febf3b;
          padding: 7px 10px;
          display: inline-block;
          font-size: 12px;
          font-family: $arquitecta-heavy;
          color: $black-new;
          line-height: 14px;
          margin-bottom: 20px;
          text-transform: uppercase;
          letter-spacing: 1.2px;
          &.status_gray {
            background: $black-new;
            color: $white;
          }
        }
        .card-img-info {
          position: absolute;
          z-index: 7;
          bottom: 40px;
          color: $white;
          padding-right: 10px;
          width: 70%;
          .card-name {
            font-size: 38px;
            letter-spacing: 0px;
            line-height: 38px;
            margin-bottom: 5px;
            font-family: $font-primary;
          }
          .card-price {
            font-size: 18px;
            line-height: 22px;
            margin: 0;
          }
        }
      }
      .owl-carousel,
      .owl-stage-outer,
      .owl-stage,
      .owl-item,
      .owl-item div {
        height: 100%;
      }
      .card-info {
        .card-detail {
          display: flex;
          justify-content: space-evenly;
          margin: 0;
          padding: 30px 0 10px;
          align-items: center;
          width: 100%;
          li {
            display: flex;
            flex-direction: column;
            width: 100%;
            list-style: none;
            border-right: 1px solid $lightgray;
            padding: 10px 5px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            text-align: center;
            color: $black-new;
            &:last-child {
              border-right: none;
            }
            .info-value {
              font-size: 16px;
              font-family: $arquitecta-heavy;
              margin-bottom: 5px;
              color: #545454;
            }
            .info-name {
              font-size: 14px;
              font-family: $arquitecta-bold;
              color: #969696;
            }
          }
        }
      }
      .card-hover-content {
        box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
        visibility: hidden;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        width: 100%;
        // margin-right: 15px; // transition: height 0.5s;
        .plan-card-content {
          padding: 7%;
          .card-name {
            font-size: 22px;
            font-family: $font-primary;
            width: 80%;
          }
          .card-text {
            min-height: 96px;
          }
          .learn-more {
            border: 1px solid $new-border-color;
            margin-top: 8%;
            color: $black-new;
            padding: 2% 10%;
            text-align: center;
            font-family: $atten-font;
            font-weight: $font-atten-bold;

            font-size: 14px;
            display: flex;
            justify-content: center;
            &:hover {
              text-decoration: none;
              color: $white;
              background: $orange;
            }
          }
        }
      }
      &:hover {
        .card-imgs {
          .plan-img-info {
            display: none;
          }
        }
        .owl-nav {
          display: block !important;
          bottom: -60px !important;
          button {
            &:after {
              border-right: 2px solid $black-new !important;
              border-bottom: 2px solid $black-new !important;
            }
            &:before {
              background: $black-new !important;
            }
          }
        }
      }
      @include max-width($desktop-xl) {
        height: 682px;
        .card-imgs {
          height: 600px;
        }
        &:hover {
          .owl-nav {
            bottom: -55px !important;
          }
        }
      }
      @include max-width($desktop-large) {
        height: 576px;
        .card-imgs {
          height: 480px;
        }
        &:hover {
          .owl-nav {
            bottom: -50px !important;
          }
        }
        .card-info {
          .card-detail {
            li {
              .info-value {
                font-size: 12px;
              }
              .info-name {
                font-size: 9px;
              }
            }
          }
        }
      }
      @include max-width($macbook) {
        .card-info {
          .card-detail {
            li {
              .info-value {
                font-size: 11px;
              }
            }
          }
        }
      }
      @include max-width($tablet-landscape) {
        height: auto;
        .card-imgs {
          height: 442px;
          .card-img-info {
            .card-name {
              font-size: 26px;
              line-height: 30px;
            }
          }
          .plan-img {
            .owl-carousel {
              .owl-nav {
                display: none !important;
              }
              .owl-dots {
                display: block;
                position: absolute;
                text-align: center;
                width: 100%;
                bottom: 0;
                .owl-dot {
                  outline: none !important;
                  span {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    margin: 5px;
                  }
                }
              }
            }
          }
          .card-snipe {
            color: $black-new;
          }
        }
        .card-hover-content {
          display: none !important;
        }
        &:hover {
          .card-info {
            display: block !important;
          }
          .card-imgs {
            .plan-img-info {
              display: block !important;
            }
          }
          .card-hover-content {
            display: none !important;
          }
        }
        .card-info {
          .card-detail {
            box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
            padding: 10px 0 10px;
            li {
              border: none;
              padding: 15px 0px;
              width: auto;
              letter-spacing: normal;
              .info-value {
                font-size: 10px;
              }
              .info-name {
                font-size: 8px;
              }
            }
          }
        }
      }
      @include max-width($iphonex-landscape) {
        margin-bottom: 8%;
        width: 48%;
        &:nth-child(2n-1) {
          margin-right: 20px;
        }
      }
      @include max-width($iphone4-landscape) {
        margin-bottom: 16%;
        padding: 0;
        .card-imgs {
          height: 430px;
        }

        .card-info {
          .card-detail {
            li {
              .info-value {
                font-size: 14px;
              }
              .info-name {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    @include max-width($desktop-xl) {
      padding: 0 32px;
    }
    @include max-width($iphone4-landscape) {
      padding: 0.5% 10% 5%;
    }
    @include max-width($iphonex-landscape) {
      justify-content: center;
      padding: 0 4%;
    }
  }
  @include max-width($desktop-xl) {
    .sectiontitle-main-div {
      padding: 2% 34px;
    }
  }
  @include max-width($macbook) {
    .heading-section {
      .main-title {
        font-size: 48px;
        line-height: 50px;
      }
    }
    .sectiontitle-main-div {
      .sectiontitle-headline {
        font-size: 38px !important;
        line-height: 48px;
      }
    }
  }
  @include max-width($iphonex-landscape) {
    .sectiontitle-main-div {
      padding: 1.5% 5%;
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .heading-section {
      padding: 0.5% 2% 0;
      .main-title {
        font-size: 32px;
        line-height: 34px;
      }
    }
    .plan-card {
      padding: 0.5% 2% 5%;
      .card-main-div {
        width: 100%;
        &:nth-child(2n-1) {
          margin-right: 0px;
        }
        .card-imgs {
          .card-img-info {
            .card-name {
              font-size: 24px;
              line-height: 24px;
            }
            .card-price {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .sectiontitle-main-div {
      padding: 1.5% 2.3%;
      .sectiontitle-headline {
        font-size: 38px !important;
        line-height: 48px;
      }
    }
  }
}
@include max-width($iphone4-landscape) {
  .neighborhood-main {
    .residence-card-section {
      .sectiontitle-main-div {
        .sectiontitle-headline {
          font-size: 38px !important;
          line-height: 48px;
        }
      }
    }
  }
}
.load-more {
  display: block;
  border: 0;
  margin: 0px auto 20px;
  outline: none !important;
  padding: 10px 20px;
  font-size: 16px;
  background-color: $black-new;
  color: $white;
  text-transform: uppercase;
  font-family: $font-primary;
  cursor: pointer;
}
