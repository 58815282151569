@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.generic-content-block{
    float: unset;
}
.generic-main-div{
    display:flex;
    background:$bggray-new;
    align-items: center;
    // background: linear-gradient( 90deg, #0F7398 50%, #f7f7f7 50%);

    &.border {
        margin: auto;
        width: 90%;
        .generic-content{
            .generic-content-section{              
                .generic-description{
                    color: $black-new;
                }
                .generic-button{
                    @include orange-learn-more;
                }
            }
        }
    }
    .generic-image{
        height: 960px;
    }
    .generic-content{
        padding:8% 5%; 
        position: relative;
        background-repeat: no-repeat;
        background-position: left center;
        .generic-content-section{
            width:75%;
            padding-left: 10%;
            // padding-top: 20%;
            .heading-section {
                width: 100%;
                text-align: left;
             }
            .generic-description{
                font-size: 18px;
                color: $graytext;
                padding-bottom: 30px;
                letter-spacing: 0px;
                line-height: 20px;
                p{
                  margin: 0;
                }
            }
            .generic-button{
                @include white-learn-more;
                text-decoration: none;
                min-width: 314px;
                display: inline-block;
                background-color: $white;
                margin-top: 20px;
                &.no-link{
                    pointer-events: none;
                }
                &:hover{
                    color: $white;
                    background-color:$orange; 
                    border:1px solid $white;
                }
            }
        }
    }
    .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50% ;
        background-position: left center;
        background-size: 40px 80%;
        img {
            max-width: 100%;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .divide-equal:first-child:nth-last-child(2) ~ div {
        background: $white;
    }
    .divide-equal:first-child:nth-last-child(1) {
        width: 100% ;
    }
    @include max-width($desktop-xl) {
        .generic-image{
            height: 780px;
        }
    }
    @include max-width($desktop-large) {
        .generic-image{
            height: 720px;
        }
        .generic-content{
            .generic-content-section{
                    .generic-button {
                        min-width: 250px;
                        letter-spacing: 2px;
                    }
                }   
        }
        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
            background-size: 30px 80%;
        }
    }
    @include max-width($tablet-landscape) {
        margin: 5% -15px;
        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
            background-size:15px 90%;
        }
        
        .generic-image{
            height: 640px;
        }
        .generic-content{
            .generic-content-section{
                width:80%;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        flex-wrap: wrap;
        // padding: 10% 5%;

        &.border {
            width: 98%;
        }
        
        .generic-image{
            width:100% !important;
            padding: 0;
            height: 300px; 
        }
        .generic-content{
            width:100% !important;
            order:1;
            padding: 10% 5%;
            .generic-content-section{
                width:100%;
                padding-top:0%; 
                padding-left: 5%;
                .generic-description{
                    padding-bottom: 20px;
                }
            }   
        }

    }

    @include max-width($iphone6-plus-landscape) {
        .generic-content{
            .generic-content-section{
                .generic-button{
                    min-width: auto;
                }
            }
        }
        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
            background-size:15px 90%;
        }
    }
    @include max-width($iphone4-landscape) {
        // padding: 15% 5%;
            .generic-image{
                height: 280px; 
            }
            .generic-content{
                padding: 15% 10%;
                .generic-button{
                    width:100%;
                }
            }
        }
}







