@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.neigh-card-section{
    background-color: $white;
    padding: 5% 0 2%;
    .heading-section {
        padding: 0;
    }

    .sectiontitle-main-div {
        .sectiontitle-headline {
            font-size:48px;
            line-height:70px;
            color: $black-new;
        }
    }
    .neigh-card{
        padding: 3% 5%;
        padding-top: 0;
        .card-main-div{
            padding-bottom: 5%;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            &:last-child{
                padding-bottom: 0;
            }
            .card-imgs{
                width: 55%;
                height:609px;
                position: relative;
                .card-img-div{
                    height: 100%;
                    &:after{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: transparent linear-gradient(180deg, #00000000 0%, #000000 300%) 0% 0% no-repeat padding-box;
                    }
                }
                .plan-img{
                    position: absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                    .owl-carousel {
                        .owl-item{
                            overflow: hidden;
                        }
                        .owl-nav {
                            position: absolute;
                            bottom: 40px;
                            right: 40px;
                            color: white;
                            z-index: 2;
                            button{
                                &:after{
                                    position: absolute;
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    border-right: 2px solid $white;
                                    border-bottom: 2px solid $white;
                                    transform: rotate(-45deg);
                                    top: 35%;
                                   }
                                &:before {
                                    position: absolute;
                                    content: '';
                                    top: 56%;
                                    height: 2px;
                                    width: 20px;
                                    background: white;
                                }
                                span{
                                   display: none;
                                }
                            }
                            .owl-prev{
                                &:after{
                                    transform: rotate(135deg);
                                    left: -35px;
                                }
                                &:before {
                                    left: -35px;
                                }
                            }
                            .owl-next{
                                &:before {
                                   right: -7px;
                                }
                            }
                        }
                    }
                    // .overlay{
                    //     height: 100%;
                    //     transition: 0.5s;
                    //     transform: scale(1.5);
                    //     &:hover{
                    //         transform: scale(1);
                    //         transition: 0.5s;
                    //     }
                    //     &:after {
                    //         background: transparent linear-gradient(181deg, #00000000 0%, #000000D9 100%) 0% 0% no-repeat padding-box;
                    //         content: "";
                    //         position: absolute;
                    //         z-index: 1;
                    //         top: 0;
                    //         left: 0;
                    //         bottom: 0;
                    //         right: 0;
                    //     }
                    // }
                }
                .card-snipe{
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    padding: 8px;
                    background-color: $orange;
                    
                    color: $white;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                }
                .promo-icon{ 
                    height:70px; 
                    width:70px; 
                    position: absolute; 
                    z-index: 2; 
                    top: 0; 
                    left: 0; 
                    background-repeat: no-repeat;
                }
                .card-img-info {
                    position: absolute;
                    z-index: 7;
                    bottom: 30px;
                    color:$white;
                    padding-right: 10px;
                    width: 70%;
                    padding-left: 5%;
                    .card-name {
                        font-size: 38px;
                        line-height: 51px;
                    }
                    .card-price {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }
            }
            .owl-carousel,.owl-stage-outer,.owl-stage,.owl-item,.owl-item div{
                height: 100%;
            }
            .card-info{
                width: 45%;
                padding: 2% 4%;
                .card-status-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    .card-status{
                        color: $gray-new;
                        font-size: 21px;
                        margin-bottom: 0;
                        font-family: $arquitecta-heavy;
                        text-transform: uppercase;
                    }
                    .tour-img{
                        border: 1px solid $bordergray;
                        border-radius: 22px;
                        padding: 8px 20px;
                        font-size: 14px;
                        
                        color: $black-new;
                        margin-bottom: 1rem;
                        text-decoration: none;
                        margin-bottom: 0;
                        img{
                            width: 20px;
                            height: 19px;
                            margin-right: 8px;
                        }
                    }
                }
                .card-name{
                    font-size: 50px;
                    
                    color: $black-new;
                }
                .card-price{
                    font-size: 21px;
                    
                    color: $black-new;
                    margin: 0;
                }
                .card-detail{
                    display: flex;
                    padding: 20px 0;
                    margin: 0;
                    text-align: center;
                    li{
                        list-style: none;
                        width: 50%;
                        color: $black-new;
                        border-right: 1px solid $new-border-color;
                        padding: 5px 0;
                        line-height: 18px;
                        .card-icon{
                            font-weight: bold; 
                            display: block;
                            font-family:$arquitecta-heavy;
                            letter-spacing: 0.5px;
                            font-size: 16px;
                            color: #545454;
                        }
                        .feature-name {
                            text-transform: uppercase;
                            margin-top: 5px;
                            display: inline-block;
                            color: $gray-new;
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            font-family:$arquitecta-heavy;
                            color: #969696;
                        }
                        &:last-child {
                            border: none !important;
                        }
                    }
                }
                .card-text{
                    font-size: 20px;
                    color: $black-new;
                    padding-top: 5%;
                    padding-bottom: 10%;
                }
                .learn-more{
                     display: inline-block;
                     text-align: center;
                     color: $orange;
                     border: 2px solid $orange;
                     text-decoration: none;
                     padding: 3% 15%;
                     text-transform: uppercase;
                     font-size:16px;
                     letter-spacing: 3.2px;
                     line-height: 25px;
                     font-family: $arquitecta-heavy;
                     transition: all .5s ease;
                     &:hover{
                        color: $white;
                        background: $orange;
                     }
                }
            }
            @include max-width($desktop-large) {
                .card-imgs{
                    height:480px;
                    .card-img-info {
                        .card-name {
                            font-size: 40px;
                            line-height: 48px;
                        }
                        .card-price {
                            font-size: 18px;
                            line-height: 21px;
                        }
                    }
                }
                .card-info{
                    .card-status-info {
                        .card-status{
                            font-size: 14px;
                        }
                    }
                    .card-name{
                        font-size: 40px;
                    }
                    .card-price{
                        font-size: 17px;
                    }
                    .learn-more{
                        padding: 3% 15%;
                    }
                    .card-text{
                        padding-top: 0;
                        padding-bottom:5%;
                        font-size: 16px;
                     }
                }

            }
            @include max-width($tablet-landscape) {
                .card-imgs{
                    .card-img-info {
                        .card-name {
                            font-size: 30px;
                            line-height: 40px
                        }
                    }
                }
                .card-info{
                    .card-name{
                        font-size: 35px;
                    }
                    .card-detail{
                        li {
                            .card-icon{
                                font-size: 10px;
                            }
                            .feature-name {
                                font-size: 8px;
                            }
                        }
                    }
                 
                }

            }
            @include max-width($iphonex-landscape) {
                .card-imgs{
                    width: 100%;
                    height: 380px;
                }
                .card-info{
                    width: 100%;
                    padding: 40px 6%;
                    .card-name{
                        font-size: 40px;
                    }
                    .card-price{
                        font-size: 18px;
                    }
                    .card-text{
                        font-size: 16px;
                    }
                    .card-detail{
                        padding: 25px 0;
                        margin: 0;
                        li {
                            .feature-name {
                                font-size: 9px;
                            }
                        }
                    }
                    .learn-more {
                        width: 100%;
                    }
                }
            }
            @include max-width($iphone4-landscape) {
                .card-imgs{
                    height: 252px;
                }
            }
        }
        @include max-width($iphonex-landscape) {
            padding: 3% 0;
        }
    }

    @include max-width($desktop-large) {
        .sectiontitle-main-div {
            .sectiontitle-headline {
                font-size:35px;
                line-height:45px;
            }
        }
    }
    
    @include max-width($tablet-landscape) {
        padding: 5% 0 5%;
        .heading-section {
           display: none;
        }
    }
}