@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.residence-top {
  padding-top: 0px !important;
}
.residence-main-div {
  height: calc(100vh - 229px);
  .residence-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
    .neigh-name {
      display: flex;
      align-items: center;
      position: relative;
      text-decoration: none;
      width: 25%;
      i {
        color: #E5721F;
        font-size: 16px;
        padding: 10px;
        border: 1px solid #E5721F;
    
      }
      .neighborhood-name {
        font-size: 20px;
        color: $black-new;
        line-height: 27px;

        padding-left: 15px;
      }
    }
    .residence-nav-bar {
      width: 65%;
      ul {
        display: flex;
        list-style: none;
        margin: 0;
        justify-content: center;
        font-size: 20px;
        color: $black-new;
        line-height: 35px;
        letter-spacing: 4px;
        padding: 0;
        font-family: $arquitecta-bold;
        text-transform: uppercase;
        .plan-link {
          cursor: pointer;
          &.current-active {
            span {
              color: $black-new;
              padding-bottom: 5px;
              border-bottom: 4px solid $orange;
            }
          }
          span {
            color:$gray-new;
            &:hover {
              color: $black-new;
              padding-bottom: 5px;
              border-bottom: 4px solid $orange;
            }
          }
          &:not(:last-child):after {
            display: inline-block;
            content: "";
            border-top: 1px solid $lightgray;
            width: 60px;
            margin: 0 14px;
            transform: translateY(-1rem);
            position: relative;
            top: 10px;
          }
        }
      }
    }
    .plan-icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 15%;
      opacity: 0;

      .residence-btn {
        @include black-outline-button;
        margin-right: 10px;
        padding: 5px 10px !important;
        display: none;
        .resisdence-save-btn {
          color: $black-new;
          &:hover {
            text-decoration: none;
          }
        }
      }
      i.fa.fa-heart-o {
        border-radius: 100%;
        border: 1px solid $lightborder;
        padding: 10px;
        text-align: center;
      }
    }
  }
  .residence-details {
    display: flex;
    margin-top: 108px;
    margin-bottom: 121px;
    height: 100%;
    .residence-image {
      width: 70%;
      background-color: $bggray-new;
      border-right: 1px solid $lightborder;
      overflow: auto;
      padding: 30px 5% 5px;
      .image-div,
      .iframe-div {
        height: 680px !important;
        padding-bottom: 30px;
        iframe {
          width: 100%;
          height: 100%;
          border: none;
          .splash-screen-wrapper {
            .splash-screen-thumbnail {
              background-size: unset !important;
            }
          }
        }
      }

      .owl-carousel,
      .owl-stage-outer,
      .owl-stage,
      .owl-item,
      .owl-item div {
        height: 100%;
      }
      .owl-nav {
        position: absolute;
        top: 50%;
        width: 100%;
        margin: 0;
        margin-top: -12.5px;
        button {
          background: #ffffffb2;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          position: absolute;
          margin: 0;
          outline: none;
          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            border-right: 2px solid #111111;
            border-bottom: 2px solid #111111;
            transform: rotate(-45deg);
            top: 40%;
            right: 20px;
          }
          span {
            display: none;
          }
        }
        .owl-prev {
          left: 10px;
          &:after {
            transform: rotate(135deg);
            left: 18px;
          }
        }
        .owl-next {
          right: 10px;
        }
      }
      .owl-dot {
        outline: none;
      }
    }
    .residence-content {
      width: 30%;
      padding: 3% 40px;
      background-color: $bggray-new;
      overflow: auto;
      .plan-status {
        margin-bottom: 12px;
        text-align: center;
        color: $black-new;
        background-color: #febf3b;
        border: 1px solid $new-border-color;
        box-shadow: none;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        padding: 5px 20px;
        font-family: $arquitecta-heavy;
        display: inline-block;
        &.status_gray {
          background: $black-new;
          color: $white;
        }
      }
      .plan-name {
        font-size: 40px;
        color: $black-new;
        line-height: 45px;
        margin: 0;
      }
      .plan-price {
        font-size: 16px;
        padding-bottom: 7px;
        color: $gray-new;
      }
      .floor_bottomBtn {
        margin-top: 20px;
      }
      .plan-description {
        margin-top: 15px;
        color: $black-new;

        p,
        ul,
        ol,
        span,
        em {
          font-size: 18px;
          padding-top: 10px;
          margin: 0;
          color: $black-new;
          line-height: 20px;
        }
      }
      // .plan-btns {
      //   margin-top: 15px;
      // }
      .residence-btns {
        border: 1px solid $new-border-color;
        padding: 20px 30px;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        .plan-btn {
          color: $new-border-color;
          padding: 0;
          margin: 0;
          font-size: 13px;
          line-height: 25px;
          letter-spacing: 3.9px;
          text-transform: uppercase;
          font-family: $arquitecta-heavy;
          &:hover {
            text-decoration: none;
            color: $black-new;
          }
        }
        &:focus {
          outline: none;
        }
      }
      .interactive-icons {
        padding-left: 0px;
        margin-top: 8px;
        margin-bottom: 0;
        li {
          list-style: none;
          a {
            @include black-outline-button;
            text-transform: uppercase;
            background-color: $white !important;
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            color: $orange !important;
            &:hover {
              color: $white !important;
              background-color: $orange !important;
              border: 1px solid $orange !important;
            }
          }
        }
      }
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  .plan-features {
    display: flex;
    justify-content: space-between;
    color: $white;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    .plan-footer-left {
      display: flex;
      justify-content: space-between;
      width: 70%;
      position: relative;
      background-color: $black-new;
      flex-wrap: wrap;
      .plan-details-container {
        padding: 5px 24px;
        padding-right: 0;
        display: flex;
        align-items: center;
        width: 35%;
        .menu-bar {
          margin-right: 25px;
          position: relative;
          height: 30px;
          width: 30px;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          padding: 8px 0;
          pointer-events: all;
          display: flex;

          > span {
            width: 100%;
            height: 2px;
            background: $white;
            transition: all 0.5s ease;
          }
          &.active {
            padding: 0;

            > span {
              background: $white;

              &:first-child {
                transform: rotate(-45deg) translate(-10px, 10px);
              }

              &:nth-child(2) {
                opacity: 0;
              }

              &:last-child {
                transform: rotate(45deg) translate(-10px, -10px);
              }
            }
          }
        }
        // .plan-details:last-child{
        //     padding-left: 15px;
        //     border-left: 1px solid $new-border-color;
        // }
        .plan-estimate-price {
          display: flex;
          line-height: 19px;
          flex-wrap: wrap;
          justify-content: space-between;
          .estimate-payment {
            border-bottom: 1px solid $bgdarkgray;
            color: $bgdarkgray;
            font-size: 16px;
           
            text-decoration: none;
          }
          .residence-price {
            font-size: 14px;
            padding-right: 10px;
          }
        }
        .residence-name {
          font-size: 30px;
          line-height: 37px;
        }
      }
      .plan-features-list {
        display: flex;
        justify-content: space-around;
        text-align: center;
        align-items: center;
        padding: 24px;
        margin: 0;
        //width:65%;
        li {
          list-style: none;
          border-right: 1px solid $bordergray;
          padding: 5px 25px;
          .feature-value {
            margin: 0;
            font-size: 26px;
            line-height: 40px;
            display: block;
            font-family: $font-primary;
          }
          .feature-name {
            font-family: $arquitecta-bold;
            font-size: 14px;
            line-height: 25px;
            text-transform: uppercase;
            letter-spacing: 2.8px;
            color:$light-gray;
          }
          &:last-child {
            border: none !important;
          }
        }
      }
      .plan-overlay {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        padding: 25px;
        color: $white;
        z-index: 1;
        display: none;
        &.show_overlay {
          display: block;
        }
        .plan_card_slide {
          background-color: $bglightcream;
          color: $black-new;
          padding: 15px;
          height: 100%;
        }
        .owl-stage {
          padding-left: 0 !important;
          display: flex;
        }
        .overlay-plan-image {
          height: 150px;
          width: 100%;
        }
        .overlay-plan-name {
          margin: 5px 0 0;
          font-size: 24px;
        }
        p {
          margin: 0;
          font-size: 16px;
        }
        .learn-more {
          font-size: 14px;
          padding: 5px 20px;
          margin-top: 5px;
          color: $black-new;
          background-color: transparent;
          border: 2px solid $black-new;
          display: block;
          text-align: center;
          text-decoration: none;
        }
      }
    }
    .plan-footer-right {
      width: 30%;
      display: flex;
      .build-your-home {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: $orange;
        font-size: 20px !important;
        line-height: 35px;
        letter-spacing: 3.9px;
        padding: 20px;
        cursor: pointer;
        color: $white;
        font-family: $arquitecta-heavy;
        text-transform: uppercase;

        > div,
        .floorplan_save_btn {
          height: 100%;
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  @include max-width(1620px) {
    height: calc(100vh - 214px);
    .residence-header {
      .neigh-name {
        width: 22%;
      }
      .residence-nav-bar {
        width: 70%;
        li {
          font-size: 14px;
        }
      }
      .plan-icons {
        width: 8%;
      }
    }
  }
  @include max-width(1560px) {
    .plan-features {
      .plan-footer-left {
        .plan-features-list {
          li {
            padding: 5px 15px;
          }
        }
      }
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .plan-features {
      .plan-footer-right {
        .build-your-home {
          font-size: 13px !important;
        }
      }
      .plan-footer-left {
        .plan-features-list {
          li {
            .feature-value {
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
  @include max-width($desktop-large) {
    .residence-header {
      .neigh-name {
        .neighborhood-name {
          font-size: 18px;
        }
      }
      .residence-nav-bar {
        ul{
          .plan-link {
            &.current-active {
              span {
                border-bottom: 2px solid $orange;
              }
            }
            span {
              &:hover {
                border-bottom: 2px solid $orange;
              }
            }
          }
        }
      }
      .plan-icons {
        .residence-btn {
          font-size: 12px !important;
          letter-spacing: 2px;
          padding: 5px 10px !important;
        }
      }
      .residence-nav-bar {
        ul {
          font-size: 16px;

          li:not(:last-child):after {
            width: 55px;
          }
        }
      }
    }
    .residence-details {
      .residence-content {
        padding-bottom: 60px;
        .plan-name {
          font-size: 30px;
        }
        // .plan-description{
        //     p,ul,ol,span,em{
        //         font-size: 14px;
        //     }
        // }
        .residence-btns {
          padding: 15px 25px;
        }
        .interactive-icons {
          li {
            a {
              font-size: 11px !important;
            }
          }
        }
      }
      .residence-image {
        .image-div,
        .iframe-div {
          height: 495px !important;
        }
        .gallery-grid-images {
          .grid-image {
            height: 250px;
          }
        }
        .pink-tab {
          p {
            font-size: 30px;
          }
        }
      }
    }
    .plan-features {
      .plan-footer-left {
        .plan-features-list {
          li {
            padding: 5px 15px;
            .feature-value {
              font-size: 18px;
            }
            .feature-name {
              font-size: 8px;
            }
          }
        }
        .plan-details-container {
          .residence-name {
            font-size: 25px;
          }
        }
      }
      .plan-footer-right {
        .build-your-home {
          font-size: 13px !important;
        }
      }
    }
  }
  @include max-width(1620px) {
    .plan-features {
      .plan-footer-left {
        .plan-features-list {
          li {
            .feature-value {
              font-size: 16px;
              line-height: 25px;
            }
            .feature-name {
              font-size: 8px;
            }
          }
        }
        .plan-details-container {
          .residence-name {
            font-size: 28px;
          }
          .plan-estimate-price {
            .estimate-payment {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @include max-width($macbook) {
    .residence-header {
      .neigh-name {
        width: 20%;
      }
      .residence-nav-bar {
        width: 80%;
        li {
          font-size: 14px;
        }
      }
      .plan-icons {
        display: none;
        .residence-btn {
          font-size: 10px !important;
        }
      }
    }
    .plan-features {
      .plan-footer-left {
        .plan-details-container {
          .plan-estimate-price {
            display: block;
            .estimate-payment {
              margin: 0;
            }
          }
        }
      }
      .plan-footer-right {
        .build-your-home {
          font-size: 13px;
        }
      }
    }
    .residence-details {
      .residence-image {
        .image-div,
        .iframe-div {
          height: 433px !important;
        }
        .gallery-grid-images {
          .grid-image {
            height: 208px;
          }
        }
      }
    }
  }
  @include max-width($tablet-landscape) {
    height: auto;
    .plan-features{
      .plan-footer-left{
        .plan-features-list{
          width: 100%;
        }
      }
    }
    .residence-header {
      position: fixed;
      background: transparent;
      padding: 15px;
      &.overlay_back {
        background: rgba(0, 0, 0, 0.5);
      }
      .neigh-name {
        width:80%;
        i {
          left: 0;
          color: $white;
          padding: 0 10px;
          border: 2px solid #fff;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 30px;
        }
        .neighborhood-name {
          display: none;
        }
      }
      .residence-nav-bar {
        display: none;
      }
      .plan-icons {
        width: unset;
        .residence-btn {
          border: 2px solid $white;
          background: rgba(0, 0, 0, 0.5);
          margin: 0;
          padding: 3px 15px !important;
          .resisdence-save-btn {
            color: $white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        i.fa.fa-heart-o {
          display: none;
        }
      }
      &.bdx-added {
        position: static;
        .neigh-name {
          .neighborhood-name {
            display: block;
          }
        }
        &.overlay_back {
          background: unset;
        }
      }
    }
    .residence-details {
      display: block;
      margin: 0;
      .residence-image {
        width: 100%;
        padding: 0;
        .image-div,
        .iframe-div,
        .grid-image {
          height: 460px !important;
          padding: 0 !important;
        }
        i.fa.fa-long-arrow-left {
          position: absolute;
          z-index: 99;
          color: white;
          left: 10px;
          top: 5px;
          font-size: 23px;
        }
      }
      .residence-content {
        width: 100%;
        padding: 30px;
        height: unset;
        overflow-y: unset;
        .plan-status {
          font-size: 13px;
        }
        .plan-name {
          font-size: 34px;
        }
        // .plan-description{
        //     p,ul,ol,span,em{
        //         font-size: 16px;
        //     }
        // }
        .residence-btns {
          padding: 15px;
          width: 50%;
          font-size: 20px;
        }
        .interactive-icons {
          li {
            a {
              font-size: 12px !important;
            }
          }
        }
      }
    }
    .plan-features {
      display: flex;
      flex-direction: column;
      position: unset;
      margin-bottom: 64px;
      .plan-footer-left {
        width: 100%;
        flex-direction: column;
        .plan-details-container {
          width: 100%;
          order: 2;
          padding: 20px 40px;
          justify-content: space-between;
          border-bottom: 1px solid #707070;
          .menu-bar {
            order: 1;
          }
          .plan-details {
            margin-left: 10px;
          }
          .residence-name {
            font-size: 28px;
          }
          .plan-estimate-price {
            .residence-price {
              font-size: 16px;
              padding-bottom: 8px;
            }
            .estimate-payment {
              font-size: 14px;
            }
          }
        }
        .plan-features-list {
          display: block;
          padding: 20px 40px 5px;
          border-bottom: 1px solid $bordergray;
          li {
            border: none;
            padding: 10px 10px;
            border-bottom: 1px solid $bordergray;
            width: 100%;
            text-align: center;
            margin: auto;
            align-items: center;
            display: flex;
            justify-content: space-between;
            &:last-child {
              border-bottom: unset;
            }
            .feature-value {
              font-size: 24px;
            }
            .feature-name {
              font-size: 10px;
            }
          }
        }
      }
      .plan-footer-right {
        width: 100%;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;
        .build-your-home {
          width: 100%;
          order: 3;
        }
      }
    }
  }
  @include max-width($iphone4-landscape) {
    .plan-features {
      .plan-footer-left {
        .plan-details-container {
          padding: 25px;
        }
        .plan-features-list {
          padding: 20px 25px 5px;
        }
      }
    }
    .residence-details {
      .residence-content {
        .residence-btns {
          width: 100%;
        }
      }
    }
  }
  #screen-2,
  #screen-1 {
    height: 100%;
    .residence-image {
      padding: 0 !important;
      height: 100%;
      position: relative;
      .image-div,
      .iframe-div {
        height: 100% !important;
        padding: 0 !important;
      }
      .slider-wrapper,
      .slick-list,
      .slick-slider,
      .slick-track {
        height: 100%;
      }
      .slick-slide {
        div {
          height: 100%;
          outline: none;
        }
        img {
          outline: none;
        }
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        background: $white;
        width: 43px;
        height: 43px;
        margin: 0;
        outline: none;
        z-index: 1;
        &:after {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          border-right: 2px solid #111111;
          border-bottom: 2px solid #111111;
          transform: rotate(-45deg);
          top: 44%;
          right: 15px;
        }
        &:before {
          position: absolute;
          content: "";
          top: 50%;
          height: 2px;
          width: 15px;
          background: #111;
          right: 15px;
          opacity: 1;
        }
        &.slick-disabled {
          opacity: 0.5;
        }
      }
      .slick-prev {
        left: 10px;
        &:after {
          transform: rotate(135deg);
          left: 15px;
        }
        &:before {
          left: 15px;
        }
      }
      .slick-next {
        right: 10px;
      }
      .slick-dots {
        display: none !important;
      }
    }
    .residence-content {
      .plan-btn {
        font-size: 13px;
      }
      .thumbnail-slider-wrap {
        padding-top: 6px;
        .slick-track {
          transform: unset !important;
          width: unset !important;
        }
        .slick-current {
          position: relative;
          .plan-btn {
            color: $black-new;
          }
          &:after {
            content: "";
            position: absolute;
            top: -3px;
            right: -15px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 22px 20px 22px;
            border-color: transparent transparent $orange transparent;
            transform: rotate(42deg);
          }
          .residence-btns {
            border: 1px solid $orange;
            background: white;
          }
        }
      }
      .btn-description {
        margin-top: 50px;
        div {
          outline: none;
        }
        .btn-name {
          font-size: 24px;

          color: $black-new;
        }
        p {
          font-size: 18px;
          line-height: 22px;
          margin-top:6px;
        }
      }
    }
    @include max-width($desktop-large) {
      .residence-content {
        .plan-btn {
          font-size: 12px;
        }
        .btn-description {
          .btn-name {
            font-size: 20px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    @include max-width($tablet-landscape) {
      .residence-image {
        .image-div,
        .iframe-div {
          height: 460px !important;
        }
        .slick-slide {
          img {
            height: 460px !important;
          }
        }
        .slick-arrow {
          display: none !important;
        }
        .slick-dots {
          display: block !important;
          position: unset;
          li {
            outline: none;
            width: unset;
            height: unset;
            cursor: default;
            outline: none;
            margin: 0;
            button {
              margin: 5px 7px;
              width: 8px;
              padding: 0 !important;
              &:before {
                font-size: 11px;
                color: #d6d6d6 !important;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &:before {
                  opacity: 1;
                  color: #869791 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  #screen-3,
  #screen-4 {
    .residence-image {
      padding: 0 !important;
      height: 100%;
      width: 100%;
      position: relative;
      .image-div,
      .iframe-div {
        height: 100% !important;
        padding: 0 !important;
        .dw-modal-window {
          position: absolute;
        }
      }
      .slider-wrapper,
      .slick-list,
      .slick-slider,
      .slick-track {
        height: 100%;
      }
      .slick-slide {
        div {
          height: 100%;
          outline: none;
        }
        img {
          outline: none;
        }
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        background: #ffffffb2;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        margin: 0;
        outline: none;
        z-index: 1;
        &:after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          border-right: 2px solid #111111;
          border-bottom: 2px solid #111111;
          transform: rotate(-45deg);
          top: 40%;
          right: 20px;
        }
        &:before {
          display: none;
        }
        &.slick-disabled {
          background: hsla(0, 0%, 87%, 0.9);
        }
      }
      .slick-prev {
        left: 10px;
        &:after {
          transform: rotate(135deg);
          left: 18px;
        }
      }
      .slick-next {
        right: 10px;
      }
      .slick-dots {
        display: none !important;
      }
      @include max-width($tablet-landscape) {
        &.bdx_parent_div {
          height: 460px !important;
        }
        .image-div,
        .iframe-div {
          height: 460px !important;
        }
        .slick-slide {
          img {
            height: 460px !important;
          }
        }
      }
    }
  }
  #screen-5,
  #screen-6 {
    .residence-image {
      display: flex;
      padding: 5%;
      width: 100%;
      .image-column {
        width: 50%;
        height: 694px;
        margin-right: 5%;
      }
      .detail-column {
        width: 50%;
        padding-top: 3%;
        .detail-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 30px;
          border-bottom: 1px solid $lightgray;
          margin-bottom: 27px;
          &:last-child {
            border-bottom: unset;
          }
          .detail-text {
            width: 55%;
            .detail-title {
              font-size: 22px;
              color: $black-new;

              margin-bottom: 10px;
            }
            .detail-description {
              font-size: 14px;
              color: $black-new;
              line-height: 18px;
              margin: 0;
            }
          }
          .residence-btn {
            width: 35%;
            // border: 1px solid $black-new;
            // border-radius: 23px;
            // font-size: 16px;
            //
            text-align: center;
            a {
              @include black-outline-button;
              // color: $black-new;
              text-decoration: none;
              padding: 10px 5px !important;
              display: inline-block;
              width: 100%;
              &:hover {
                color: $white !important;
                background-color: $orange !important;
                border: 1px solid $orange !important;
              }
            }
          }
        }
      }
    }
    .residence-content {
      ul.estimate-data {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;
        flex-direction: column;
        border-top: 2px solid $lightgray;
        border-bottom: 2px solid $lightgray;
        li {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          font-size: 20px;

          border-bottom: 2px solid $lightgray;
          justify-content: space-between;
          padding: 20px 0;
          &:last-child {
            border-bottom: unset;
          }
          ul.sub-estimate-list {
            list-style: none;
            padding: 0;
            width: 100%;
            padding-top: 10px;
            li {
              border: unset;
              font-size: 16px;
              padding: 0;
              font-family: $font-primary;
            }
          }
        }
      }
      .estimate-price {
        margin-top: 20px;
        .total-price {
          font-size: 22px;

          display: flex;
          justify-content: space-between;
          color: $black-new;
        }
        .estimate-payment {
          border-bottom: 1px solid $black-new;
          color: $black-new;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    @include max-width($desktop-large) {
      .residence-image {
        .image-column {
          height: 500px;
        }
        .detail-column {
          .detail-content {
            .detail-text {
              .detail-title {
                font-size: 18px;
              }
              .detail-description {
                font-size: 14px;
              }
            }
            .residence-btn {
              a {
                font-size: 12px !important;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
      .residence-content {
        ul.estimate-data {
          li {
            font-size: 18px;
            ul.sub-estimate-list {
              li {
                font-size: 14px;
              }
            }
          }
        }
        .estimate-price {
          .total-price {
            font-size: 20px;
          }
        }
      }
    }
    @include max-width($tablet-landscape) {
      .residence-image {
        display: block;
        padding: unset;
        .image-column {
          width: 100%;
          height: 460px !important;
          margin-right: unset;
        }
        .detail-column {
          width: 100% !important;
          padding: 10% 5% 3%;
        }
      }
    }
    @include max-width($iphone4-landscape) {
      .residence-image {
        .detail-column {
          padding: 15% 5% 3%;
          .detail-content {
            flex-wrap: wrap;
            .detail-text {
              width: 100%;
            }
            .residence-btn {
              width: 100%;
              margin-top: 20px;
              a {
                font-size: 12px !important;
                letter-spacing: 3.9px;
              }
            }
          }
        }
      }
    }
  }
  #screen-6 {
    .residence-image {
      .form-main {
        width: 80%;
        .form-heading {
          font-size: 24px;
          font-family: $atten-font;
          font-weight: $font-atten-bold;

          color: $black-new;
          margin-bottom: 15px;
        }
        .form-group {
          margin-bottom: 1rem;
        }
        .form-control {
          padding: 15px;

          margin-bottom: 5px;
          color: $black-new;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          display: block;
          width: 100%;
          height: unset;
          &:focus {
            outline: none;
            box-shadow: unset;
            border: 1px solid #ced4da;
          }
        }
        .gray-text {
          color: $textgrey;
          font-size: 12px;
        }
        .save-btn {
          @include black-outline-button;
          width: 100%;
          padding: 10px !important;
          border-radius: 25px;
          border: 1px solid $black-new;
          &:focus {
            outline: none;
          }
        }
      }
      @include max-width($desktop-large) {
        .form-main {
          width: 90%;
        }
      }
      @include max-width($tablet-landscape) {
        .form-main {
          width: 65%;
        }
      }
      @include max-width($iphone6-landscape) {
        .form-main {
          width: 100%;
        }
      }
    }
  }
  .screen-3.plan-features {
    @media only screen and (width: 768px) {
      min-height: calc(100vh - 460px);
      background-color: $black-new;
      margin: 0;
    }
    @media only screen and (width: 375px) {
      min-height: calc(100vh - 460px);
      background-color: $black-new;
    }
  }
}
.Estimate_modal {
  .modal-dialog {
    .modal-content {
      ::-webkit-scrollbar {
        width: 0px;
      }
      .modal-header {
        padding: 0;
        position: absolute;
        right: 0;
        .close {
          text-shadow: unset;
          height: 30px;
          width: 30px;
          right: 12px;
          top: 15px;
          background: $black-new;
          z-index: 9;
          span {
            font-size: 30px;
            font-weight: lighter;
            line-height: 23px;
            color: $white;
          }
        }
      }
      .modal-body {
        overflow-y: auto;
        background-color: $bgdarkgray;
        .mortgage-section-main,
        .mortgage-section {
          background-color: $bgdarkgray;
          height: 100%;
        }
      }
      @include max-width($tablet) {
        .modal-body {
          .mortgage-section {
            height: auto;
          }
        }
      }
    }
  }
}

.iframe-div {
  transform: scale(1);
  .isp-top-bar {
    .logo {
      img {
        width: auto;
      }
    }
  }
  .ifp-content .ifp-customer-logo .logo img {
    width: auto;
  }
}

.bdx_plugin_div {
  ul,
  ol,
  li,
  dl,
  label,
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  a:hover {
    text-decoration: none;
  }
}

.bdx_plugin_div .view-controls {
  padding: 7px 10px !important;
}
.bdx_plugin_div .pro360_category {
  padding: 0 10px 10px !important;
  margin: 10px 0 0 !important;
}
.bdx_plugin_div .pro360_palettes {
  padding: 10px 5px !important;
}

.bdx_plugin_div .tooltip {
  opacity: 1 !important;
}
