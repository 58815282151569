@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.photo-section {
    display: flex;
    flex-direction: column;
    padding: 134px 0px 200px 5%;

    .headerSectionWrapper {

        .heading-section {
            margin-left: 0;
            text-align: left;
            margin-bottom: 3%;

            .sub-title {
                padding: 0;
            }

            .main-title {
                font-size:68px ;
                line-height:70px ;
                letter-spacing: 0px;
                color: #545454;
                width: 72%;
                padding: 18px 0 20px;

                > span {
                    display: block;
                }
            }
        }
    }

    .photo-info {
        .photo-main-img {
            .owl-carousel {
                position: relative;
                padding: 0 0 3%;
                .owl-stage-outer {
                    .owl-stage {
                        left: -70px;

                        .owl-item {
                            > div {
                                width: 100%;
                                padding-bottom: calc(80% + 100px);
                                height: 0;
                                overflow: hidden;
                                position: relative;

                                img {
                                    object-fit: contain;
                                    position: absolute;
                                    cursor: pointer;
                                    
                                    &:focus {
                                        outline: 0;
                                    }
                                }

                                .tour-img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 150px;
                                    height: 150px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .owl-nav {
                    display: flex;
                    position: absolute;
                    bottom: -21px;
                    left: 5%;
                    transform: translateY(100%);

                    button {
                        margin: 0;
                        outline: 0;
                        border: 0;
                        background: $orange;
                        color: $white;
                        margin-right: 6px;

                        &.disabled {
                            opacity: 1;
                        }

                        // &:hover {
                        //     background: transparent;
                        //     color: inherit;
                        // }

                        .sliderArrow {
                            position: relative;
                            border: 1px solid $new-border-color;
                            height: 61px;
                            width: 61px;

                            &:after,
                            &:before {
                                content: " ";
                                position: absolute;
                                top: 50%;
                                left: 45%;
                            }

                            &:before {
                                width: 8px;
                                height: 8px;
                                border-top: 2px solid $white;
                                border-right: 2px solid $white;
                                transform: translate(-1px, -50%) rotate(45deg);
                            }

                            &:after {
                                background: $white;
                                transform: translate(-50%, -50%);
                                height: 2px;
                                width: 16px;
                            }

                            &.arrowLeft {
                                transform: rotate(180deg);
                            }
                        }

                    }
                }

                .owl-dots {
                    position: absolute;
                    bottom: -50px;
                    transform: translateY(100%);
                    background: $new-border-color;
                    height: 2px;
                    display: flex;
                    width: 75%;
                    left: 15%;

                    .owl-dot {
                        height: 2px;
                        flex-grow: 1;
                        width: 100%;

                        &.active {
                            background: $black-new;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                button:focus,
                button:active {
                    outline: 0;
                    border: 0;
                }

            }
            .single-image {
                width: 60%;
                margin: auto;
                height: 500px;
                padding-right: 10%;
                img {
                    object-fit: contain;
                }
            }
        }
    }


    @include max-width($desktop-xl) {
        padding: 134px 0px 300px 5%;

        .photo-info {
            .photo-main-img {
                .owl-carousel {
                    .owl-stage-outer {
                        .owl-stage {
                            left: -50px;
                        }
                    }
                }
            }
        }
    }

    @include max-width($desktop-large) {
        padding-bottom: 15%;

        .headerSectionWrapper {
            .heading-section {
                .main-title {
                    font-size:58px ;
                    line-height:68px ;
                }
            }
        }

        .photo-info {
            .photo-main-img {
                .owl-carousel {
                    .owl-nav {
                        left: 5%;
                        button {
                            .sliderArrow {
                                height: 45px;
                                width: 45px;
                             }
                         }
                    }
                    .owl-dots {
                        bottom: -42px;
                    }
                }
            }
        }
    }

    @include max-width($macbook) {
        .headerSectionWrapper {
            .heading-section {
                .main-title {
                    font-size:48px;
                    line-height:50px;
                    width: 80%;
                }
            }
        }
    }

    @include max-width($tablet-landscape) {
        padding: 10% 0 15% 5%;

        .photo-info {
            .photo-main-img {
                .owl-carousel {
                    .owl-dots {
                        width: 65%;
                        left: 25%;
                    }
                }
            }
        }
    }

    @include max-width($iphone6-plus-landscape) {
        padding: 10% 0 15% 2%;

        .headerSectionWrapper {
            
                    .heading-section {
                        margin-left: 0;
                        text-align: left;
                        margin-bottom: 3%;
            
                        .sub-title {
                            padding: 0;
                        }
            
                        .main-title {
                            font-size: 32px;
                            line-height: 34px;
                            letter-spacing: 0px;
                            color: #545454;
                            width:100%;
                            padding: 18px 0 20px;
            
                            > span {
                                display: block;
                            }
                        }
                    }
                }

        .photo-info {
            .photo-main-img {
                .owl-carousel {
                    .owl-stage-outer {
                        .owl-stage {
                            left: -70px;

                            .owl-item {
                                > div {
                                    padding-bottom: calc(100% + 50px);
                                }
                            }
                        }
                    }

                    .owl-nav {
                        display: none;
                    }

                    .owl-dots {
                        width: 95%;
                        left: 0;
                    }
                }
                .single-image {
                    height: 300px;
                    width: 100%;
                }
            }
        }
    }

    @include max-width($iphone4-landscape) {
        padding: 15% 0 30% 2%;
    }
}


