@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.review-section {
  background-color: $bglightgray;
  .reviews-info {
    display: flex;
    padding: 8% 0;
    justify-content: space-between;
    flex-wrap: wrap;
    .reviews-heading {
      width: 60%;
      padding-left: 7%;
      .heading-section {
        width: 100%;
        text-align: left;
        margin: 0;
      }
      .reviews-text {
        font-size: 20px;
        color: $black-new;
        line-height: 32px;
        margin: 0;
      }
    }
    .reviews-logo {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center !important;
      .eliant_logo {
        width: 238px;
        margin-bottom: 20px;
        height: 192px;
      }
      .review-star1 {
        display: flex;
        // padding-top: 15px;
        .star-text {
          padding-top: 2px;
          padding-right: 15px;
          font-size: 20px;
          margin: 0;
          color: $black-new;
        }
        span {
          padding-right: 5px;
          width: 30px;
          height: 30px;
          display: inline-block;
          img {
            object-fit: contain;
          }
        }
      }
    }
    @include max-width($desktop-xl) {
      .reviews-heading {
        padding-left: 9%;
        .reviews-text {
          // font-size: 16px;
          line-height: 28px;
        }
      }
      .reviews-logo {
        .review-star1 {
          .star-text {
            // font-size: 16px;
            line-height: 28px;
          }
          span {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    @include max-width($iphone6-plus-landscape) {
      padding: 10% 5%;
      .reviews-heading {
        width: 100%;
        padding-left: 0;
      }
      .reviews-logo {
        width: 100%;
        order: -1;
        padding-bottom: 20px;
        .eliant_logo {
          width: 200px;
          height: 160px;
          margin-bottom: 0px;
        }
        .review-star1 {
          padding-top: 0px;
          padding-bottom: 23px;
        }
      }
    }
    @include max-width($iphone4-landscape) {
      padding: 10% 8%;
    }
  }
  .reviews-slider {
    position: relative;
    padding-bottom: 5%;
    .single-image {
      height: 100%;
      width: 70%;
      margin: auto;
    }
    .owl-stage {
      display: flex;
    }
    .owl-item {
      transform: scale(0.75);
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: hsla(0, 0%, 92%, 0.4);
        z-index: 0;
      }
      &.active {
        transform: scale(1);
        &:after {
          position: unset;
        }
      }
    }

    .owl-nav {
      position: absolute;
      top: 50%;
      width: 100%;
      margin: 0;
      margin-top: -25px;
      button {
        width: 69px;
        height: 69px;
        border-radius: 50% !important;
        background: white !important;
        position: absolute;
        margin: 0;
        outline: none;
        &:after {
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-right: 2px solid $black-new;
          border-bottom: 2px solid $black-new;
          transform: rotate(-45deg);
          top: 43%;
          right: 30px;
        }
        span {
          display: none;
        }
      }
      .owl-prev {
        left: 5%;
        &:after {
          transform: rotate(135deg);
          left: 28px;
        }
      }
      .owl-next {
        right: 5%;
      }
    }
    .slider-top-div {
      display: flex;
      justify-content: space-between;
      padding: 0px 5% 20px;
      align-items: center;
      .review-sort {
        display: flex;
        font-size: 14px;
        align-items: center;
        color: $black-new;
        span {
          margin-right: 5px;
        }
        select {
          padding: 5px;
          padding-left: 0;
          border: 1px solid $black-new;
          outline: none;
          color: $black-new;
        }
      }
    }
    .total-review {
      font-size: 14px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: $black-new;
      margin: 0;
    }
    .reviews-slider-div {
      background-color: $white;
      padding: 8%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        font-size: 28px;
        line-height: 37px;
        color: $black-new;
      }
      p.silder-text {
        font-size: 20px;
        padding: 10px 0 50px;
        color: $black-new;
      }
      .review-star1 {
        display: flex;
        align-items: center;
        padding-bottom: 25px;
        .star-text {
          padding-top: 5px;
        }
        span {
          padding-right: 5px;
        }
      }
      .review-date {
        font-size: 15px;
        color: $textgrey;
        margin: 0;
      }
    }
    @include max-width($desktop-large) {
      .owl-nav {
        button {
          width: 50px;
          height: 50px;
          &:after {
            width: 10px;
            height: 10px;
            right: 23px;
          }
        }
        .owl-prev {
          &:after {
            left: 21px;
          }
        }
      }
      .total-review {
        font-size: 12px;
      }
      .reviews-slider-div {
        h5 {
          font-size: 25px;
          line-height: 30px;
        }
        p.silder-text {
          font-size: 16px;
          padding: 10px 0 50px;
        }
        .review-date {
          font-size: 13px;
        }
      }
    }
   
    @include max-width($iphone6-plus-landscape) {
        
      .owl-nav {
        button {
          width: 38px;
          height: 38px;
          &:after {
            top: 40%;
            right: 15px;
          }
        }
        .owl-prev {
          &:after {
            left: 15px;
          }
        }
      }
      .single-image {
        width: 90%;
      }
      .reviews-slider-div {
        h5 {
          font-size: 20px;
          line-height: 30px;
        }
        p.silder-text {
          font-size: 14px;
          padding: 10px 0 30px;
        }
        .review-date {
          font-size: 11px;
        }
      }
    }
    @include max-width($iphone4-landscape) {
      .owl-nav {
        .owl-prev {
          left: 3%;
        }
        .owl-next {
          right: 3%;
        }
      }
      .slider-top-div {
        padding: 0px 8% 20px;
      }
    }
  }
}

.review_loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);

  span {
    height: 80px;
    width: 80px;
  }
}
