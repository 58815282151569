@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.image-box-main {
  // padding: 5% 0;
  background-color: $bglightcream;
  // float: left;
  width: 100%;
  .image-box {
    background-size: cover;
    min-height: 400px;
    padding-left: 5%;
    padding-bottom: 8%;
    display: flex;
    position: relative;
    align-items: flex-end;
    height: 1096px;
    z-index: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $overlayblack;
      z-index: -1;
    }
    .video-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: $black-new;
      #click-to-unmute {
        display: none;
      }
    }
    .image-box-content {
      color: $white;
      width: 50%;
      .image-heading {
        font-size: 68px;
        line-height: 68px;
        letter-spacing: 0;
        width: 80%;
        padding-bottom: 50px;
        font-family: $font-primary;
        word-break: break-word;
        margin: 0;
        font-weight: 500;
      }
      .image-button {
        @include white-learn-more;
        text-decoration: none;
        min-width: 314px;
        display: inline-block;
        background-color: $white;
        &.no-link {
          pointer-events: none;
        }
        &:hover {
          color: $white;
          background-color: $orange;
          border: 1px solid $orange;
        }
      }
    }
    @include max-width($desktop-xl) {
      height: 891px;
    }
    @include max-width($desktop-large) {
      height: 700px;
      .image-box-content {
        .image-heading {
          font-size: 40px;
          line-height: 48px;
        }
        .image-button {
          font-size: 16px;
          letter-spacing: 2px;
        }
      }
    }
    @include max-width($tablet-landscape) {
      height: 585px;
      .video-box {
        display: none;
      }
      .image-box-content {
        width: 60%;
        .image-heading {
          font-size: 52px;
          line-height: 54px;
        }
      }
    }
    @include max-width($iphonex-landscape) {
      min-height: 840px;
      padding-bottom: 8%;
      height: auto;
      .image-box-content {
        width: 100%;
      }
    }
    @include max-width($iphone6-plus-landscape) {
      .image-box {
        height: 500px;
        .image-box-content {
          width: 80%;
          .image-heading {
            font-size: 32px;
            line-height: 34px;
            width: 80%;
          }
        }
      }
    }
    @include max-width($iphone4-landscape) {
      padding: 15% 5%;
      min-height: 500px;
      height:auto;
      .image-box-content {
        width: 100%;
        .image-heading {
          font-size: 32px;
          line-height: 34px;
          padding-bottom: 30px;
          width: 100%;
        }
      }
    }
  }
}
