@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.plan-banner {
    min-height: 700px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;

    .HeroContent {
        width: 100%;
        align-items: flex-end;
        &.add_overlay{
            background: transparent linear-gradient(119deg, #00000000 0%, #00000045 100%);
        }
        .HeroContentWrapper {
            width: 66%;
            padding: 5%;
            z-index: 3;

            .bannerHeader {
                .heading-section {
                    text-align: left;
                    margin-left: 0;
                    width: 100%;

                    .main-title {
                        padding-bottom: 32px;
                        font-family: $font-primary;
                        color: $black-new;
                        padding: 15px 0;
                    }
                    @include max-width($iphone4-landscape) {
                        width: 100%;
                    }
                }
                .generic-text{
                    font-size: 18px; 
                    line-height: 25px;
                    margin: 0;
                    color: $black-new;
                }
            }

        }
    }
    @include max-width($desktop-large) {
        .HeroContent {
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            font-size: 46px;
                            line-height: 1.2;
                            letter-spacing: -2px;
                        }
                    }
                }
            }
        }
    }
    @include max-width($tablet-landscape) {
        .HeroContent {
            .HeroContentWrapper {
                width: 80%;
            }
        }
    }
    @include max-width($iphonex-landscape) {
        min-height: 500px;
        .HeroContent {
            .HeroContentWrapper {
                width: 100%;
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .HeroContent {
            .HeroContentWrapper {
                .bannerHeader {
                    .heading-section {
                        .main-title {
                            font-size: 32px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .HeroContent {
            .HeroContentWrapper {
                padding: 10%;
            }
        }
    }


}