@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

#tour_form {
  display: none;
}
#tour_forms{
  display: none;
}

div#tour_form {
  img {
    height: auto;
    max-width: 100%;
    outline: none;
    width: auto;
  }
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #fbf9f6;
  // margin-right: -.2em;
  cursor: -webkit-default;
  cursor: default;
}
div#tour_forms {
  img{
      height: auto;
      max-width: 100%;
      width: auto;
  }
  height: 100%;
  width: 100%;
  margin: 0;
padding: 0;
  background: #fbf9f6;
  // margin-right: -.2em;
  cursor: -webkit-default;
  cursor: default;
}
.tourpop_form {
  display: flex;
  height: 100%;
  .form-column {
    width: 50%;
    #mktoForm_4381 .logo,
    #mktoForm_4738 .logo {
      img {
        max-width: 150px !important;
      }
    }
  }
  .form-image {
    display: flex;
    width: 50%;
    position: relative;
    .form_image_overlay {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgb(0 0 0 / 0%) linear-gradient(180deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 76%)) 0 0 no-repeat padding-box;
  }
    img {
      height: 100% !important;
      object-fit: cover;
      background-position: center;
      background-size: cover;
      min-width: 100%;
    }
    .two_image {
      width: 5%;
    }
    picture {
      flex: 1;
      img {
        width: 100%;
      }
    }
  }
}

#tour_forms {
	#mktoForm_4381,#mktoForm_4738 {
		.Header {
			.headtxt {
				color: $black-new !important;
				font-family: $font-primary !important;
				padding-right: 10px !important;
				font-weight: 600;
			}
		}
		.heading {
			color: $black-new !important;
			font-family: $font-primary !important;
			line-height: 1.2 !important;
		}
		.step {
			color: $black-new !important;
			font-family: $font-primary !important;
			font-size: 14px !important;
		}
		.subhead {
			color: $black-new !important;
			font-family: $font-primary !important;
		}
		.mktoFormRow {
			.mktoField {
				color: $black-new !important;
				font-family: $font-primary !important;
			}
		}
		.nxtStep {
			color: $black-new !important;
			font-family: $font-primary !important;
			font-size: 14px !important;
		}
		.bckstep {
			a {
				color: $black-new !important;
				font-family: $font-primary !important;
				font-size: 14px !important;
			}
		}
		.Row_35 {
			.subhead {
				font-size: 18px !important;
			}
		}
		.Row_36 {
			.headd {
				color: $black-new !important;
				font-family: $font-primary !important;
			}
			.date {
				color: $black-new !important;
				font-family: $font-primary !important;
				line-height: 30px;
			}
		}
		.bbtn {
			a {
				@include black-outline-button;
				letter-spacing: 2px !important;
				// color: $black-new !important;
				// font-family: $font-primary !important;
				// border: 1px solid $black-new !important;
				// line-height: 20px !important;
				// font-size: 18px !important;
			}
		}
		.ms-choice {
			color: $black-new !important;
			font-family: $font-primary !important;
			border: 1px solid $black-new !important;
			line-height: 20px !important;
		}
		.ms-drop {
			li {
				label {
					span {
						color: $black-new !important;
						font-family: $font-primary !important;
					}
				}
			}
			input[type="checkbox"] {
				visibility: visible !important;
				opacity: 1 !important;
				left: 20px !important;
				top: -2px;
				padding: 0 !important;
			}
		}
		a#PrivacyLink {
			color: rgb(229, 114, 31) !important;
		}
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
		.red-error {
			color: red !important;
			font-size: 15px;
			padding-top: 5px !important;
			font-family: $font-primary !important;
		}
	}
	.mktoForm#mktoForm_4381,.mktoForm#mktoForm_4738 {
		.mktoLabel {
			color: $black-new !important;
			font-family: $font-primary !important;
			font-size: 14px !important;
			left: 12px !important;
		}
		.button {
			.skip {
				a {
					color: $black-new !important;
					font-family: $font-primary !important;
					background-color: transparent !important;
					font-size: 14px !important;
					letter-spacing: unset !important;
					text-transform: unset !important;
					border: unset !important;
				}
			}
			a {
				@include green-filled-button;
				min-width: 110px !important;
				max-width: unset !important;
				width: unset !important;
				padding: 10px!important;
			}
		}
		.Field_1,.Field_3 {
			.remove_dots{
				 label:before {
					border: 0px !important;
					box-shadow: unset !important;
					content: " " !important;
					background: rgb(0 0 0 / 0%) !important;
				}

			}
			.mktoRadioList {
				label {
					p {
						color: $black-new !important;
						font-family: $font-primary !important;
						font-size: 14px !important;
					}
				}
				input {
					&:checked {
						+ {
							label {
								.appointment {
									border: 2px solid rgb(229, 114, 31) !important;
								}
							}
						}
					}
				}
			}
		}
		.mktoFormRow {
			.mktoField {
				border: 1px solid $black-new !important;
				line-height: 20px !important;
				text-shadow: unset !important;
			}
		}
		.mktoFormRow.Row_30 {
			.mktoField {
				border: none !important;
			}
		}
		input {
			font-size: 16px !important;
		}
		optgroup {
			font-size: 16px !important;
		}
		select {
			font-size: 16px !important;
		}
		textarea {
			font-size: 16px !important;
		}
	
	}
	.mktoForm#mktoForm_4738{
		.mktoFormRow.Row_25 .wrap500 {
			padding-bottom: 20px !important;
		}
	}
	#mktoForm_4738,#mktoForm_4381{
		.disclaimer {
			font-family: $font-primary !important;
			font-size: 10px !important;
			margin: 15px 0 10px !important;
			color: $lightgraytext !important;
			line-height: 12px;
		}
	}
	#mktoForm_4381.mktoForm,#mktoForm_4738.mktoForm {
		.mktoError {
			.mktoErrorMsg {
				font-family: $font-primary !important;
				font-size: 14px;
			}
		}
	}
	.mktoForm#mktoForm_4381,.mktoForm#mktoForm_4738 {
		.button {
			a {
				@include green-filled-button;
				img{
					display: none;
				}
			}
		}
	}
	#mktoForm_4381{
		.Row_55{
			.mktoFormCol{
				min-height:0;
			}
		}
	}

	@include max-width($iphone4-landscape) {
		.mktoForm#mktoForm_4738 {
			.button {
				a {
					width: 100% !important;
					letter-spacing: 2.9px;
				}
			}
		}
	}
	.arrow {
		.arrow {
			&::before {
				background-color: $black-new !important;
			}
			&::after {
				border-color: $black-new !important;
			}
		}
	}
	 
	#mktoForm_4738{
		.des {
			p{
				color: $black-new !important;
				font-family: $font-primary !important;
				&.sbhead{
					color: $black-new !important;
					font-family: $font-primary !important;
				}
			}
		}
		&.mktoForm{
			.Field_1 .mktoCheckboxList > label{
				color: $black-new !important;
				font-family: $font-primary !important;
			}
			.whteInfo{
				color: rgb(229, 114, 31) !important;
				font-family: $font-primary !important;
				border: 1px solid rgb(229, 114, 31) !important;
			}
			#Step4Row5{
				text-align: center;
			}
		} 
	} 
	.red-error {
		color: red !important;
		font-size: 15px;
		padding-top: 5px !important;
		font-family: $font-primary !important;
	}
}

#tour_form {
  #mktoForm_4381,
  #mktoForm_4738 {
    .Header {
      .headtxt {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        padding-right: 10px !important;
        font-weight: 600;
      }
    }
    .heading {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      line-height: 1.2 !important;
    }
    .step {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      font-size: 14px !important;
    }
    .subhead {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

    }
    .mktoFormRow {
      .mktoField {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

      }
    }
    .nxtStep {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      font-size: 14px !important;
    }
    .bckstep {
      a {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        font-size: 14px !important;
      }
    }
    .Row_35 {
      .subhead {
        font-size: 18px !important;
      }
    }
    .Row_36 {
      .headd {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

      }
      .date {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        line-height: 30px;
      }
    }
    .bbtn {
      a {
        @include black-outline-button;
        letter-spacing: 2px !important;
        width: auto;
        font-size: 16px !important;
        padding: 18px 30px;
        &:hover {
          background-color: $orange;
          border: 1px solid $orange !important;
          color: $white;
        }
      }
    }
    .ms-choice {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      border: 1px solid $black-new !important;
      line-height: 20px !important;
    }
    .ms-drop {
      li {
        label {
          span {
            color: $black-new !important;
            font-family: $atten-font !important;
            font-weight: $font-atten-book;

          }
        }
      }
      input[type="checkbox"] {
        visibility: visible !important;
        opacity: 1 !important;
        left: 20px !important;
        top: -5px;
        padding: 0 !important;
      }
    }
    a#PrivacyLink {
      color: $orange !important;
    }
    .arrow {
      &::before {
        background-color: $black-new !important;
      }
      &::after {
        border-color: $black-new !important;
      }
    }
  }
  .mktoForm#mktoForm_4381,
  .mktoForm#mktoForm_4738 {
    .mktoLabel {
      color: $black-new !important;
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      left: 12px !important;
    }
    .button {
      .skip {
        a {
          color: $black-new !important;
          font-family: $atten-font !important;
          font-weight: $font-atten-book;

          background-color: transparent !important;
          font-size: 14px !important;
          letter-spacing: unset !important;
          text-transform: unset !important;
          border: unset !important;
        }
      }
      a {
        @include orange-filled-button;
        min-width: 110px !important;
        max-width: unset !important;
        width: unset !important;
        padding: 10px !important;
        line-height: 22px !important;
      }
    }
    .Field_1,
    .Field_3 {
      .mktoRadioList {
        label {
          p {
            color: $black-new !important;
            font-family: $atten-font !important;
            font-weight: $font-atten-book;

            font-size: 14px !important;
          }
        }
        input {
          &:checked {
            + {
              label {
                .appointment {
                  border: 2px solid $orange !important;
                }
              }
            }
          }
        }
      }
    }
    .mktoFormRow {
      .mktoField {
        border: 1px solid $black-new !important;
        line-height: 20px !important;
        text-shadow: unset !important;
        box-shadow: unset !important;
      }
    }
    .mktoFormRow.Row_30 {
      .mktoField {
        border: none !important;
      }
    }
    input {
      font-size: 16px !important;
    }
    optgroup {
      font-size: 16px !important;
    }
    select {
      font-size: 16px !important;
    }
    textarea {
      font-size: 16px !important;
    }
    .mktoFormRow.Row_33 .wrap500 {
      margin-top: 50px !important;
    }
  }
  .mktoForm#mktoForm_4738 {
    .mktoFormRow.Row_25 .wrap500 {
      padding-bottom: 20px !important;
    }
  }
  #mktoForm_4738,
  #mktoForm_4381 {
    .disclaimer {
      font-family: $atten-font !important;
      font-weight: $font-atten-book;

      font-size: 10px !important;
      margin: 15px 0 10px !important;
      color: $lightgraytext !important;
      line-height: 12px;
    }
  }
  #mktoForm_4381.mktoForm,
  #mktoForm_4738.mktoForm {
    .mktoError {
      .mktoErrorMsg {
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        font-size: 14px;
      }
    }
  }
  .mktoForm#mktoForm_4381,
  .mktoForm#mktoForm_4738 {
    .button {
      a {
        @include orange-filled-button;
        font-family: $arquitecta-bold !important ;
        img {
          display: none;
        }
      }
    }
  }
  #mktoForm_4381 {
    .Row_55 {
      .mktoFormCol {
        min-height: 0;
      }
    }
  }

  @include max-width($iphone4-landscape) {
    .mktoForm#mktoForm_4738 {
      .button {
        a {
          width: 100% !important;
          letter-spacing: 2.9px;
        }
      }
    }
  }
  .arrow {
    .arrow {
      &::before {
        background-color: $black-new !important;
      }
      &::after {
        border-color: $black-new !important;
      }
    }
  }

  #mktoForm_4738 {
    .des {
      p {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        &.sbhead {
          color: $black-new !important;
          font-family: $atten-font !important;
          font-weight: $font-atten-book;

        }
      }
    }
    &.mktoForm {
      .Field_1 .mktoCheckboxList > label {
        color: $black-new !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

      }
      .whteInfo {
        color: $orange !important;
        font-family: $atten-font !important;
        font-weight: $font-atten-book;

        border: 1px solid $orange !important;
      }
      #Step4Row5 {
        text-align: center;
      }
    }
  }
  .red-error {
    color: red;
    font-size: 15px;
    padding-top: 5px !important;
    font-family: $atten-font !important;
    font-weight: $font-atten-book;

  }
}
.fancybox-slide--html {
  padding: 0 !important;
  #tour_form {
    .fancybox-close-small {
      color: $white !important;
      background: $black-new !important;
      width: 25px !important;
      height: 25px !important;
      padding: 0px !important;
      right: 10px !important;
      top: 10px !important;
      border-radius: 50% !important;
    }
  }
  #tour_forms {
    .fancybox-close-small {
      color: $white !important;
      background: $black-new !important;
      width: 25px !important;
      height: 25px !important;
      padding: 0px !important;
      right: 10px !important;
      top: 10px !important;
      border-radius: 50% !important;
    }
  }
}
.custom-tour.fancybox-slide--html {
  padding: 0 !important;
}

#mktoForm_4381,
#mktoForm_4738 {
  .heading {
    font-size: 28px !important;
  }
  .subhead {
    font-size: 14px !important;
  }
  .Row_3 {
    .subhead {
      font-size: 16px;
    }
  }
}

@include max-width($iphonex-landscape) {
  .tourpop_form {
    .form-column {
      width: 100%;
    }
    .form-image {
      display: none;
    }
  }
  #tour_form #mktoForm_4381 .Header,
  #tour_form #mktoForm_4738 .Header {
    padding: 35px 30px 0 !important;
  }
  #tour_form .mktoForm#mktoForm_4381 .Field_1 .mktoRadioList label p,
  #tour_form .mktoForm#mktoForm_4738 .Field_3 .mktoRadioList label p {
    font-size: 14px !important;
  }

  #mktoForm_4381,
  #mktoForm_4738 {
    .heading {
      font-size: 27px !important;
    }
  }
}
@include max-width($iphone4-landscape) {
  #tour_form .mktoForm#mktoForm_4381 .mktoLabel {
    font-size: 13px !important;
  }
}

.national_holiday_date{
	&.ui-state-disabled{
		opacity: .7;
		.ui-state-default{
			background-color: #545454;
			color: $white;
		}
	}
}