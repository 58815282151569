@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.gallery-section{
    display: flex;
    .gallery-side-img{
        width: 50%;
        height: 1080px;
        background-color:$bgdarkgray;
    }
    .gallery-info{
        width: 50%;
        color: $white;
        background-color: $bdlightblack;
        padding: 5%;
        padding-right: 0;
        padding-bottom: 10%;
        .heading-section{
            width: 70%;
            text-align: left;
            margin: 0;
            h2,p{
                font-weight: normal;
                color: $white;
            }
        }
        .gallery-learn-more{
            display: none;
        }
        .gallery-main-img{
            height: 460px;
            margin-top: 50px;
            .carousel-btn{
                background-color: $black-new;
                height: calc(100% - 60px) !important;
                display: block;
                position: relative;
                .carousel-learn-more{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    padding: 10px 30px;
                    border-radius: 25px;
                    background: $white;
                    font-size: 20px;
                    min-width: 150px;
                    color: $black-new;
                    text-decoration: none;
                    text-align: center;
                }
            }
          
            .owl-carousel,.owl-stage-outer,.owl-stage,.owl-item,.owl-item div{
                height: 100%;
            }
            .owl-stage{
                padding-left: 0 !important;
            }
            .owl-item{
                img{
                    height: calc(100% - 60px);
                }
                .image-text{
                    margin: 15px 0 0; 
                    font-size: 18px;
                    color: $wildsand;
                }
            }
            .owl-nav{
                position: absolute;
                top: 42%;
                width: 100%;
                margin: 0;
                margin-top: -35px;
                button{
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    margin: 0;
                    outline: none;
                    border-radius: unset;
                    background: $orange;
                    &:after{
                        position: absolute;
                        content: "";
                        width: 15px;
                        height: 15px;
                        border-right: 2px solid $white;
                        border-bottom: 2px solid $white;
                        transform: rotate(-45deg);
                        top: 42%;
                        right: 20px;
                    }
                    &:before{
                        position: absolute;
                        content: "";
                        border-bottom: 2px solid $white;
                        top: 50%;
                        right: 20px;
                        width: 40px;
                    }
                    span{
                        display: none;
                    }
                    &.disabled{
                        display: none;
                    }
                }
                .owl-prev{
                    left: -122%;
                    &:after{
                        transform: rotate(135deg);
                        left: 20px;
                    }
                    &:before{
                        left: 20px;
                    }
                }
                .owl-next{
                    right: 0px;
                }
            }
            .white-btn{
                background-color: $white;
                color:$bdlightblack;
                border-radius: 25px;
            }
        }
    }
    @include max-width($desktop-xl) {
        .gallery-side-img{
            height: 900px;
        }
        .gallery-info{
            .gallery-main-img{
                height: 315px;
            }
        }
    }
    @include max-width($desktop-large) {
        .gallery-side-img{
            height: 810px;
        }
        .gallery-info{
            .gallery-main-img{
                height: 280px;
                .carousel-btn{
                    .carousel-learn-more{
                        font-size: 16px;
                    }
                }
                .owl-item{
                    .image-text{
                        font-size: 16px;
                    }
                }
                .owl-nav{
                    button{
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        margin: 0;
                        outline: none;
                        &:after{
                            right: 20%;
                            width: 12px;
                            height: 12px;
                           }
                        &:before{
                            right: 20%;
                            width: 35px;
                        }
                    }
                    .owl-prev{
                        &:after{
                            left: 20%;
                        }
                        &:before{
                            left: 20%;
                        }
                    }
                }
            }
        }
    }
    @include max-width($macbook) {
        .gallery-side-img{
            height: 768px;
        }
    }

    @include max-width($tablet-landscape) {
        .gallery-side-img{
            display: none;
        }
        .gallery-info{
            width: 100%;
            padding: 10% 5%;
            text-align: center;
            .heading-section{
                width: 80%;
            }
            .gallery-main-img{
                margin-top: 30px;
                height: 320px;
                .owl-item{
                    .image-text{
                       text-align: left;
                    }
                }
                .owl-nav{
                    .owl-prev{
                        left: -5.5%;
                    }
                    .owl-next{
                        right:-5.5%;
                    }
                }
            }
            .gallery-learn-more{
                display: inline-block;
                background: $white;
                font-size: 18px;
                padding: 10px 30px;
                border-radius: 30px;
                min-width: 150px;
                text-align: center;
                margin: auto;
                margin-top: 30px;
                color: $black-new;
                font-family: $atten-font;
                font-weight: $font-atten-bold;

                text-decoration: none;
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .gallery-info{
            padding: 15% 10%;
        }
    }
    @include max-width($iphone6) {
        .gallery-info{
            .heading-section{
                width: 100%;
            }
        }
    }
}
