@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
// blog listing  page scss
.blog_listing_container {
  .blog-cover-section {
    width: 100%;
    background-color: $orange;
    background-position: right center;
    background-size: 40px 85%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/border.jpg");
    .blog-cover-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .blog-cover-info {
        padding: 5%;
        .header-section {
          width: 80%;
          .heading-section {
            text-align: left;
            width: 100%;
            margin: unset;
            .sub-title {
              color: $white;
              font-size: 16px;
              letter-spacing: 4.8px;
              line-height: 25px;
              padding-bottom: 10px;
            }
            .main-title {
              color: $white;
              padding: 15px 0;
              font-size: 48px;
              line-height: 50px;
            }
          }
          .title-info {
            color: $white;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            padding: 10px 0 10px;
            a {
              color: $wildsand;
              &:hover {
                color: $wildsand;
              }
            }
            p {
              color: $white;
            }
          }
          p {
            margin: 0;
            padding: 0;
          }
          .read_more {
            @include white-learn-more;
            display: inline-block;
            margin-top: 50px;
            min-width: 314px;
            background-color: $white;
            border: 1px solid $white;
            letter-spacing: 3.2px;
            &.no-link {
              pointer-events: none;
            }
            &:hover {
              color: $white;
              background-color: $orange;
              border: 1px solid $white;
            }
          }
        }
      }
      .blog-cover-img {
        padding: 6% 1.35% 6% 6%;
        img {
          height: 536px;
        }
      }
      .divide-equal:first-child:nth-last-child(2),
      .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50%;
      }
      .divide-equal:first-child:nth-last-child(1) {
        width: 100%;
      }
      @include max-width($desktop-xl) {
        .blog-cover-img {
          img {
            height: 450px;
          }
        }
      }
      @include max-width($desktop-large) {
        .blog-cover-info {
          .header-section {
            .heading-section {
              .main-title {
                font-size: 42px;
                line-height: 45px;
                padding-bottom: 5px;
              }
            }
            .read_more {
              min-width: 250px;
              letter-spacing: 2px;
            }
          }
        }
      }
      @include max-width($tablet-landscape) {
        .blog-cover-info {
          .header-section {
            width: 80%;
          }
        }
        .blog-cover-img {
          img {
            height: 370px;
          }
        }
      }
      @include max-width($iphonex-landscape) {
        .blog-cover-img {
          width: 100% !important;
          padding-bottom: 0;
          padding-right: 5%;
          img {
            height: 300px;
          }
        }
        .blog-cover-info {
          width: 100% !important;
          .header-section {
            width: 100%;
            .heading-section {
              .main-title {
                font-size: 40px;
                line-height: 42px;
              }
              .sub-title {
                padding-top: 15px;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
      @include max-width($iphone6-landscape) {
        .blog-cover-img {
          img {
            height: 262px;
          }
        }
        .blog-cover-info {
          .header-section {
            width: 100%;
            .heading-section {
              .main-title {
                padding-top: 10px;
                padding-bottom: 10px;
              }
              .sub-title {
                padding-top: 20px;
              }
            }
            .title-info {
              padding: 0;
            }
            .read_more {
              width: 100%;
              margin-top: 55px;
              margin-bottom: 35px;
            }
          }
        }
      }
    }
    @include max-width($desktop-large) {
      background-size: 30px 85%;
    }

    @include max-width($iphonex-landscape) {
      background-image: none;
    }
  }
  .category_list {
    display: flex;
    justify-content: center;
    padding: 3%;
    border-bottom: 1px solid $new-border-color;
    color: $black-new;
    flex-wrap: wrap;
    .category_dropdown_list {
      width: 100%;
      padding: 12px 20px;
      outline: none;
      border: 2px solid $new-border-color;
      -webkit-appearance: none;
      background: no-repeat calc(100% - 20px);
      text-transform: uppercase;
      font-size: 14px;
      background-image: url("../../assets/images/angle-arrow-down.png");
    }
    .cat_list_item {
      margin-right: 5%;
      cursor: pointer;
      font-weight: 600;
      padding: 1% 0;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3.6px;
      font-family: $arquitecta-bold;
      text-transform: uppercase;
      text-align: center;
      &:hover {
        color: $orange;
      }
      &.active {
        color: $orange;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @include max-width($desktop-large) {
      .cat_list_item {
        letter-spacing: 2px;
      }
    }
    @include max-width($tablet-landscape) {
      .cat_list_item {
        margin-right: 4%;
      }
    }
    @include max-width($iphonex-landscape) {
      display: block;
      padding: 40px 5%;
      border: none;
      .cat_list_item {
        display: inline-block;
        width: 50%;
        margin: 0;
        padding: 3px 0;
      }
    }
  }
  .card_heading_content {
    padding: 6%;
    padding-bottom: 3%;
    .brand_heading {
      color: $orange;
      text-transform: uppercase;
      font-family: $arquitecta-bold;
      font-size: 16px;
      letter-spacing: 4.8px;
      line-height: 25px;
      margin-bottom: 25px
    }
    .latest_post_heading {
      font-size: 68px;
      line-height: 70px;
      font-weight: 600;
    }
    @include max-width($desktop-large) {
      .latest_post_heading {
        font-size: 54px;
      }
      .brand_heading {
        letter-spacing: 4.2px;
        font-size:14px;
        line-height: 25px;
      }
    }
    @include max-width($iphonex-landscape) {
      .latest_post_heading {
        font-size: 48px;
        line-height: 60px;
      }
    }
  }
}
// blog details page scss
.blog-content-container {
  .blog-content {
    p {
      font-size: 18px;
      width: 80%;
      margin: auto;
      padding: 25px 0px;
      line-height: 20px;
      .wp-block-image {
        max-width: 1024px;
        display: table;
        margin: 0 auto;
      }
    }
    h3,h2 {
      font-family: $font-primary;
      width: 80%;
      margin: auto;
      padding-bottom: 1%;
      font-size: 30px;
      line-height: 42px;
      @include max-width($tablet-landscape) {
        width: 90%;
      }
    }
    h6.social_icons {
      width: 64%;
      margin: auto;
      a {
        color: #545454;
        font-size: 25px;
        padding: 0;
        padding-bottom: 5%;
        display: inline-block;
        padding-right: 5px;
        i{
          width: 30px;
          height: 30px;
        }
      }
    }
    .author_name_date {
      font-size: 14px;
      text-transform: capitalize;
      line-height: 20px;
      padding-bottom: 20px;
      width: 90%;
      margin: auto;
      @include max-width($iphone4-landscape) {
        width: 100%;
      }
    }
  }

  @include max-width($desktop) {
      .blog-content{
          p{
            width: 95%;
          }
          h3,h2 {
            width: 95%;
          }
          h6.social_icons {
            width:90%;
          }
      }
  }

  // @include max-width($tablet) {
  //     .blog-content{ width: 100%;
  //     border-right: unset;
  //     }
  // }
  @include max-width($iphone4-landscape) {
    .blog-content {
      padding: 5%;
      p {
        padding: 0;
        width: 100%;
        padding-bottom: 5%;
      }
      h3,h2 {
        width: 100%;
      }
      h6.social_icons {
        width:100%;
      }
    }
  }

  .blog-cover-detail-content {
    background-color: #f7f7f7;
    .blog-cover-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 3%;
      .blog-cover-info {
        padding: 5%;
        .header-section {
          width: 95%;
          .heading-section {
            text-align: left;
            width: 100%;
            margin: unset;
          }
          .sub-title {
            font-size: 16px;
            letter-spacing: 4.8px;
            line-height: 25px;
            padding-bottom: 10px;
          }
          .main-title {
            padding: 15px 0;
            font-size: 48px;
            line-height: 50px;
          }
          .title-info {
            color: $black-new;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            padding: 10px 0 10px;
            a {
              color: $wildsand;
              &:hover {
                color: $wildsand;
              }
            }
          }
          .author_name_date {
            font-size: 14px;
            text-transform: capitalize;
            line-height: 20px;
            padding-top: 10px;
          }
          p {
            margin: 0;
            padding: 0;
          }
        }
      }
      .blog-cover-img {
        img {
          height: 701px;
        }
      }
      .divide-equal:first-child:nth-last-child(2),
      .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50%;
      }
      .divide-equal:first-child:nth-last-child(1) {
        width: 100%;
      }
      @include max-width($desktop-xl) {
        .blog-cover-img {
          img {
            height: 650px;
          }
        }
      }
      @include max-width($desktop-large) {
        .blog-cover-info {
          .header-section {
            .heading-section {
              .main-title {
                font-size: 42px;
                line-height: 45px;
                padding-bottom: 5px;
              }
            }
          }
        }
        .blog-cover-img {
          img {
            height: 550px;
          }
        }
      }
      @include max-width($tablet-landscape) {
        .blog-cover-info {
          .header-section {
            width: 80%;
          }
        }
        .blog-cover-img {
          img {
            height: 485px;
          }
        }
      }
      @include max-width($iphonex-landscape) {
        padding-bottom: 0;
        .blog-cover-img {
          width: 100% !important;
          padding-bottom: 0;
          img {
            height: 386px;
          }
        }
        .blog-cover-info {
          width: 100% !important;
          padding-bottom: 0;
          .header-section {
            width: 100%;
            .heading-section {
              .main-title {
                font-size: 40px;
                line-height: 42px;
              }
              .sub-title {
                padding-top: 15px;
                padding-bottom: 20px;
                font-size: 14px;
                letter-spacing: 4.2px;
              }
            }
          }
        }
      }
      @include max-width($iphone6-landscape) {
        .blog-cover-info {
          .header-section {
            width: 100%;
            .heading-section {
              .main-title {
                padding-top: 10px;
                padding-bottom: 10px;
              }
              .sub-title {
                padding-top: 20px;
              }
            }
            .title-info {
              padding: 0;
            }
          }
        }
      }
    }
    .social_icons {
      a {
        color: #545454;
        font-size: 25px;
        padding-right: 5px;
        padding-top: 5%;
        display: inline-block;
        @include max-width($iphonex-landscape) {
          padding-top: 3%;
        }
        i{
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .category_list {
    display: flex;
    justify-content: center;
    padding: 2% 10% 1%;
    width: 100%;
    color: $black-new;
    flex-wrap: wrap;
    text-align: center;
    border-top: 1px solid $new-border-color;
    .cat_list_item {
      margin-right: 4%;
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 1%;
      line-height: 24px;
      letter-spacing: 3.6px;
      font-family: $arquitecta-bold;
      text-transform: uppercase;
      text-align: center;
      &:hover {
        color: $orange;
      }
      &.active {
        color: $orange;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @include max-width($desktop-large) {
      padding: 2% 4% 1%;
      .cat_list_item {
      letter-spacing: 2px;
      }
    }
    @include max-width($macbook) {
      padding: 2% 2% 1%;
    }
    @include max-width($tablet-landscape) {
      .cat_list_item {
        margin-right: 4%;
      }
    }
    @include max-width($iphonex-landscape) {
      display: none;
    }
  }
}

//lifestyle global scss
.lifestyle-global-div {
  .brand_heading {
    margin-bottom: 35px;
  }
  .latest_post_heading {
    line-height: 78px !important;
    font-weight: normal !important;
  }
  @include max-width($desktop-large) {
    .brand_heading {
      margin-bottom: 20px;
    }
    .latest_post_heading {
      line-height: normal !important;
    }
  }
}

.blogs-section{
  .blog_card_container {
    padding: 0 6%;
    padding-right: 1%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom:3%; 
    .blog_card {
      width: 28%;
      margin-right: 5%;
      padding-bottom: 15px;
      .blog_card_img {
        height: 500px;
      }
    }
    .blog_card_content {
      padding-bottom: 10%;
      .blog_card_category {
        padding: 4% 0;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 4.2px;
        color: $gray-new;
        font-family: $arquitecta-bold;
        padding-bottom: 5%;
      }
      .blog_card_content_para {
        font-size: 18px;
        line-height: 20px;
      }
      .read_more {
        text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        color: $orange;
        font-family: $arquitecta-heavy;
        line-height: 25px;
        letter-spacing: 3.2px;
        position: relative;
        &::before {
          content: " ";
          position: absolute;
          top: 50%;
          left: auto;
          width: 7px;
          height: 7px;
          border-top: 2px solid $orange;
          border-right: 2px solid $orange;
          -webkit-transform: translate(-1px, -50%) rotate(45deg);
          transform: translate(-1px, -50%) rotate(45deg);
          right: -24px;
        }

        &::after {
          background: $orange;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          height: 2px;
          width: 13px;
          content: " ";
          position: absolute;
          top: 50%;
          left: auto;
          right: -28px;
        }
      }
      @include max-width($iphone4-landscape) {
        padding-bottom: 7%;
        .blog_card_category {
          padding: 25px 0;
        }
      }
    }
    .blog_card_content_caption {
      h4 {
        font-size: 30px;
        font-family: $font-primary;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
    @include max-width($desktop-large) {
      padding-right: 1%;
      .blog_card {
        width: 29%;
        margin-right: 4%;
        .blog_card_img {
          height: 400px;
        }
      }
      .blog_card_content {
        .blog_card_content_caption {
          h4 {
            font-size: 24px;
          }
        }
      }
    }

    @include max-width($tablet-landscape) {
      .blog_card {
        .blog_card_img {
          height: 370px;
        }
      }
    }
    @include max-width($iphonex-landscape) {
      .blog_card {
        width: 45%;
        margin-right: 5%;
        .blog_card_img {
          height: 335px;
        }
      }
      .blog_card_content {
        .blog_card_content_caption {
          h4 {
            font-size: 30px;
          }
        }
      }
    }
    @include max-width($iphone4-landscape) {
      .blog_card {
        width: 100%;
      }
    }
  }
  .card_heading_content {
    padding: 6%;
    padding-bottom: 3%;
    .brand_heading {
      color: $orange;
      text-transform: uppercase;
      font-family: $arquitecta-bold;
      font-size: 16px;
      letter-spacing: 4.8px;
      line-height: 25px;
      margin-bottom: 25px;
    }
    .latest_post_heading {
      font-size: 68px;
      line-height: 70px;
      font-weight: 600;
    }
    @include max-width($desktop-large) {
      .latest_post_heading {
        font-size: 54px;
      }
      .brand_heading {
        letter-spacing: 4.2px;
        font-size:14px;
        line-height: 25px;
      }
    }
    @include max-width($iphonex-landscape) {
      .latest_post_heading {
        font-size: 48px;
        line-height: 60px;
      }
    }
    @include max-width($iphone4-landscape) {
      padding-top: 8%;
    }
  }
}