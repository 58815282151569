@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.blog-section {
  display: flex;
  background-color: $bglightgray;
  padding: 5% 0%;
  float: unset;
  width: 100%;
  .blog-content {
    // background-color: $white;
    padding: 7% 9.3%;
    width: 100%;
    background-size: 50px 100%;
    background-position: left;
    background-repeat: no-repeat;
    // border: 1px solid #DBDBDB;
    .blog-info-div {
      width: 100%;
    }
    .header-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 5%;
      flex-wrap: wrap;
      .heading-section-left {
        width: 35%;
        text-align: left;
        .heading-section {
          text-align: left;
          width: 100%;
          .main-title {
            font-size: 68px;
            line-height: 70px;
          }
        }
      }
      .heading-section-right {
        width: 55%;
        display: flex;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 40px;
        .title-info {
          font-size: 18px;
          line-height: 20px;
          color: $black-new;
          margin: 0;
          line-height: 20px;
          p {
            font-size: 18px;
            font-family: $font-primary;
          }
        }
        .learn-more {
          color: $orange;
          border-bottom: 2px solid $orange;
          font-size: 18px;
          font-family: $atten-font;
          font-weight: $font-atten-book;
          margin-top: 20px;
          letter-spacing: 3.6px;
          line-height: 18px;
          text-transform: uppercase;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .owl-stage {
      display: flex;
      justify-content: space-between;
    }
    .owl-carousel .owl-stage:after {
      display: none;
    }
    .blog-info {
      height: 100%;
      .blog-img {
        height: 400px;
        margin-bottom: 30px;
        img {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h6 {
        font-size: 22px;
        line-height: 24px;
        font-family: $font-primary;
        color: $black-new;
      }
      .blog-text {
        font-size: 16px;
        line-height: 20px;
        color: $black-new;
        margin: 0;
        p {
          margin: 0;
        }
      }
    }
    .blog-content-width {
      display: flex;
      .blog-info {
        margin-right: 3%;
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
    .blog-content-width .blog-info:first-child:nth-last-child(4),
    .blog-content-width .blog-info:first-child:nth-last-child(4) ~ div {
      width: 25%;
    }
    .blog-content-width .blog-info:first-child:nth-last-child(3),
    .blog-content-width .blog-info:first-child:nth-last-child(3) ~ div {
      width: 33.33%;
    }
    .blog-content-width .blog-info:first-child:nth-last-child(2),
    .blog-content-width .blog-info:first-child:nth-last-child(2) ~ div {
      width: 50%;
    }
    .blog-content-width .blog-info:first-child:nth-last-child(1) {
      width: 100%;
    }
    @include max-width($desktop-xl) {
      .blog-info {
        .blog-img {
          height: 400px;
        }
      }
    }
    @include max-width($desktop-large) {
      background-size: 30px 100%;
      margin: auto;
      .header-section {
        .heading-section-left {
          .heading-section {
            .main-title {
              font-size: 58px;
              line-height: 68px;
            }
          }
        }
        .heading-section-right {
          .learn-more {
            font-size: 18px;
            letter-spacing: -1px;
          }
        }
      }
      .blog-info {
        .blog-img {
          margin-bottom: 25px;
          img {
            max-width: 100%;
          }
        }
        h6 {
          font-size: 22px;
          line-height: 32px;
        }
      }
      .blog-content-width {
        .blog-info {
          margin-right: 2.5%;
        }
      }
    }
    @include min-width(1410px) {
      .header-section {
        .heading-section {
          .main-title {
            width: 70%;
          }
        }
      }
    }
    @include max-width($macbook) {
      .blog-info {
        .blog-img {
          height: 321px;
        }
      }
    }
    @include max-width($tablet-landscape) {
      .blog-info-div {
        .owl-stage {
          padding-left: 0px !important;
        }
      }
      .blog-info {
        .blog-img {
          height: 371px;
        }
      }
    }
    @include max-width($iphonex-landscape) {
      padding: 10% 5%;
      overflow: hidden;
      background-size: 18px 100%;

      .header-section {
        padding-bottom: 40px;
        .heading-section-left {
          width: 100%;
        }
        .heading-section-right {
          width: 100%;
          padding-bottom: 20px;
        }
      }
      .blog-info {
        .blog-img {
          height: 324px;
        }
      }
    }
    @include max-width($iphone6-plus-landscape) {
      padding: 5% 5% 0;
      background-image: none !important;
      .header-section {
        padding-bottom: 0;
        .heading-section-left {
          .heading-section {
            .main-title {
              font-size: 30px;
              line-height: 35px;
            }
          }
        }
        .heading-section-right {
          .title-info {
            font-size: 18px;
            line-height: 24px;
            color: $black-new;
            margin: 0;
            p {
              font-size: 16px;
              line-height: 20px;
              font-family: $font-primary;
            }
          }
          .learn-more {
            color: $orange;
            border-bottom: 2px solid $orange;
            font-size: 12px;
            font-family: $atten-font;
            font-weight: $font-atten-book;
            margin-top: 10px;
            letter-spacing: 3.6px;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 25px;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    @include max-width($iphone6-landscape) {
      padding: 5% 5%;
      border: unset;
      .owl-stage {
        justify-content: unset;
      }
      .blog-info {
        .blog-img {
          height: 321px;
        }
      }
    }
  }
  @include max-width($iphone6-plus-landscape) {
    padding: 10% 0%;
  }
  @include max-width($iphone4-landscape) {
    padding: 15% 0%;
  }
}
