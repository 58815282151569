@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.iframe-modal-section {
    float: unset;
  }
.iframe-main-div{
    display:flex;
    background-color:$white;
    // padding: 8% 5%;
    flex-wrap: wrap;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 36px 74%;
    .iframe-content{
        padding:5%;        
        .iframe-content-section{
            width: 75%;
            padding-left: 10%;
            .heading-section {
                width: 100%;
                text-align: left;
            }
            .iframe-description{
                font-size:18px;
                color: $black-new;
                padding-bottom: 30px;
                margin-bottom: 0;
                letter-spacing:0px;
                line-height:20px;
            }
            .iframe-button{
                @include orange-learn-more;
                display: inline-block;
                margin-top: 20px;
                min-width: 314px;
                &:hover{
                    color: $white;
                    background-color: $orange;
                }  
            }
        }
    }
    .iframe-image{
        height: 960px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .iframe-modal-button{
            position: absolute;
            // top:50%;
            // left:50%;
            // transform: translate(-50%, -50%);
            bottom: 5%;
            left: 5%;
            .iframe-view-more{
                text-decoration: none;
                font-size: 26.5px;
                background:$black-new;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                padding: 16px 24px;
                text-align: center;
                // display: inline-block;
                min-width: 180px;
                color: $white;
            }
        }
    } 
    .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50% ;
    }
    .divide-equal:first-child:nth-last-child(2) ~ div {
        width: 50% ;
        background-color:#EAEAEA;
        display: flex;
        justify-content: center;
    }
    .divide-equal:first-child:nth-last-child(1) {
        width: 100% ;
    }
    @include max-width($desktop-xl) {
        .iframe-image{
            height:780px;
        } 
    }
    @include max-width($desktop-large) {
        .iframe-content{
            .iframe-content-section{
                .iframe-button{
                    min-width: 250px;
                    letter-spacing: 2px;
                }
            }   
        }
        .iframe-image{
            height: 720px;
            // .iframe-modal-button{
            //     .iframe-view-more{
            //         font-size: 18px;
            //         // padding: 10px;
            //     }
            // }
        }
    }
    @include max-width($tablet-landscape) {
        .iframe-image{
            height:640px;
        } 
    }
    @include max-width($iphonex-landscape) {
        flex-direction: column-reverse;
        background-size: 12px 90%;
        padding: 0 0px 0 0px;

        .divide-equal:first-child:nth-last-child(2) ~ div img{
            max-height: 100%;
            object-fit: cover;
            width: 100%;
        }
    
        .iframe-content{
            width:100% !important;
            padding:5% 5%;
            .iframe-content-section{
                width:100%;
                padding-left: unset;
                .iframe-description{
                    padding-bottom: 0px;
                }
            }   
        }
        .iframe-image{
            width:100% !important;
            height:400px;
            margin-top: 3%;
            .iframe-modal-button{
                bottom: 10%;
            }
        }
    }

    @include max-width($iphone6-plus-landscape) {
        .iframe-image{
            height:300px;
        }
    }


    @include max-width($iphone4-landscape) {
        .iframe-content{
            padding: 15% 5% 15% 8%;
            .iframe-content-section{
                .iframe-button{
                    width:100%;
                }
            }
        }
        .iframe-image{
            height: 280px;
            margin-top: 0;
            .iframe-modal-button{
                .iframe-view-more{
                    min-width: 120px;
                    padding: 10px 15px 7px 15px;
                }
            }
           
        }
    }
} 

