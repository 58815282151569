@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.blocks-image {
  padding: 8% 5%;
  background: $bggray-new;
  margin: 8.5% auto;
  width: 98%;
  .four-blocks {
    padding: 5% 5% 5% 2%;
    background: $bggray-new;
    margin: 0%;
    .block-info {
      padding: 4% 5%;
    }
    .icon-image {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
    .title {
      font-size: 34px;
      line-height: 50px;
      margin-bottom: 10px;
      color: $black-new;
    }
    .text {
      font-size: 18px;
      margin-bottom: 28px;
      color: $black-new;
      line-height: 20px;
    }
    .learn-more {
      color: $orange;
      text-transform: uppercase;
      font-family: $atten-font;
      font-weight: $font-atten-bold;

      border-bottom: 2px solid $orange;
      font-size: 18px;
      letter-spacing: 3.6px;
      line-height: 30px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .side-image {
    //padding: 0 50px 0 0 !important;
    position: relative;
    height: 794px;
    max-width: 536px;
    &::after {
      content: "";
      position: absolute;
      left: 0px;
      width: 100%;
      height: 92.44%;
      top: 4%;
      background: #a3c144;
      z-index: 0;
    }

    img {
      position: absolute;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 90.7%;
      z-index: 1;
     // max-width: 486px;
    }
  }
  @include max-width($desktop-large) {
    .four-blocks {
      .block-info {
        padding: 35px 20px;
      }
      .title {
        font-size: 26px;
        margin-bottom: 10px;
      }
      .text {
        margin-bottom: 28px;
      }
      .learn-more {
        font-size: 18px;
        letter-spacing: -1px;
        line-height: 24px;
      }
    }

    .side-image {
      padding: 0 30px 0 0 !important;
      height: 590px;
    }
  }
  @include max-width($tablet-landscape) {
    .four-blocks {
      .title {
        font-size: 24px;
      }
    }
  }
  @include max-width($iphonex-landscape) {
    padding: 50px 25px;
    .special-case {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .four-blocks {
      padding: 5% 5% 5% 0;
      .icon-image {
        width: 25px;
        height: 25px;
        margin-right: 20px;
        margin-bottom: 0;
      }
      .title {
        margin-bottom: 0;
      }
    }
    .side-image {
      height: 550px;
      order: -1;
      margin: 0 auto;
    }
  }
  @include max-width($iphone6) {
    .side-image {
      height: 350px;
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .side-image {
      padding: 0 20px 0 0 !important;
    }
  }
  @include max-width($iphone4-landscape) {
    .four-blocks {
      padding: 5% 10%;
      .block-info {
        padding: 35px 0px;
      }
    }
  }
}
