@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.comm_gallery_modal {
    
    *:focus {
        outline: 0;
    }
    
    .modal-dialog {
        .modal-content {
            .modal-header {
                position: absolute;
                width: 100%;
                z-index: 4;
                display: flex;
                border: 0;
                justify-content: flex-end;
                background: $white;

                .modal-title {
                    display: none;
                }
            }

            .modal-body {
                display: flex;
                justify-content: center;
                max-width: 100%;
                // margin: 0 auto 50px auto;
                // position: relative;
                width: 100%;
                height: 100%;
                position: relative;
                background: $white;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
    .swiper-container{
        width: 100%;
        height: 100%;
        .swiper-slide{
            display: flex !important;
            align-items: center;
            // padding-top: 5vh;
            // height: 100vh !important;
            overflow: hidden;
            display: flex !important;
            flex-direction: column;
            justify-content: center;

            > img {
                object-position: bottom;
                object-fit: contain;
                width: 70%;
                height: auto;
                max-width: 100%;
                max-height: 70%;
            }

            > p {
                font-family: $font-primary;
                font-size: 12px;
                letter-spacing: 4.8px;
                line-height: 16px;
                margin: 10px 0 0 0;
                text-align: center;
                padding: 0 20px;
                width:70%;
            }
        }
        .swiper-pagination{
            &.swiper-pagination-bullets{
                right: 6%;
                // height: 60% !important;
                width: 15px;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
            }
            .swiper-pagination-bullet{
                width: 12px;
                height: 12px;
                transform: unset;
                &.swiper-pagination-bullet-active{
                    background: $orange;
                }
            }
        }
    }

    @include max-width($tablet-landscape) {
        .swiper-container{
            .swiper-slide{
                > img {
                    width: 80%;
                }
                > p {
                    width:85%;
                }
            }
            .swiper-pagination{
                &.swiper-pagination-bullets{
                    right: 3%;
                }
                .swiper-pagination-bullet{
                    width: 8px;
                    height: 8px;
                    &.swiper-pagination-bullet-active{
                        background: $orange;
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .swiper-container{
            .swiper-slide{
                > p {
                    width: 90%;
                }
            }
        }
    }
}
