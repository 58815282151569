@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.video-container {
  max-width: 1762px !important;
}
.video-block-section {
  float: unset;
}
.video-main-div {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  padding: 6% 0;
  margin: 0 auto;
  align-items: center;
  .video-iframe {
    width: 53.81%;
    height: 598px;
    position: relative;
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
    .videoArrow {
      background: $orange;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 88px;
      height: 88px;
      transform: translate(-50%, -50%);
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .video-info {
    width: 46%;
    padding: 2% 2% 0 12%;
    .heading-section {
      width: 100%;
      text-align: left;
      .main-title {
        width: 100%;
      }
    }
    .title-info {
      color: $black-new;
      font-size: 18px;
      margin: 0;
      padding: 10px 0 25px;
      line-height: 20px;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
    ul {
      li {
        color: $black-new;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .learn-more {
      @include orange-learn-more;
      display: inline-block;
      margin-top: 20px;
      min-width: 314px;
      transition: all 0.5s ease;
      &:hover {
        color: $white;
        background-color: $orange;
        border: 1px solid $orange;
      }
    }
  }
  .divide-equal:first-child:nth-last-child(1) {
    width: 75%;
  }
  @include max-width($desktop-large) {
    padding: 10% 0;
    .video-iframe {
      height: 550px;
      .videoArrow {
        width: 60px;
        height: 60px;
        &:after {
          border-width: 8px 0 8px 10px;
        }
      }
    }
    .video-info {
      padding: 2% 8% 0;
      // .heading-section{
      //     .main-title {
      //         font-size: 42px;
      //     }
      // }
      .learn-more {
        min-width: 250px;
        letter-spacing: 2px;
      }
    }
  }
  @include max-width($macbook) {
    .video-info {
      padding: 2% 5% 0 8%;
      .heading-section {
        .main-title {
          width: 100%;
        }
      }
    }
  }
  @include max-width($iphonex-landscape) {
    width: 100%;
    padding: 15% 0;
    .video-iframe {
      width: 100%;
      padding: 0;
      height: 400px;
      min-height: auto;
      // order:1;
    }
    .video-info {
      width: 100%;
      padding: 10% 5% 0;
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .video-info {
      padding: 8% 0% 2%;
      .heading-section {
        width: 100%;
        text-align: left;
        .main-title {
          width: 100%;
        }
      }

      .title-info {
        color: $black-new;
        font-size: 20px;
        margin: 0;
        padding: 10px 0 25px;
        letter-spacing: 0px;
        line-height: 30px;
        a {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
      .learn-more {
        @include orange-learn-more;
        display: inline-block;
        margin-top: 0px;
        min-width: auto;
        &:hover {
          color: $white;
          background-color: $orange;
          border: 1px solid $orange;
        }
      }
    }
  }
  @include max-width($iphone6-landscape) {
    .video-iframe {
      height: 270px;
    }
  }
}

.direction-map-div {
  @include max-width($iphonex-landscape) {
    .video-iframe {
      order: 0;
    }
    .video-info{
      padding:10% 5% 0 !important;
    }
  }
}
