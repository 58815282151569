@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";


.account_info{
    padding: 2% 0% 0;
    .user_mobile_details{
        display: none;
    }
    .user_name_section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
    }
    .sectiontitle-main-div{
        padding: 0;
    }
    .assist_message{
        padding: 10px;
        text-align: center;
        background-color:$black-new;
        color: $white;
        font-size: 20px;
        margin: 15px 0;
    }
    .account_options{
            padding: 0 5%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            .react-tabs__tab-list{
                border: none;
                margin: 0;
                .react-tabs__tab--selected{
                    border: none;
                    border-bottom: 3px solid $black-new;
                    &:focus{
                        border-color: $black-new;
                        box-shadow: none;
                    }
                }
            }
        .logout_btn{
            display: flex;
            align-items: center;

            h4{
                margin: 0 20px 0 0;
            }
            a{
                color: $black-new;
                text-decoration: underline;
                &:hover{
                    color: $black-new;
                }
            }
        }
        .mobile_logout_btn{
            display: none;
        }
    }

    .account_details{
        padding: 5%;
        .profile_details{
            .profile_form{
                max-width: 30%;
                margin: auto;
                input{
                    &:focus{
                        border-color: $orange;
                        box-shadow: none;
                    }
                }
            }
            button{
                background-color: $orange ;
                color: $white ;
                border-radius : 50px ;
                font-size: 16px;
                letter-spacing: 3.9px;
                font-family: $atten-font;
                font-weight: $font-atten-bold;

                width: 100%;
                text-transform: uppercase;
                line-height: normal;
                padding: 8px 0;
                border: 3px solid transparent;
                display: block;
                transition: all 0.8s ease;

                &:focus,&:active,&:hover{
                    box-shadow: none;
                    background:$bglightgray;
                    color:$orange;
                    border-color: $orange;
                }

                &.update_pwd_btn{
                    display: block;
                    color: $white;
                    background-color: $black-new;
                    font-size: 13px;
                    border-radius: 3px;
                    &:focus,&:active,&:hover{
                        background:$bglightgray;
                        color:$black-new;
                        border-color: $black-new;
                    }
                }
            }
        }

        .user_floorplan_details{
            position: relative;
            .user_loader{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2%;
                img{
                    height: 100px;
                    width: 100px;
                }
            }
            .user_floorplan_list{
                display: flex;
                flex-wrap: wrap;
                .user_floorplan{
                    padding: 20px;
                    width: 33%;
                    display: inline-block;
                    .user_fp_section{
                        position: relative;
                        height: 434px;
                        .floor_overlay{
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            top: 100%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            opacity: 0;
                            justify-content: center;
                            align-items: center;
                            background: rgba($color: #000000, $alpha: 0.5);
                            transition: all 0.5s ease;
                            a{
                                border: 1px solid $white;
                                padding: 5px 20px;
                                border-radius: 50px;
                                color:$black-new;
                                background: $white;
                                font-size: 17px;
                                &:hover{
                                    text-decoration: none;
                                }
                                &.plan_delete{
                                    color:$white;
                                    background: transparent;
                                    margin-top: 10px;
                                    padding: 2px 10px;
                                    font-size: 21px;
                                }
                            }
                        }

                        &:hover{
                            .floor_overlay{
                                opacity: 1;
                                top: 0;
                            }
                        }

                    }

                    .user_plan_details{
                        margin-top: 10px;      
                        h5,p{
                            margin: 0;
                        }
                    }
                }
            }
            .no_floorplans{
                margin: 0;
                text-align: center;
            }
            

            .owl-theme {
                .owl-nav{
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    left: 50%;
                    transform: translate(-50%,-100%);
                    display: flex !important;
                    justify-content: space-between;
                    button{
                        background-color: $lightgray;
                        color: $white; 
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        margin: 0;
                        position: relative;
                        span{
                            display: none;
                        }
                        &:focus{
                            outline: none;
                        }
                        &:before{
                            content: "";
                            width: 10px;
                            height: 10px;
                            border: 2px solid $white;
                            position: absolute;
                            top: 50%;
                            left: 50%;;
                        }
                        &.owl-prev{
                            &:before{
                                border-top-color: transparent;
                                border-right-color: transparent;
                                transform: translate(-35%,-50%) rotate(45deg);
                            }
                        }
                        &.owl-next{
                            &:before{
                                border-bottom-color: transparent;
                                border-left-color: transparent;
                                transform: translate(-65%,-50%) rotate(45deg);     
                            }            
                        }  
                    }
                }      
            }

            
        }
    }
    @include max-width($tablet-landscape) {
        .account_details{
            .user_floorplan_details{
                .user_floorplan_list{
                    .user_floorplan{
                        width: 100%;
                        .user_fp_section{
                            .floor_overlay{
                                opacity: 1;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

    }
    @include max-width($iphonex-landscape){
        .account_details{
            .profile_details{
                .profile_form{
                    max-width: 60%;
                }
            }

            .user_floorplan_details{
                .user_floorplan_list{
                    .user_floorplan{
                        .user_fp_section{
                            height: 334px;
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .user_mobile_details{
            display: block;
            padding: 0 5% 5%;
            p{
                margin: 0;
            }
        }
        .sectiontitle-headline{
            font-size: 41px;
            line-height: 35px;
        }
        .account_options{
            justify-content: center;
            .logout_btn{
                display: none;
            }
            .mobile_logout_btn{
                display: block;
                padding: 8%;
                position: fixed;
                bottom: 0;
                z-index: 4;
                width: 100%;
                background: $black-new;
            
                a{
                    background-color: $orange ;
                    color: $white ;
                    border-radius : 50px ;
                    font-size: 16px;
                    letter-spacing: 3.9px;
                    font-family: $atten-font;
                    font-weight: $font-atten-bold;

                    width: 100%;
                    text-transform: uppercase;
                    line-height: normal;
                    padding: 10px;
                    display: block;
                    text-align: center;
                }
            }
        }

        .account_details{
            .profile_details{
                .profile_form{
                    max-width: 90%;
                    button{
                        font-size: 15px;
                        &.update_pwd_btn{
                            font-size: 13px;  
                        }
                    }
                }
            }
        }

    }
}
