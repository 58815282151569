// ----- Fonts ----- //

// To edit/update icomoon fonts go to https://icomoon.io and upload the icomoon.json from the fonts directory

$fonts: (
    ("Belizio Regular", "Belizio-Regular"),
    ("Arquitecta Heavy", "Arquitecta-Heavy"),
    ("Arquitecta Bold", "Arquitecta-Bold"),
);

@each $font in $fonts {
    @font-face {
        font-family: "#{nth($font, 1)}";
        font-style: normal;
        font-weight: normal;
        src: url("./#{nth($font, 2)}.eot");
        src: url("./#{nth($font, 2)}.eot") format("embedded-opentype"),
             url("./#{nth($font, 2)}.woff2") format("woff2"),
             url("./#{nth($font, 2)}.woff") format("woff"),
             url("./#{nth($font, 2)}.ttf") format("truetype"),
             url("./#{nth($font, 2)}.svg") format("svg");
    }
}