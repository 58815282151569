@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.mortgage-section-main{
    position: relative;
    z-index: 1;    
    // float: left;
    width: 100%;

    // .frequency_data{
    //     text-transform: lowercase;
    // }
    
    .mortgage-section{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 600px;
        .mortgage-content-top{
            width:50%;
            padding:5%;
            padding-bottom: 2%;
            .mortgage-content-info{
                
                .heading-section{
                   text-align: left;
                   width: 100%;
                }
                .plan-price{
                    font-size: 20px;
                    margin: 0px 0 20px;
                    font-family: $arquitecta-heavy;
                    color: $black-new;
                    line-height: 20px;
                    text-transform: uppercase;
                }
                .plan-text{
                    font-size:18px;
                    line-height: 20px;
                    color: $black-new;
                }
                .apply-here{
                    @include orange-learn-more;
                    margin-top: 45px;
                    min-width: 314px;
                    &:hover{
                        color: $white;
                        background-color: $orange;
                    }  
                }
            }
            .disclaimer-text{
                font-size: 12px;
                margin: 0;
                color:#545454;
                margin-top: 45px;
                line-height: 16px;
            }
        }
        
        .mortgage-img{
            width: 725px;
            height: 960px;
            img{
                object-fit: cover;
                height: 100%;
            }
        }
    }
    .mortgage-form{
        box-shadow: 0px 10px 50px #00000029;
        display: flex;
        flex-wrap: wrap;
        background-color: $white;
        position: absolute;
        width: 25%;
        top:50%;
        right:25%;
        transform: translateY(-50%);
        max-width: 398px;
        Form{
            width:100%;
            padding: 20.605% 10%;
            .form-group {
                margin-bottom: 20px;
            }
            .form-label{
                color:$darkgray;
                font-size: 12px;
                margin-bottom: 5px;
                text-transform: uppercase;
                font-family: $arquitecta-heavy;
            }
            input.mortgage_field {
                padding: 0 8px !important;
            }
            .form-control{
                background-color:$bglightgray;
                box-shadow: unset;
                border: 1px solid #ced4da;
                padding: 0 5px;
                color:#242424;
                &:focus{
                    background-color:$bglightgray;
                }
            }
            .price-text{
                margin: 25px 0 10px;
                font-size: 16px;
                color:#242424;
                font-family: $arquitecta-heavy;
                text-transform: uppercase;
            }
            .total-price{
                font-size: 26px;
                font-family: $font-primary;
                color:#242424;
                margin: 0;
                font-family: $arquitecta-heavy;
            }
        }
        .mortgage-form-img{
            width: 50%;
            background-color: $bgdarkgray;
            img {
                height: 100%;
            }
        }
    }
    @include max-width($desktop-xl) {
        .mortgage-section{
            .mortgage-img {
                width:546px;
                height:723px;
            }
        }
    }
    @include max-width($desktop-large){
        .mortgage-section{
            min-height: 550px;
            .mortgage-img {
                width:472px;
                height:625px;
            }
            .mortgage-content-top{
                .mortgage-content-info{
                    .plan-price{
                        font-size: 20px;;
                        line-height: 20px;                        
                    }
                    .apply-here{
                        letter-spacing: 2px;
                        min-width: 250px;
                    }
                }
                
            }
        }
        .mortgage-form{
            width: 25%;
            Form{
                padding: 16% 10%;
                .form-group {
                    margin-bottom: 10px;
                }
                .form-label{
                    font-size: 10px;
                }
                .price-text{
                    // font-size: 13px;
                    margin: 20px 0 5px;
                }
                .total-price{
                    // font-size: 20px;
                    line-height: normal;
                }
            }
        }
    }
    @include max-width($tablet-landscape) {
        .mortgage-section{
            min-height: 520px;
            .mortgage-content-top{
                .mortgage-content-info{
                    width: 95%;
                }
            }
            .mortgage-img {
                width: 359px;
                height: 520px;
            }
        }
        .mortgage-form{
            width:30%;  
        }
    }
    @include max-width($tablet) {
        .mortgage-section{
            .mortgage-content-top{
                width:100%;
                padding:8% 5% !important;
                .mortgage-content-info{
                    width: 100%;
                    .apply-here{
                        margin-top: 35px;
                    }
                }
                .disclaimer-text{
                    margin-top: 70px;
                }
            }
            .mortgage-img{
                display: none;
            }
        }
        .mortgage-form{
            position: unset;
            width: 100%;
            transform: none;
            max-width: none;
            Form{
                .price-text{
                    font-size: 18px;
                }
                .total-price{
                    font-size: 26px;
                }
            }
        }
    }
    @include max-width($iphonex-landscape) {
        margin: 0 0 10%;
        .mortgage-form{
            Form{
                width:100%;
                .price-text{
                    font-size: 15px;
                }
                .total-price{
                    font-size: 22px;
                }
            }
            .mortgage-form-img{
                img {
                    width: 100%;
                }
            }
        }
    }
    @include max-width($tablet) {
        .mortgage-form{
            Form{
                width:50%;
                padding: 8% 6%;
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .mortgage-section{
            .mortgage-content-top{
                padding:15% 5% !important;
                .mortgage-content-info{
                    margin-bottom: 45px;
                    .apply-here{
                        margin-top: 25px;
                    }
                }
            }
        }
        .mortgage-form{
            Form{
                width: 100%;
                padding: 6% 10%;
                .price-text{
                    font-size: 15px;
                }
                .total-price{
                    font-size: 22px;
                }
            }
            .mortgage-form-img{
                width: 100%;
                order:-1;
                height: 250px;
                img {
                    width: 100%;
                }
            }
        }
    
    }
}