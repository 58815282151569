// ----- Fonts ----- //

$font-size: 15px;

$font-sans-serif: Arial, sans-serif;
$font-serif: "Times New Roman", Times, serif;

$font-primary: "Belizio Regular", $font-serif;
// $font-secondary: "Belizio Regular", $font-sans-serif;
$arquitecta-heavy: "Arquitecta Heavy", $font-sans-serif;
$arquitecta-bold: "Arquitecta Bold", $font-sans-serif;


$font-atten-book: 300;
$font-atten-medium: 500;
$font-atten-bold: 700;

$atten-font : "new-atten" , $font-sans-serif;