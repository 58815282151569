@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.qmi-card-section {
    background-color: $white;
    float: left;
    width: 100%;
    .sectiontitle-main-div {
        .sectiontitle-headline{
            padding: 7% 5% 1% 0;
        }
    }
    .qmi-card {
        justify-content: center;
        padding: 0.5% 4% 0;
        display: flex;
        flex-wrap: wrap;
        .card-main-div {
            margin-bottom: 4%;
            height: 735px;
            .card-imgs {
                position: relative;
                height: 653px;
                padding: 25px; // overflow: hidden;
                z-index: 1;
                .plan-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    .owl-carousel {
                        .owl-item {
                            overflow: hidden;
                        }
                        .owl-nav {
                            display: none;
                            position: absolute;
                            bottom: 40px;
                            right: 30px;
                            color: $white;
                            z-index: 2;
                            width: 70px;
                            height: 25px;
                            button {
                                &:after {
                                    position: absolute;
                                    content: "";
                                    width: 8px;
                                    height: 8px;
                                    border-right: 2px solid $white;
                                    border-bottom: 2px solid $white;
                                    transform: rotate(-45deg);
                                    top: 43%;
                                    right: 0;
                                }
                                &:before {
                                    position: absolute;
                                    content: '';
                                    top: 55%;
                                    height: 2px;
                                    width: 16px;
                                    background: $white;
                                    right: 0;
                                }
                                span {
                                    display: none;
                                }
                            }
                            .owl-prev {
                                &:after {
                                    transform: rotate(135deg);
                                    left: 25%;
                                }
                                &:before {
                                    left: 25%;
                                }
                            }
                        }
                        .owl-dots {
                            display: none;
                            .owl-dot {
                                span {
                                    background: $gray-new;
                                }
                                &.active {
                                    span {
                                        background: $orange;
                                    }
                                }
                            }
                        }
                    }
                    .overlay {
                        height: 100%;
                        &:after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background: transparent linear-gradient(180deg, #00000000 0%, #000000 300%) 0% 0% no-repeat padding-box;
                        }
                        img {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                }
                }
                .card-snipe {
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    padding: 8px;
                    background-color: $orange;
                    font-family: $font-primary;
                    color: $white;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                }
                .promo-icon{ 
                    height:70px; 
                    width:70px; 
                    position: absolute; 
                    z-index: 2; 
                    top: 0; 
                    left: 0; 
                    background-repeat: no-repeat;
                }
                .card-status {
                    background: #FEBF3B;
                    padding: 7px 10px;
                    display: inline-block;
                    font-size: 12px;
                    font-family:$arquitecta-heavy;
                    color:$black-new;
                    line-height: 13px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    letter-spacing: 1.1px;
                }
                .card-img-info {
                    position: absolute;
                    z-index: 7;
                    bottom: 30px;
                    color: $white;
                    padding-right: 10px;
                    width: 70%;
                    .card-name {
                        font-size: 38px;
                        letter-spacing: -0.93px;
                        line-height: 51px;
                        margin-bottom: 5px; 
                        font-family: $font-primary;
                    }
                    .card-price {
                        font-size: 18px;
                        line-height: 27px;
                        margin: 0;
                    }
                }
            }
            .owl-carousel,
            .owl-stage-outer,
            .owl-stage,
            .owl-item,
            .owl-item div {
                height: 100%;
            }
            .card-info {
                .card-detail {
                    display: flex;
                    justify-content: space-evenly;
                    margin: 0;
                    padding: 15px 0 10px;
                    align-items: center;
                    width: 100%;
                    li {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        list-style: none;
                        border-right: 1px solid $lightgray;
                        padding: 10px 5px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        text-align: center;
                        color: $black-new;
                        &:last-child {
                            border-right: none;
                        }
                        .info-value {
                            font-size: 16px;
                            font-family: $arquitecta-bold;
                            margin-bottom: 5px;
                            color: #545454;
                        }
                        .info-name {
                            font-size: 14px;
                            font-family: $arquitecta-bold;
                            color: #969696;
                        }
                    }
                }
            }
            .card-hover-content {
                box-shadow: 0 5px 6px rgba(0, 0, 0, .1);
                visibility: hidden;
                overflow: hidden;
                position: absolute;
                bottom: 0;
                width: calc(100% - 30px);
                // margin-right: 15px; // transition: height 0.5s;
                .qmi-card-content {
                    padding: 7%;
                    .card-name {
                        font-size: 22px;
                        font-family: $font-primary;
                        width: 80%;
                    }
                    .card-text {
                        min-height: 96px;
                    }
                    .learn-more {
                        border: 1px solid $new-border-color;
                        margin-top:4%;
                        color: $black-new;
                        padding: 2% 10%;
                        text-align: center;
                        font-family: $atten-font;
                        font-weight: $font-atten-medium;

                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        &:hover {
                            text-decoration: none;
                            color: $white;
                            background: $orange;
                        }
                    }
                }
            }
            &:hover {
                .card-imgs {
                    .plan-img-info {
                        display: none;
                    }
                }
                .owl-nav {
                    display: block !important;
                    bottom: -75px !important;
                    button {
                        &:after {
                            border-right: 2px solid $black-new !important;
                            border-bottom: 2px solid $black-new !important;
                        }
                        &:before {
                            background: $black-new !important;
                        }
                    }
                }
            }
            @include max-width($desktop-xl) {
                height: 682px;
                .card-imgs {
                    height: 600px;
                }
                &:hover {
                    .owl-nav {
                        bottom: -65px !important;
                    }
                }
            }
            @include max-width($desktop-large) {
                height: 576px;
                .card-imgs {
                    height: 500px;
                    .card-img-info {
                        .card-name {
                            font-size: 34px;
                            line-height: 40px;
                        }
                        .card-price {
                            font-size: 18px;
                            line-height: 21px;
                        }
                    }
                }
                &:hover {
                    .owl-nav {
                        bottom: -65px !important;
                    }
                }
                .card-info {
                    .card-detail {
                        li {
                            .info-value {
                                font-size: 12px;
                            }
                            .info-name {
                                font-size: 9px;
                            }
                        }
                    }
                }
            }
            @include max-width($macbook) {
                .card-info {
                    .card-detail {
                        li {
                            .info-value {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
            @include max-width($tablet-landscape) {
                height: 514px;
                .card-imgs {
                    height: 442px;
                    .card-img-info {
                        .card-name {
                            font-size: 30px;
                            line-height: 40px;
                        }
                    }
                    .plan-img {
                        .owl-carousel {
                            .owl-nav {
                                display: none !important;
                            }
                            .owl-dots {
                                display: block;
                                position: absolute;
                                text-align: center;
                                width: 100%;
                                bottom: 0;
                                .owl-dot {
                                    outline: none !important;
                                    span {
                                        width: 5px;
                                        height: 5px;
                                        border-radius: 50%;
                                        margin: 5px;
                                    }
                                }
                            }
                        }
                    }
                    .card-snipe {
                        color: $black-new;
                    }
                    
                }
                .card-hover-content {
                    display: none !important;
                }
                &:hover {
                    .card-info {
                        display: block !important;
                    }
                    .card-imgs {
                        .plan-img-info {
                            display: block !important;
                        }
                    }
                    .card-hover-content {
                        display: none !important;
                    }
                }
                .card-info {
                    .card-detail {
                        li {
                            letter-spacing: normal;
                            .info-value {
                                font-size: 10px;
                            }
                            .info-name {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
           
            @include max-width($iphone6-plus-landscape) {
                margin-bottom: 8%;
               
            }
            @include max-width($tablet) {
                &:hover {
                    .owl-nav {
                        bottom: -45px !important;
                    }
                }
            }
            @include max-width($iphone6-plus-landscape) {
                &:hover {
                    .owl-nav {
                        bottom: -40px !important;
                    }
                }
            }
            @include max-width($iphone4-landscape) {
                margin-bottom: 12%;
                padding: 0;
                .card-imgs {
                    height: 430px !important;
                
                }
               
                .card-info {
                    .card-detail {
                        box-shadow: 0 5px 6px rgba(0, 0, 0, .1);
                        li {
                            border: none;
                            padding: 15px 0px;
                            width: auto;
                            .info-value {
                                font-size: 14px;
                            }
                            .info-name {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
        @include max-width($iphone4-landscape) {
            padding: 0.5% 2% 5%;
        }
    }
}

.load-more {
    display: block;
    border: 0;
    margin: 0px auto 20px;
    padding: 10px 20px;
    outline: none !important;
    font-size: 16px;
    background-color: $black-new;
    color: $white;
    text-transform: uppercase;
    font-family: $font-primary;
    cursor: pointer;
}