@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.home-banner-section {
  float:none;
}
.banner_content {
  // float: left;
  width: 100%;
  padding-bottom: 54px;
  .test-banner {
    width: 90%;
    padding: 1.3% 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .banner_title {
    // float: left;
    width: 37%;
    padding-left: 3%;

    h2 {
      // text-align: left;
      font-size: 100px;
      line-height: 100px;
      letter-spacing: 0px;
      color: #545454;
      opacity: 1;
      word-break: break-all;
      // width: 50%;
    }
    a {
      text-align: center;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 3.2px;
      color: #e5721f;
      text-transform: uppercase;
      font-family: $arquitecta-heavy;
      border: 1px solid #e5721f;
      opacity: 1;
      padding: 19px 65px;
      display: inline-block;
      margin: 33px 0 0;
      transition: all 0.5s ease;
      text-decoration: none;
      &:hover {
        color: #ffffff;
        background: #e5721f;
      }
    }
  }
  .banner_image {
    width: 60%;
    // float: right;
    // margin-right: 8%;
  }
  .divide-equal:first-child:nth-last-child(1) {
    width: 60% !important;
    margin: auto !important;
    // float: unset;
    text-align: center;
  }

  .banner_slider {
    width: 98%;
    margin: 0 auto;

    .slick-slide {
      overflow: hidden;
    }

    button.slick-arrow.slick-prev,
    button.slick-arrow.slick-next {
      background: #e5721f 0% 0% no-repeat;
      opacity: 1;
      border: none;
      z-index: 1;
      width: 61px;
      height: 61px;
    }

    .slick-prev {
      left: 0;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 40%;
        width: 8px;
        height: 8px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        transform: translate(-1px, -50%) rotate(-135deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: #ffffff;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 16px;
      }
    }

    .slick-next {
      right: 0;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        transform: translate(-1px, -50%) rotate(45deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: #ffffff;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 16px;
      }
    }
  }

  @include max-width($desktop-large) {
    .banner_title {
      h2 {
        font-size: 70px;
        line-height: 70px;
      }
      a {
        padding: 15px 35px;
        font-size: 14px;
        line-height: 23px;
        margin: 18px 0 0;
      }
    }
  }
  @include max-width($macbook) {
    .banner_title {
      padding-left: 6.8%;
      h2 {
        font-size: 50px;
        line-height: 50px;
      }
      a {
        font-size: 14px;
        line-height: 24px;
        padding: 19px 25px;
        margin: 13px 0 0;
      }
    }
    .banner_image {
      padding-right: 2.5%;
    }
  }

  @include max-width($iphonex-landscape) {
    .divide-equal:first-child:last-child {
      width: 100% !important;
    }
    .test-banner {
      flex-direction: column;
      width: 100%;
    }
    .banner_title {
      width: 100%;
      padding: 12px 12px;
      h2 {
        font-size: 40px;
        line-height: 40px;
        width: 100%;
      }
      a {
        font-size: 12px;
        line-height: 25px;
        letter-spacing: 3.2px;
        padding: 8px 15px;
        margin: 10px 0;
      }
      br {
        display: none;
      }
    }
    .banner_image {
      width: 100%;
      margin-right: 0;
      padding: 0% 5px;
    }

    .banner_slider {
      width: 98%;
      margin: 0 auto;

      button.slick-arrow.slick-prev,
      button.slick-arrow.slick-next {
        display: none !important;
      }

      .slick-dots li,
      .slick-dots li button {
        width: 8px;
        height: 8px;
      }
    }
  }

  @include max-width($iphone6-plus-landscape) {
    .banner_title {
      width: 100%;
      padding: 5px 5px;
      h2 {
        font-size: 28px;
        line-height: 40px;
        width: 100%;
      }
      a {
        font-size: 12px;
        line-height: 25px;
        letter-spacing: 3.2px;
        padding: 8px 15px;
        margin: 0 0 10px 0;
      }
    }
    .banner_image {
      width: 100%;
      padding: 0% 5px;
    }

    .banner_slider {
      width: 98%;
      margin: 0 auto;

      button.slick-arrow.slick-prev,
      button.slick-arrow.slick-next {
        display: none !important;
      }
    }
  }
  @include max-width($iphone6-landscape) {
    .test-banner{
      padding:1.3% 0px;
    }
  }
  @include max-width($tablet) {
    .banner_title{
        width: 100%;  
    }
  }
}
