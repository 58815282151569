@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.post-container {
  max-width: 1600px !important;
}
.post-section {
  display: flex;
  background-color: $white;
  padding: 4% 5% 0%;
  background: linear-gradient(360deg, #ffffff 70%, #545454 26%);

  &.less-gradient {
    background: linear-gradient(360deg, #ffffff 51%, #545454 42%);
  }

  .post-content {
    // background-color: $white;
    //  padding: 8% 8% 7.5%;
    width: 100%;
    // border: 1px solid #DBDBDB;
    .header-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 4%;
      flex-wrap: wrap;
      padding-left: 28px;
      .heading-section-left {
        width: 65%;
        text-align: left;
        .heading-section {
          text-align: left;
          width: 100%;
          .sub-title {
            font-size: 16px;
            line-height: 25px;
            text-align: left;
            letter-spacing: 4.8px;
            color: #ffffff;
            text-transform: uppercase;
          }
          .main-title {
            font-size: 48px;
            line-height: 50px;
            letter-spacing: 0px;
            padding: 23.5px 25px 23.5px 0px;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
    .col-md-8,
    .col-md-4 {
      padding-right: 42.5px;
      padding-left: 42.5px;
    }
    .post-info {
      height: 100%;
      margin-bottom: 63px;
      .post-img {
        margin-bottom: 25px;
        //float: left;
        width: 100%;
        background-color: $lightgray;
        img {
          max-width: 100%;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      span {
        font-size: 14px;
        line-height: 25px;
        text-align: left;
        letter-spacing: 4.2px;
        color: #e5721f;
        text-transform: uppercase;
        opacity: 1;
        font-family: $arquitecta-bold;
      }
      h6 {
        font-size: 22px;
        line-height: 24px;
        color: $black-new;
        padding: 5px 0 13px;
      }
      a {
        font-family: $atten-font;
        font-weight: $font-atten-bold;

        font-size: 18px;
        line-height: 30px;
        text-align: left;
        letter-spacing: 3.6px;
        color: #e5721f;
        text-transform: uppercase;
        border-bottom: 2px solid #e5721f;
        text-decoration: none;
      }
    }
    .horizontal-img {
      .post-info {
        .post-img {
          max-width: 881px;
          img {
            object-fit: cover;
            height: 394px;
            width: 100%;
          }
        }
      }
    }
    .square-img {
      .post-info {
        .post-img {
          max-width: 398px;

          img {
            height: 394px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @include max-width($desktop-xl) {
    background: linear-gradient(360deg, #ffffff 73%, #545454 26%);
    &.less-gradient {
      background: linear-gradient(360deg, #ffffff 57%, #545454 42%);
    }
    .post-content {
      .post-info {
        .post-img {
          height: auto;
        }
      }

      .col-md-8,
      .col-md-4 {
        padding-right: 22.5px;
        padding-left: 22.5px;
      }
    }
  }
  @include max-width($desktop-large) {
    .post-content {
      .header-section {
        .heading-section-left {
          .heading-section {
            .sub-title {
              font-size:14px;
              letter-spacing: 4.2px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  @include max-width($desktop) {
    .post-content {
      padding: 5%;
      .header-section {
        padding-bottom: 4.6%;
        .heading-section-left {
          width: 70%;
          text-align: left;
          .heading-section {
            .main-title {
              font-size: 38px;
              line-height: 40px;
            }
          }
        }
      }
      .post-info {
        .post-img {
          height: auto;
          img {
            height: 300px !important;
          }
        }
      }
    }
  }

  @include max-width($iphonex-landscape) {
    background: linear-gradient(360deg, #ffffff 75%, #545454 28%);
    .post-content {
      padding: 5% 0%;
      .header-section {
        padding-bottom: 40px;
        .heading-section-left {
          width: 100%;
        }
        .heading-section-right {
          width: 100%;
          padding-bottom: 20px;
        }
      }
      .post-info {
        .post-img {
          height: auto;
        }
      }
    }
  }

  @include max-width($iphone6-plus-landscape) {
    background: linear-gradient(360deg, #ffffff 83.5%, #545454 12%);
    &.less-gradient {
      background: linear-gradient(360deg, #ffffff 62%, #545454 42%);
    }
    .post-content {
      padding: 5% 0%;
      .header-section {
        width: 100%;
        padding-bottom: 2%;
        padding-left: 0px;
        .heading-section-left {
          width: 100%;
          text-align: left;
          .heading-section {
            .sub-title {
              font-size: 14px;
              line-height: 25px;
            }
            .main-title {
              font-size: 24px;
              line-height: 32px;
              padding: 13.5px 0;
            }
          }
        }
      }
      .col-md-8,
      .col-md-4 {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      .square-img {
        .post-info {
          .post-img {
            max-width: none;
            width: 100%;
          }
        }
      }
      .post-info {
        margin-bottom: 25px;
        .post-img {
          height: auto;
          margin-bottom: 15px;
          img {
            min-height: auto !important;
          }
        }
        span {
          font-size: 14px;
          line-height: 25px;
        }
        h6 {
          font-size: 20px;
          line-height: 22px;
          padding: 5px 0 8px;
        }
        a {
          font-size: 14px;
        }
      }
    }
  }

  @include max-width($iphone6-landscape) {
    .post-content {
      .square-img {
        .post-info {
          .post-img {
            img {
              max-width: 100%;
              min-height: 100%;
            }
          }
        }
      }
      .horizontal-img {
        .post-info {
          .post-img {
            img {
              max-width: 100%;
              min-height: 100%;
            }
          }
        }
      }
    }
  }
}
