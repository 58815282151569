@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.team-section {
  position: relative;
  background: #f7f7f7;
  padding: 127px 0 70px;
  margin: 0% 0 0;
  .main-title {
    font-size: 68px;
    line-height: 70px;
  }
  .container {
    padding: 0 12%;
    background-repeat: no-repeat;
    background-size: 40px 96%;
    background-position: top left;

    // &::before{
    //     content: "";
    //     position: absolute;
    //     width: 40px;
    //     left: 0;
    //     background:url(../../assets/images/team-border.jpg) no-repeat;
    //     background-size: contain;
    //     height: 100%;
    //     top: 0;
    // }

    .header-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 3.5%;
      flex-wrap: wrap;
      .heading-section-left {
        width: 35%;
        text-align: left;
        .heading-section {
          text-align: left;
          width: 100%;
        }
      }
      .heading-section-right {
        width: 55%;
        display: flex;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 40px;
        .title-info {
          font-size: 20px;
          line-height: 30px;
          color: $black-new;
          margin: 0;
          line-height: 30px;
          p {
            font-size: 20px;
            font-family: $font-primary;
          }
        }
        .learn-more {
          color: $black-new;
          border-bottom: 2px solid $orange;
          font-size: 24px;
          font-family: $font-primary;
          font-weight: 600;
          margin-top: 10px;
          letter-spacing: -1.2px;
          line-height: 30px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .col-md-4,
    .col-lg-12 {
      padding: 0 42.5px;
    }

    .team-info {
      margin: 0 0 77px;
      float: left;
      width: 100%;
      .team-img {
        img {
          width: 398px;
          height: 394px;
          object-fit: cover;
          max-width: 100%;
        }
      }
      h3 {
        text-align: left;
        font-size: 34px;
        line-height: 32px;
        letter-spacing: 0px;
        color: #545454;
        padding: 27px 0 0;
        float: left;
        width: 100%;
      }
      span {
        text-align: left;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 4.2px;
        color: #e5721f;
        text-transform: uppercase;
        padding: 10px 0 9px;
        font-family: $arquitecta-bold;
        float: left;
        width: 100%;
      }
      p.team-text {
        font-size: 18px;
        line-height: 20px;
        text-align: left;
        letter-spacing: 0px;
        color: #545454;
      }
    }

    @include max-width($desktop-xl) {
      .col-md-4,
      .col-lg-12 {
        padding: 0 22.5px;
      }
    }
    @include max-width($desktop-large) {
      .header-section {
        .main-title {
          font-size: 58px;
          line-height: 68px;
        }
      }
    }
    @include max-width($tablet-landscape) {
      .header-section {
        .heading-section-left {
          width: 100%;
        }
      }

      .team-info {
        .team-img {
          img {
            height: 290px;
          }
        }
      }
    }
    @include max-width($iphonex-landscape) {
      background-size: 20px 100%;
      .col-md-4,
      .col-lg-12 {
        padding: 0 15px;
      }

      .team-info {
        margin: 0 0 40px;
        h3 {
          font-size: 26px;
          line-height: 24px;
        }

        span {
          font-size: 13px;
          line-height: 23px;
        }

        p.team-text {
          font-size: 16px;
          line-height: 18px;
        }
        .team-img {
          img {
            height: 270px;
            object-fit: cover;
            max-width: 100%;
          }
        }
      }
    }

    @include max-width($iphone6-plus-landscape) {
      background-size: 12px 100%;
      .col-md-4,
      .col-lg-12 {
        padding: 0 15px;
      }

      .header-section {
        .main-title {
          font-size: 32px;
          line-height: 34px;
          letter-spacing: 0;
        }
      }

      .team-info {
        margin: 0 0 30px;

        .team-img {
          img {
            height: 250px;
            width: 100%;
          }
        }
      }
    }
  }

  @include max-width($iphone6-plus-landscape) {
    padding: 50px 0;
  }
}
