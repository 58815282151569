@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.sectiontitle-main-div {
  padding: 1% 5%;
  .sectiontitle-headline {
    color: $gray-new;
    font-size: 68px;
    font-family: $font-primary;
    word-break: break-word;
    // font-weight: 700;
    line-height: 70px;
    h5 {
      a:hover {
        text-decoration: none;
      }
    }
  }
  .eyebrow-text-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    width: 100%;
    .sectiontitle-eyebrow_text {
      text-transform: uppercase;
      font-family: $arquitecta-bold;
      font-size: 16px;
      letter-spacing: 4.8px;
      color: $orange;
      line-height: 25px;
      padding-right: 15px;
    }
  }

  .eyebrowtext-border{
    border-top: 2px solid #d2d0d0;
    flex-grow: 1;
  }
  
  @include max-width($desktop-large) {
    .sectiontitle-headline {
      font-size: 40px;
      line-height: 48px;
    }
    .eyebrow-text-div {
      .sectiontitle-eyebrow_text {
        font-size: 14px;
        letter-spacing: 4.2px;
        line-height: 25px;
      }
    }
  }
  // @include max-width($tablet-landscape) {
  //   .eyebrow-text-div {
  //     .sectiontitle-eyebrow_text {
  //       font-size: 12px;
  //     }
  //   }
  // }
  @include max-width($iphone4-landscape) {
    padding: 5%;
  }
}
