@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.living-smart-tabbed{
    .living-smart-div{
        .living-smart-tablist{
            display: flex;
            justify-content: space-around;
            padding: 0;
            .living-smart-tab{
                list-style: none;
                display: flex;
                padding: 10px;
                outline: unset;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-family: $arquitecta-bold;
                border-radius: 5px !important;
                &.react-tabs__tab--selected{
                    background: $orange;
                    color:  $white;
                }
                .tab-icon{
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                    object-fit: contain;
                }
                .tab-heading{
                    font-size: 24px;
                    margin: 0;
                }
                &.divide-equal:first-child:nth-last-child(5), &.divide-equal:first-child:nth-last-child(5) ~ li{
                    width: 20% !important;
                }
                &.divide-equal:first-child:nth-last-child(4), &.divide-equal:first-child:nth-last-child(4) ~ li {
                    width: 25% !important;
                }
                &.divide-equal:first-child:nth-last-child(3), &.divide-equal:first-child:nth-last-child(3) ~ li {
                    width: 33.33% !important;
                }
                &.divide-equal:first-child:nth-last-child(2), &.divide-equal:first-child:nth-last-child(2) ~ li {
                    width: 50% !important;
                }
                &.divide-equal:first-child:nth-last-child(1) {
                    width: 100% !important;
                }
            }
        }
        .react-tabs__tab-panel {
            display: flex;
            .tabbed-img{
                width: 40%;
                padding-top: 3%;
                min-height: 350px;
            }
            .tabbed-text{
                width: 60%;
                margin: 0;
                padding: 3%;
                color: $black-new;
                font-size: 18px;
                font-size: 18px;
                p{
                    margin: 0; 
                }
            }
            .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
                width: 50% !important;
            }
            .divide-equal:first-child:nth-last-child(1) {
                width: 100% !important;
            }
        }
        @include max-width($tablet-landscape) {
            .accordion {
                width: 100%;
                margin: auto;
                .card{
                    padding-bottom: 20px;
                    border: unset;
                    border-bottom: 1px solid $gray-new;
                    margin-bottom: 20px;
                    background-color: transparent;
                    &:last-child{
                        border-bottom: unset !important;
                        margin-bottom: unset !important;
                    }

                    .card-header{
                        background: transparent;
                        padding: 0;
                        border: none;
                        display: flex;
                        align-items: center;
                        position: relative;
                        .tab-icon{
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                            object-fit: contain;
                        }
                        .tab-heading{
                            font-size: 20px;
                            color: $black-new;
                            margin: 0;
                            line-height: 33px;
                            width: 80%;
                        }
                        .arrowRight {
                            position: relative;
                            top: 0px;
                            right: 0px;
                            border: 1px solid $new-border-color;
                            height: 35px;
                            width: 35px;
                            transition: all .5s ease;

                            &:after,
                            &:before {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                left: 45%;
                            }

                            &:after {
                                background: $black-new;
                                transform: translate(-50%, -50%);
                                height: 2px;
                                width: 16px;
                            }

                            &:before {
                                width: 8px;
                                height: 8px;
                                border-top: 2px solid $black-new;
                                border-right: 2px solid $black-new;
                                transform: translate(-1px, -50%) rotate(45deg);
                            }
                        }
                    }
                    &.active{
                        .arrowRight {
                            transform: rotate(90deg);
                        }
                    }
                    .card-body{
                        display: flex;
                        padding: 0;
                        .tabbed-img{
                            width: 40%;
                            padding-top: 3%;
                            min-height: 350px;
                        }
                        .tabbed-text{
                            width: 60%;
                            margin: 0;
                            padding: 3%;
                            color: $black-new;
                            font-size: 18px;
                            p{
                                margin: 0; 
                            }
                        }
                        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
                            width: 50% !important;
                        }
                        .divide-equal:first-child:nth-last-child(1) {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
        @include max-width($iphonex-landscape) {
            .accordion {
                .card{
                    .card-header{
                        .tab-heading{
                            line-height: 30px;
                            width: 75%;
                        }
                        // .arrowRight {
                        //     right: 25px;
                        // }
                    }
                    .card-body{
                        flex-wrap: wrap;
                        .tabbed-img{
                            width: 100%;
                            min-height: 250px;
                            padding-top: 5%;
                        }
                        .tabbed-text{
                            width: 100%;
                            padding: 5% 0 3%;
                        }
                        .divide-equal:first-child:nth-last-child(2), .divide-equal:first-child:nth-last-child(2) ~ div {
                            width: 100% !important;
                        }
                        .divide-equal:first-child:nth-last-child(1) {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
        @include max-width($iphone4-landscape) {
            .accordion {
                .card{
                    .card-header{
                        .tab-heading{
                            line-height: 26px;
                            width: 70%;
                        }
                        // .arrowRight {
                        //     right: 20px;
                        // }
                    }
                    .card-body{
                        .tabbed-img{
                            padding-top: 8%;
                        }
                        .tabbed-text{
                            padding: 8% 0 3%;
                        }
                    }
                }
            }
        }
    }
}
