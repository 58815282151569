
@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.schedule-tour-section{
    float: unset;
    &.right-side-border {
        .schedule-tour{
            background-position: right center;
        }
    }

    &.left-side-border {
        .schedule-tour{
            background-position: left center;
        }
    }
    .schedule-tour{
        display:flex;
        background:$white;
        flex-wrap: wrap;
        padding:5%;
        padding-top: 0;
        align-items: center;
        background-size:50px 80%;
        background-position: right center;
        background-repeat: no-repeat;
        justify-content: space-between;

        &.right {
            flex-direction: row-reverse;
        }
        .content-section{
            width: 40%;
            padding:8% 5%;
            .heading-section {
                width:100%;
                text-align: left;
            }
            .description{
                font-size:20px;
                color:$black-new;
                padding-bottom: 35px;
                letter-spacing:0px;
                line-height:24px;
                float: left;
                width: 80%;
            }
            p{
                margin:0;
            }
            .tour-schedule{
                @include orange-learn-more;
                display: inline-block;
                margin-top: 20px;
                min-width: 314px;
                margin-right: 10%;
                transition: all .5s ease;
                &:hover{
                    color: $white;
                    background-color: $orange;
                }  
                &.no-link{
                    pointer-events: none;
                }
            }
        }
        .two-image{
            width: 60%;
            // padding: 0% 10% 5%;
            padding-top: 5%;
            padding-bottom: 5%;
            display: flex;
            justify-content: center;
            .image-section{
                background: #325b81;
                position: relative;
                width: 631px;
                height: 777px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                
                .small-img img{
                    top: auto;
                    bottom: 9%;
                    height: auto;
                    left: -25%;
                    width: 72%;
                    max-height: 271px;
                    max-width: 383px;
                    object-fit: cover;
                }
                
                .med-img{
                    img {
                        width: 444px;
                        height:623px;
                        left: 55%;
                        object-fit: cover;
                        bottom: -9%;
                        top: auto;
                    }
                }
                .image-building-image{
                    position: absolute;
                    top: 50%;
                    left: 80%;
                    width: 337px;
                    height: 453px;
                }
            }
        }
        
        @include max-width($desktop-xl) {
            .two-image{
                .image-section{
                    .med-img{
                        img {
                            width: 300px;
                            left: 55%;
                            height: auto;
                        }
                    }
                    .image-building-image{
                        width: 270px;
                        height: 430px;
                        top: 55%;
                        left: 75%;
                    }
                }
            }
        }

        @include max-width($desktop) {
            .content-section{
                width: 40%;
                padding:8% 3%;
                .heading-section {
                    width: 80%;
                }
            }
            .two-image{
                overflow: hidden;
                .image-section{
                    .small-img{
                        img {
                            width: 240px;
                        }
                    }
                    
                    .med-img{
                        img {
                            width: 250px;
                            left: 55%;
                            height: auto;
                        }
                    }
                }
            }
        }

        @include max-width($desktop-large) {
            background-size:30px 80%;
            .content-section{
                .description{
                    padding-bottom: 10px;
                }
                .tour-schedule{
                    min-width: 250px;
                    letter-spacing: 2px;
                }
            }
            .two-image{
                .image-section{
                    width: 380px;
                    height: 500px;
                    .image-building-image{
                        width: 225px;
                        height: 340px;
                        top: 45%;
                        left: 80%;
                    }
                }
            }
        }

        @include max-width($macbook) {
            .content-section{
                .heading-section {
                    width:100%;
                    text-align: left;
                }
                .description{
                    width:100%;
                }
            }
        }
        @include max-width($tablet-landscape) {
            .content-section{
               // padding:8% 8%;
                // .heading-section {
                //     .main-title {
                //         line-height: 50px;
                //     }
                // }
                .tour-schedule {
                    margin-right: 5%;
                }
            }
            .two-image{
                .image-section{
                    width: 300px;
                    height: 400px;
                    .image-building-image{
                        width: 60%;
                        height: 280px;
                        top:50%;
                    }
                }
            }
        
        }
        @include max-width($iphonex-landscape) {
            padding:0 40px 20px 20px;
            background-size: 15px 35%;
            background-position: right 90% !important;
            .content-section{
                width: 100%;
                padding:5% 10% 5% 5%;
                .tour-schedule{
                    margin-top: 30px;
                }
            }
            .two-image{
                order:-1;
                width: 100%;
                // padding: 0 0 10% 20%;
                padding-top: 10%;
                .image-section{
                    width: 50%;
                    height: 350px;
                    margin: 0 5%;
                    .image-building-image{
                        width: 60%;
                        height: 70%;
                        top: 45%;
                    }
                }
            }
        }

        @include max-width($iphone6-plus-landscape) {
            .content-section{
                padding: 5% 5%;
                .heading-section {
                    width: 100%;
                }
                .description {
                    width: 100%;
                }
            }
            .two-image{
                overflow: hidden;
                .image-section{
                    width: 50%;
                    height: 300px;
                    .small-img{
                        img {
                            left: -20%;
                            width: 40%;
                        }
                    }
                    
                    .med-img{
                        img {
                            width: 60%;
                            left: 60%;
                        }
                    }
                }
            }
        }

        @include max-width($iphone4-landscape) {
            .content-section{
                padding: 5% 0;
                .tour-schedule{
                    width:100%;
                }
            }
            .two-image{
                .image-section{
                    height: 250px;
                    width:65%;
                    margin: 0 2%;
                    .small-img{
                        img {
                            width:60%;
                            height:auto;
                        }
                    }
                }
            }
        }
    }
    // .schedule-tour.left{
    //     .content-section{
    //         padding:8% 7%;
    //     }
    // } 
}
.Review_Policy_main_div {
    .Review_Policy_div {
        background-color: $white;
        padding:5%;
    }
    .modal-body {
        background-color: unset !important;
    }
}
.schedule-tour.BrokerBtn  {
    display:flex;
    background:$white;
    flex-wrap: wrap;
    padding:5%;
    padding-top: 0;
    align-items: center;

    .content-section{
        width: 50%;
        padding:8% 3%;
        .heading-section {
            width:100%;
            text-align: left;
        }
        .description{
            font-size:20px;
            color:$black-new;
            padding-bottom: 35px;
            letter-spacing:0px;
            line-height:20px;
            float: left;
            width: 80%;
        }
        p{
            margin:0;
        }
        .tour-schedule{
            @include orange-learn-more;
            display: inline-block;
            margin-top: 20px;
            min-width: 314px;
            margin-right: 10%;
            transition: all .5s ease;
            &:hover{
                color: $white;
                background-color: $orange;
            }  
            &.no-link{
                pointer-events: none;
            }
        }
    }
    .two-image{
        width: 50%;
        // padding: 0% 10% 5%;
        padding-top: 5%;
        padding-bottom: 5%;
        .image-section{
            background: #325b81;
            position: relative;
            width: 579px;
            height: 748px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .image-building-image{
                position: absolute;
                top: 50%;
                left: 80%;
                width: 337px;
                height: 453px;
            }
        }
    }
    .Broker-Btns {
        display: flex;
        width: 50%;
    }
    .filled-btn{
        @include black-outline-button;
        display: inline-block;
        margin-top: 20px;
        min-width: 314px;
        &.no-link{
            pointer-events: none;
        }
    }
    @include max-width($desktop-xl) {
        .two-image{
            .image-section{
                .image-building-image{
                    width: 270px;
                    height: 430px;
                    top: 55%;
                    left: 75%;
                }
            }
        }
    }
    @include max-width($desktop-large) {
        .content-section{
            .description{
                padding-bottom: 10px;
            }
            .tour-schedule{
                min-width: 250px;
                letter-spacing: 2px;
            }
        }
        .two-image{
            .image-section{
               width: 380px;
               height: 500px;
                .image-building-image{
                   width: 225px;
                   height: 340px;
                   top: 45%;
                   left: 80%;
                }
            }
        }
        .filled-btn{
            min-width: 250px;
            letter-spacing: 2px;
        }    
    }
    @include max-width($macbook) {
        .content-section{
            .heading-section {
                width:100%;
                text-align: left;
            }
            .description{
                width:100%;
            }
        }
    }
    @include max-width($desktop) {
        .content-section{
            width: 50%;
            padding:8% 3%;
            .heading-section {
                width: 80%;
            }
        }
        .two-image{
            overflow: hidden;
            .image-section{
                .small-img{
                    img {
                        width: 240px;
                    }
                }
                
                .med-img{
                    img {
                        width: 250px;
                        left: 55%;
                    }
                }
            }
        }
    }
    
    @include max-width($iphonex-landscape) {
        padding:0 40px 20px 20px;
        background-size: 15px 35%;
        background-position: right 90% !important;
        .content-section{
            width: 100%;
            padding:5% 10% 5% 5%;
            .tour-schedule{
                margin-top: 30px;
            }
        }
        .two-image{
            order:-1;
            width: 100%;
            // padding: 0 0 10% 20%;
            padding-top: 10%;
            .image-section{
                width: 50%;
                height: 350px;
                margin: 0 5%;
                .image-building-image{
                    width: 60%;
                    height: 70%;
                    top: 45%;
                }
            }
        }
        .filled-btn{
            margin-top: 30px;
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .content-section{
            padding: 5% 0;
            .heading-section {
                width: 100%;
            }
            .description {
                width: 100%;
            }
        }
        .two-image{
            overflow: hidden;
            .image-section{
                width: 50%;
                height: 275px;
                .small-img{
                    img {
                        left: -20%;
                        width: 40%;
                    }
                }
                
                .med-img{
                    img {
                        width: 60%;
                        left: 60%;
                        height: auto;
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .filled-btn{
            width:100%;
        }
        .content-section{
            padding: 5% 0;
            .tour-schedule{
                width:100%;
            }
        }
        .two-image{
            .image-section{
                height: 270px;
                width:65%;
                margin: 0 2%;
                .small-img{
                    img {
                        width:60%;
                    }
                }
            }
        }
    }
}