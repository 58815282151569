@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.no-mr {
  margin: 0px;
}

.main-div {
  padding: 8% 5% 8%;
  background: #f7f7f7;
  margin: 4% auto 2%;
  width: 95%;
  float: none;
  clear: both;

  .accordion-newHomeSection {
    width: 100%;
    padding: 0 50px 0 0;
    max-width: 1445px;
    margin: 0 auto;
    position: relative;
    .accordionWrapper {
      position: relative;

      .headerArea {
        width: 50%;
        margin-bottom: 80px;
        padding-right: 5%;

        .heading-section {
          margin: 0;
          text-align: left;
          width: 80%;
          padding-left: 9.7%;

          .sub-title {
            padding-top: 0;
          }

          .main-title {
            padding: 18px 0 15px;
            font-size: 68px;
            line-height: 70px;
            letter-spacing: 0;
          }
        }
        .focus-boxes-subtext {
          width: 90%;
          font-size: 20px;
          line-height: 30px;
          color: $black-new;
          padding-left: 60px;
        }
      }

      .accordionWrapperItem {
        width: 50%;
        min-height: 685px;
        padding-right: 5%;
        .item {
          cursor: pointer;

          &:last-child {
            .itemHead {
              // &:before {
              //     content: ' ';
              //     position: absolute;
              //     width: calc(100% - 45px);
              //     bottom: -38px;
              //     height: 1px;
              //     background: #E5E5E5;
              // }
              &:after {
                content: none;
              }
            }
          }

          .itemHead {
            opacity: 0.4;
            position: relative;
            //transition: opacity 0.5s ease;
            transition: all 0.5s ease;
            padding: 30px 100px 30px 60px;

            &:after {
              content: " ";
              position: absolute;
              width: calc(100% - 100px);
              bottom: 0px;
              height: 1px;
              background: #e5e5e5;
              left: 10%;
            }

            .focus-boxes-heading {
              max-width: 380px;
              font-family: $font-primary;
              font-size: 28px;
              line-height: 30px;
              color: $black-new;
              margin-bottom: 18px;
              letter-spacing: -0.7px;
            }

            p {
              margin-bottom: 0;
            }

            .focus-boxes-description {
              max-width: 380px;
              margin-bottom: 0;
              font-family: $font-primary;
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0px;
              color: $black-new;
              white-space: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .arrowRight {
              position: absolute;
              top: 50%;
              right: 40px;
              transform: translateY(-50%);
              border: 1px solid $new-border-color;
              height: 45px;
              width: 45px;

              &:after,
              &:before {
                content: " ";
                position: absolute;
                top: 50%;
                left: 45%;
              }

              &:after {
                background: $black-new;
                transform: translate(-50%, -50%);
                height: 2px;
                width: 16px;
              }

              &:before {
                width: 8px;
                height: 8px;
                border-top: 2px solid $black-new;
                border-right: 2px solid $black-new;
                transform: translate(-1px, -50%) rotate(45deg);
              }
            }

            &.active {
              opacity: 1;
              background: $white;

              &:after {
                content: none;
              }

              .focus-boxes-description {
                display: block;
              }

              .arrowRight {
                border-color: $orange;

                &:after {
                  background: $orange;
                }

                &:before {
                  border-top: 2px solid $orange;
                  border-right: 2px solid $orange;
                }
              }
            }

            .mobileAccordion,
            .mobileDesc,
            .mobileImgArea {
              display: none;
            }
          }

          .imgarea {
            position: absolute;
            right: 0;
            width: 37%;
            height: 73.8%;
            //padding-bottom: calc(50% * 1.476);
            // overflow: hidden;
            top: 0;
            display: none;
            .focusimage {
              position: relative;
              height: 100%;
              &::after {
                position: absolute;
                content: " ";
                width: 50px;
                right: -50px;
                background: #a3c144;
                height: 92.44%;
                top: 3%;
              }
            }
            > img {
              width: 504px;
              max-width: 100%;
              height: auto;
              top: 0;
              right: 0;
              object-fit: cover;
              position: absolute;
              opacity: 0;
              transition: opacity 0.5s ease;
            }

            &.active {
              display: block;
              > img {
                opacity: 1;
              }
            }
          }

          &:hover {
            .itemHead {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @include max-width($desktop-xl) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          margin-bottom: 60px;
          .heading-section {
            padding-left: 10.7%;
          }
        }

        .accordionWrapperItem {
          min-height: 555px;

          .item {
            // margin-bottom: 50px;

            .itemHead {
              // &:after {
              //     top: -25px;
              // }

              .focus-boxes-heading {
                font-size: 24px;
                line-height: 1.5;
              }

              // .focus-boxes-description {
              //     line-height: 1.5;
              // }
            }

            &:last-child {
              .itemHead {
                &:before {
                  bottom: -25px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include max-width($desktop-large) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          .heading-section {
            padding-left: 12%;

            .main-title {
              font-size: 48px;
              line-height: 60px;
            }
          }
        }
        .accordionWrapperItem {
          min-height: 500px;
          .item {
            .imgarea {
              &::after {
                width: 30px;
                right: -30px;
                height: 73%;
              }
            }
          }
        }
      }
    }
  }

  @include max-width($macbook) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          padding-right: 0;
          .heading-section {
            padding-left: 12.5%;
            .main-title {
              font-size: 58px;
              line-height: 62px;
            }
          }
        }
        .accordionWrapperItem {
          padding-right: 0;
          .item {
            .itemHead {
              .arrowRight {
                top: 22%;
                right: 25px;
              }

              &.active {
                .arrowRight {
                  top: 9.2%;
                }
              }
            }
            .imgarea {
              width: 45%;
              height: 65%;
            }
          }
        }
      }
    }
  }

  @include max-width($desktop) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          .heading-section {
            padding-left: 14.2%;
          }
        }
        .accordionWrapperItem {
          .item {
            .imgarea {
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @include max-width($tablet-landscape) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          .heading-section {
            padding-left: 15.5%;
          }
        }
        .accordionWrapperItem {
          min-height: 444px;
          .item {
            .imgarea {
              &::after {
                height: 90%;
                top: 5%;
              }
            }
          }
        }
      }
    }
  }
  @include max-width($iphonex-landscape) {
    .accordion-newHomeSection {
      .accordionWrapper {
        .mobileImageArea {
          height: 300px;
          width: 100%;
          margin-bottom: 40px;
        }

        .headerArea {
          width: 100%;
          .heading-section {
            padding-left: 8.6%;
            width: 100%;
            .focus-boxes-subtext {
              width: 100%;
            }
          }
        }

        .accordionWrapperItem {
          width: 100%;
          min-height: 1px;

          .item {
            .itemHead {
              padding-right: 60px;
              opacity: 1;

              &:after {
                width: calc(100% - 36px);
                left: 4%;
              }

              &:before {
                width: 100%;
                display: none;
              }

              .focus-boxes-heading {
                margin-bottom: 0;
                padding-right: 50px;
                word-break: break-word;
              }

              .mobileDesc {
                display: block;
              }

              .focus-boxes-description {
                display: none;
              }

              .mobileAccordion {
                padding: 20px 0 0 0;
                overflow: hidden;
                display: none;

                .mobileDesc,
                .mobileImgArea {
                  display: block;
                }

                .mobileDesc {
                  max-width: 100%;
                  margin-bottom: 0;
                  font-family: $font-primary;
                  line-height: 32px;
                  letter-spacing: -0.5px;
                  color: $black-new;
                  display: block;
                  font-size: 20px;
                }

                .mobileImgArea {
                  display: none;

                  > img {
                    height: 300px;
                    max-width: 100%;
                    display: block;
                    margin-bottom: 20px;
                  }
                }
              }

              .arrowRight {
                top: 38px;
                right: 20px;
                width: 30px;
                height: 30px;
                transition: all 0.5s ease;
              }

              &.active {
                .arrowRight {
                  top: 14%;
                  transform: rotate(90deg);
                }

                .focus-boxes-description {
                  display: none;
                }
              }
            }

            .imgarea {
              top: 0;
              left: 0;
              width: 100%;
              height: 300px;
              padding: 0;
              pointer-events: none;

              &.active {
                pointer-events: all;
              }
              .focusimage {
                &::after {
                  width: 30px;
                  right: -30px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include max-width($tablet) {
    margin: 10% auto 2%;
    padding: 10% 5% 5%;
    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          margin-bottom: 30px;
          width: 100%;
          .heading-section {
            padding-left: 10.6%;
          }
        }
      }
    }
  }

  @include max-width($iphone6-plus-landscape) {
    padding: 15% 3.7% 15% 0;
    .accordion-newHomeSection {
      padding: 0;
      .accordionWrapper {
        .mobileImageArea {
          height: 300px;
          width: 100%;
          margin-bottom: 40px;
        }

        .headerArea {
          margin-bottom: 30px;
          width: 100%;
          .heading-section {
            padding-left: 2.6%;
            width: 100%;
            padding-bottom: 15px;
            .main-title {
              padding: 10px 0 0px;
              font-size: 32px;
              line-height: 34px;
            }
          }
          .focus-boxes-subtext {
            width: 100%;
            padding-left: 15px;
          }
        }

        .accordionWrapperItem {
          .item {
            margin-bottom: 0;
            .itemHead {
              padding: 15px;

              .arrowRight {
                top: 32px;
                right: 20px;
              }

              &.active {
                .arrowRight {
                  top: 16px;
                }
              }
            }
            .imgarea {
              &::after {
                width: 25px;
                right: -25px;
              }
            }
          }
        }
      }
    }
  }

  @include max-width(480px) {
    padding: 12% 8% 12% 0%;

    .accordion-newHomeSection {
      .accordionWrapper {
        .headerArea {
          .heading-section {
            padding-left: 5.6%;
          }
          .focus-boxes-subtext {
            padding-left: 15px;
          }
        }

        .accordionWrapperItem {
          .item {
            .itemHead {
              .arrowRight {
                top: 22px;
              }
              &.active {
                .arrowRight {
                  top: 22px;
                }
              }
            }
            .imgarea {
              &::after {
                position: absolute;
                content: " ";
                width: 30px;
                right: -30px;
                background: #a3c144;
                height: 90%;
                top: 5%;
              }
            }
          }
        }
      }
    }
  }
}
