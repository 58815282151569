@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.event-detail-content-column--form{
    padding: 9% 0;
    margin: 0 auto;
    width: 42.3%;
    .rsvp_heading{
        color: $black-new;
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 32px;
        font-family: $font-primary;
        padding-bottom: 20px;
    }

    .mktoField{
        color: #545454;
        letter-spacing: -0.57px;
        font-size:23px  !important;
        line-height:28px !important;
        margin-bottom: 10px  !important;
        padding: 24px 30px!important;
        border: 1px solid #D1D1D1;  
        font-family: $atten-font;
        font-weight: $font-atten-book;

        outline: none;
    }
    select#attending {
        word-spacing: 3px;
        -webkit-appearance: none;
        background: no-repeat calc(100% - 20px);
        background-image: url("../../assets/images/angle-arrow-down.png");
    }
    .mktoButtonRow{
        .mktoButtonWrap{
            &.mktoSimple{
                .mktoButton{
                    background-color: $orange !important;
                    border-radius: 0px;
                    font-size: 16px!important;
                    line-height: 35px;
                    padding: 21px!important;
                    min-width: 324px;
                    letter-spacing: 3.2px;
                    font-family: $arquitecta-heavy !important;
                    width: auto!important;
                    margin: 20px 0 45px;
                    border: 1px solid #e5721f !important;
                    transition: all .5s ease;

                    &:hover{
                        background-image: none;                        
                        background-color: $white !important;
                        color: $orange;
                        padding: 21px !important;
                        border: 1px solid #e5721f !important;
                    }
                }
            } 
        }
    }

    .event_rsvp__thanks{
        font-size: 15px;
        text-align: center
    }

    .sidebar-signup__disclaimer {
        color: $black-new;
        font-size: 12px;
        line-height: 16px;
        font-family: $font-primary;
        a{
            color: $black-new;
            font-size: 12px;
            line-height: 16px;
            font-family: $font-primary;
            text-decoration: underline;
        }
    }

    .sidebar_broker_agent {
        padding: 25px 0 0;
        color: $black-new;
        font-size: 14px;
        line-height: 16px;
        font-family: $font-primary;
        a{
            color: $black-new;
            font-size: 14px;
            line-height: 16px;
            font-family: $font-primary;
            text-decoration: underline;
        }
    }
    
    @include max-width($desktop-large) {
        .mktoField{
            font-size:18px  !important;
            padding:14px 20px!important;
        }
        .mktoButtonRow{
            .mktoButtonWrap{
                &.mktoSimple{
                    .mktoButton{
                        line-height: 25px;
                    }
                } 
            }
        }
    }

    @include max-width($desktop) {
        padding: 85px 0 ;
        width: 60%;
        .event_rsvp__thanks{
            font-size: 18px;
        }
    }

    @include max-width($tablet-landscape) {
        padding: 75px 0;
        margin: 0 auto;
        .event_rsvp__thanks{
            font-size: 17px;
        }
    }

    @include max-width($iphonex-landscape) {
        padding:65px ​0 ;
        width: 89%;
        .event_rsvp__thanks{
            font-size: 16px;
        }

        .rsvp_heading{
            margin-bottom: 18px;
            font-size:26px;
            line-height: 32px;
            padding-bottom: 20px;
        }

        .mktoField{
            letter-spacing: -0.45px;
            font-size:18px  !important;
            line-height:21px !important;
            margin-bottom: 7px  !important;
            padding: 16px 10px!important;
        }
        select#attending {
            -webkit-appearance: none;
            background: no-repeat calc(100% - 10px);
            background-image: url("../../assets/images/angle-arrow-down.png");
        }
        .mktoButtonRow{
            .mktoButtonWrap{
                &.mktoSimple{
                    .mktoButton{
                        // padding: 12px 0px!important;
                        width: 100% !important;
                        margin: 20px 0 35px;
                        line-height: 18px;
                        // &:hover{
                        //     background-color: $orange !important;
                        //     // padding: 20px 0 35px !important;
                        // }
                    }
                } 
            }
        }
    }

    @include max-width($iphone4-landscape) {
        .mktoForm {
            padding: 0 !important;
        }
    }

}

