@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
@import 'font-awesome/css/font-awesome.min.css';

.footer_section{
    // float: left;
    width: 100%;
    .footer-top{
        background-color:#545454;
        .footer-social{
            padding: 57px 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          
            .socialContent{
                ul{
                    margin:0;
                    padding: 0;
                    li{
                        padding: 0 10px;
                        display: inline-block;
                        a{
                            color: $white;
                            font-size:16px;
                            font-family: $font-primary;
                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .designed-by-logo{
                height: 100%;
                img{
                    max-width: 330px;
                    // height: 50px;
                }
            }
        
            img{
                object-fit: contain;
            }
        }
    }
    .footer-middle{
        background-color:$white;
        .footer-logo{
            display: flex;
            padding: 39px 5% 0;
            justify-content: space-between;
            align-items: center;
            .social_links{
                i{
                    font-size: 40px;
                    margin-right: 15px;
                    color: $black-new;
                }
            }
            .life-group-logo{
                height: 100%;
                img{
                    max-width:250px;
                }
            }
        }
    }
    .footer-lower{
        background-color:$white;
        .footer-copyright{
            padding: 65px 5%;
            .copyrightLinks-div{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                .copyrightCaption{
                    p{
                        font-size: 16px;
                        color: $black-new;
                        font-family: $atten-font;
                        font-weight: $font-atten-book;

                    }
                }
                .copyrightLinks{
                    display: flex;
                    ul{
                        padding: 0;
                        li{
                            font-size: 12px;
                            padding: 0 15px 0 0;
                            display: inline-block;
                            a{
                                color: $black-new;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    span{
                       img{
                            width: 48px;
                            height: 18px;
                            margin-bottom:20px;
                       }
                    }
                }
            }
            .copyright-text{
                    font-size: 12px;
                    color: $black-new;
                    margin: 0;
                    line-height: 16px;
                    margin-top: 10px;
                a{
                    color: #e5721f;
                }
            } 
        }
    }
    @include max-width($desktop-xl) {
        .footer-top{
            .footer-social{
                .designed-by-logo{
                    img{
                       max-width: 260px;
                       margin: auto;
                    }
                }
            }
        }
        .footer-middle{
            .footer-logo {
                    .life-group-logo{
                    img{
                        max-width: 220px;
                        margin: auto;
                    }
                }
            }
        }
        .footer-lower{
            .footer-copyright{
                .copyrightLinks-div{
                    .copyrightCaption{
                        p{
                            font-size: 16px;
                        }
                    }
                    .copyrightLinks{
                        ul{
                            li{
                                font-size: 12px;
                            }
                        }
                    }
                }
                .copyright-text{
                        font-size: 12px;
                }
            }
        }
    }
    @include max-width($desktop-large) {
        .footer-top{
            .footer-social{
                padding: 57px 5%;
            }
        }
        .footer-middle{
            .footer-logo{
                padding: 39px 5% 0;
                .social_links{
                    i{
                        font-size: 35px;
                    }
                }
            }
        }
        .footer-lower{
            .footer-copyright{
                padding: 25px 5%;
                .copyrightLinks-div{
                    .copyrightCaption{
                        p{
                            font-size: 14px;
                        }
                    }
                    .copyrightLinks{
                        ul{
                            li{
                                font-size: 11px;
                            }
                        }
                    }
                }
                // .copyright-text{
                //         font-size: 16px;
                // }
            }
        }
    }
    @include max-width($tablet-landscape) {
        .footer-top{
            .footer-social{
                display: block;
                text-align: center;
                .designed-by-logo{
                    width: 100%;
                    text-align: center;
                    margin-bottom:40px;
                }
            }
        }
        .footer-middle{
            .footer-logo{
                flex-wrap: wrap;
                padding: 30px 0 0;
                justify-content: center;
                align-items: center;

                .life-group-logo{
                    width: 100%;
                    text-align: center;
                    margin: 20px 0;
                }
            }
        }
        .footer-lower{
            .footer-copyright{
                .copyrightLinks-div{
                    width: 100%;
                    flex-wrap: wrap;
                    .copyrightCaption{
                        order: 2;
                        width: 100%;
                        text-align: center;
                    }
                    .copyrightLinks{
                        display: block;
                        text-align: center;
                        width: 100%;
                        ul{
                            justify-content: center;
                            margin: 0;
                        }
                        span {
                            margin: 5px 0;
                            display: inline-block;
                        }
                    }
                }
                .copyright-text{
                        font-size: 11px;
                }
            }
        }
    }
    @include max-width($iphone6-plus-landscape) {
        .footer-top{
            .footer-social{
                .socialContent{
                    ul{
                        li{
                            display: block;
                        }
                    }
                }
            }
        }
        .footer-lower{
            .footer-copyright{
                padding: 65px 5% 120px;
                .copyrightLinks-div{
                    .copyrightCaption{
                        text-align: left;
                        p{
                            font-size: 14px;
                        }
                    }
                    .copyrightLinks{
                        display: flex;
                        margin-bottom: 20px;
                        ul{
                            width: 70%;
                            flex-wrap: wrap;
                            text-align: left;
                            margin: 0;
                            li{
                                width: 100%;
                                font-size: 12px;
                            }
                        }
                        span{
                            width: 30%;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            text-align: right;
                            img{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .footer-top{
            .footer-social{
                padding: 57px 10%;
            }
        }
        .footer-middle{
            .footer-logo{
                padding: 30px 10% 0;
            }
        }
        .footer-lower{
            .footer-copyright{
                padding:15px 10% 50px;
            }
        }
    }
}
.top-scroll {
    position: fixed;
    right: 3%;
    bottom: 7%;
    z-index: 9;
    background: $white;
    padding: 8px 12px;
    color: #505051;
    border:2px solid #505051;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        margin: 0;
        font-weight: 600;
        color: #505051;
        font-size: 11px;
        letter-spacing: 1px;
    }
    i{
        font-size: 17px;
        color: #505051;
    }
    &.hidden{
        opacity:0;
    }
    &.show{
        opacity:1;
    }
    &.dynamic{
        right: 3%;
        bottom: 15%;
        @include max-width($desktop-xl){
            bottom: 17%;
        }
        @include max-width($desktop-large) {
            bottom: 19%;
        }
        @include max-width($macbook) {
            bottom: 17%;
        }
        @include max-width($iphonex-landscape) {
            bottom: 26%;
        }
        @include max-width($tablet) {
            bottom: 14%;
        }
        @include max-width($iphone6-plus-landscape) {
            bottom: 28%;
            z-index: 999;
        }
        @include max-width($iphone4-landscape) {
            bottom: 17%;
        }
    }
}