@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.Banner-Main{
    width: 100%;
    position: relative; 
    .banner-img-section{
        background-color: rgba(0,0,0,0.3);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 1160px;
        width: 100%;
        position: relative;
        .container {
            height: 1160px;
        }
        .brand-logo {
            position: absolute;
            top: 5%;
            left: 5%;
            height:8%;
            img {
                width:auto;
            }
        }
    }
    .banner-slider{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: $bgdarkgray;
        .item-bg-overlay{
            background: transparent linear-gradient(181deg,transparent,rgba(0,0,0,.85)) 0 0 no-repeat padding-box;
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .item-caption{
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            color: $white;
            font-family: $font-primary;
            height: auto !important;
            width:50%;

            h2{
                font-size: 68px;
                line-height: 70px;
                width: 100%;
            }

            p{
                font-size: 25px;
                line-height: 40px;
                width: 100%;
            }
            .item-btn{
                color:$white;
                border: 1px solid $white;
                background-color:transparent; 
                font-size:16px !important;
                line-height: 25px;
                letter-spacing: 3.9px;
                font-family: $arquitecta-heavy;
                text-transform: uppercase;
                float: left;
                width: auto;
                padding: 26px 93px;
                text-decoration: none;
                transition: all .5s ease;
                &:hover{
                    background-color:$orange; 
                }
            }
        }
        .hero_iframe{
            width: 100%;
            height: 100%;
            border: none;
            html {
                body{
                    .ytp-pause-overlay,.ytp-chrome-top.ytp-show-cards-title,#click-to-unmute button{
                        display:none !important;
                        opacity: 0 !important;
                    }
                }
            }
        }

  
        .owl-carousel,.owl-stage-outer,.owl-stage,.owl-item,.owl-item div{
            height: 100%;
        }
        .owl-nav{
            &.disabled{
                display: block !important;
            }
            position: absolute ;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            margin: 0;
            button{
                background: #FFFFFFB2;
                width: 43px;
                height: 43px;
                border-radius: 50%;
                position: absolute;
                margin: 0;
                outline: none;
                &:hover{
                    background: #FFFFFFB2;
                }
                &:after{
                    position: absolute;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid #111111;
                    border-bottom: 2px solid #111111;
                    transform: rotate(-45deg);
                    top: 40%;
                    right: 20px;
                   }
                span{
                   display: none;
                }
            }
            .owl-prev{
                left:10px;
                &:after{
                    transform: rotate(135deg);
                    left: 18px;
                }
            }
            .owl-next{
                right: 10px;
            }
        }
    }
    .banner-top-section{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding:2% 7% 3%;
        .banner-first-section{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 60%;
            .banner-left-section {
                padding-bottom: 10%;
                bottom: 0;
               .comm-name {
                    font-size:75px;
                    letter-spacing: -4.75px;
                    max-width: 90%;
                    font-family: $font-primary;
                }
                .comm-price {
                    font-size: 24px;
                    line-height: 35px;
                    max-width: 90%;
                    margin: 0;
                    color: $black-new;
                    &.marketing-headline{
                        font-size: 20px;
                    }
                }
                .card-status {
                    background: $bggray-new;
                    color:$orange;
                    padding: 5px 15px;
                    display: inline-block;
                    font-size: 16px;
                    letter-spacing: 3.2px;
                    font-family: $arquitecta-bold;
                    line-height: 35px;
                    margin: 15px 0;
                    text-transform: uppercase;
                }
            }
            .comm-info{
                display: flex;
                // justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 0;
                li{
                    list-style: none;
                    text-align: center;
                    padding: 5px 0px;
                    padding-right: 4%;
                    margin-right: 4%;
                    border-right: 1px solid $lightgray;
                    &:last-child{
                        border-right: none !important;
                        padding-right: 0;
                        margin-right: 0;
                    }
                    span.info-value {
                        display: block;
                        font-size: 22px;
                        color: $black-new;
                        font-family: $arquitecta-bold;
                    }
                    span.info-name {
                        display: block;
                        font-size: 14px;
                        letter-spacing: 2.8px;
                        text-transform: uppercase;
                        margin-top: 8px;
                        color: $gray-new;
                        font-family: $arquitecta-bold;
                    }
                }
            }
            @include max-width($desktop){
                width: 48%;
            }
        }
        
        .banner-second-section{
            width: 40%;
            text-align: right;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .seconddiv{
                &:first-child{
                    a{
                        background:#ffff!important;
                        border: 1px solid rgb(229 114 31)!important;
                    }
                }
            }
            .banner-bottom-section {
                padding-bottom:20%;
                ul{
                    padding: 0;
                    margin: 0;
                    .divide-equal{
                        margin: 0;
                    }
                    li{
                        list-style: none;
                        font-size: 18px;
                        line-height: 39px;
                        color: $black-new;
                        &.city{
                            font-size: 18px;
                            font-family: $font-primary;
                            color: $gray-new;
                        }
                    }
                    .driving-directions{
                        color: $gray-new;
                        text-decoration: underline;
                        font-size: 18px;
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
            }
            .twoform{
                display: flex;
                justify-content: flex-end;
                .schedule_tour_div{
                    &:first-child{
                        a{
                            background-color: rgb(255 255 255)!important;
                            border: 1px solid #E5721F!important;
                            color: rgb(84 84 84);
                        }
                    }
                }
                .qmi_button{
                    padding: 15px 14px;
                    @include green-filled-button;
                    min-width: 252px;
                    font-family: "Atten Heavy",Arial,sans-serif!important;
                    font-size: 14px!important;
                    letter-spacing: 2.2px;
                    line-height: 19px;
                    background-color: #E5721F!important;
                    border: 1px solid #E5721F!important;
                    color: #ffffff;
                    margin-left: 8px;
                }
            }
            .twoforms{
                display: flex;
                justify-content: flex-end;
                
                .priorityform1{
                    padding: 15px 14px!important;
                }
                .qmi_button{
                    padding: 15px 14px;
                    margin-bottom: 10px;
                }
                .tour_filled_btn_first {
                    background-color: rgb(255 255 255)!important;
                    border: 1px solid rgb(229 114 31)!important;
                    color: rgb(84 84 84);
                    box-shadow: none;
                    font-size: 13px!important;
                    
                    font-family: "Atten Heavy",Arial,sans-serif!important;
                    text-transform: uppercase;
                    min-width: 260px;
                    border-radius: 0;
                    font-size: 20px;
                    line-height: 31px;
                    text-decoration: none;
                    text-align: center;
                    display: inline-block;
                    padding: 19px 50px;
                    font-size: 16px!important;
                    
                    line-height: 19px;
                }
                .tour_filled_btn{
                    @include green-filled-button;
                    font-family: "Atten Heavy",Arial,sans-serif!important;
                    font-size: 16px!important;
                    letter-spacing: 3.2px;
                    line-height: 19px;
                    background-color: #E5721F!important;
                    border: 2px solid #E5721F!important;
                    color: #ffffff;
                }
                
            }
            .tour_filled_btn {
                @include green-filled-button;
                    font-family: "Atten Heavy",Arial,sans-serif!important;
                    font-size: 16px!important;
                    letter-spacing: 3.2px;
                    line-height: 19px;
                    background-color: #E5721F!important;
                    border: 2px solid #E5721F!important;
                    color: #ffffff;
                padding: 20px 70.4px;
                margin-left: 10px;
            }
            @include max-width($desktop){
                width: 50%;
            }

        }
    }
   
       
    @include max-width($desktop-xl) {
        .banner-img-section{
            height: 942px;
            .container {
                height: 942px;
            }
        }
        .banner-top-section{
            padding:3% 8% 3%;
             .banner-first-section{
                .banner-left-section {
                   .comm-name {
                        font-size: 65px;
                        letter-spacing: -3px;
                    }
                    .comm-price {
                        font-size: 18px;
                        line-height: 25px;
                        &.marketing-headline{
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
                .comm-info{
                    li{
                        span.info-value {
                            font-size: 18px;
                        }
                        span.info-name {
                            font-size: 10px;
                            letter-spacing: 1.2px;
                        }
                    }
                }
             }
             .banner-second-section{
                .banner-bottom-section {
                    ul{
                        li{
                            font-size: 14px;
                            line-height: 25px;
                            &.city{
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }
                        .driving-directions{
                            font-size: 16px;
                        }
                    }
                }
                .tour_filled_btn{
                    font-size: 14px!important;
                    line-height: 19px;
                }
             }
        }
    }
    @include max-width($desktop-large) {
        .banner-img-section{
            height: 870px;
            .container {
                height: 870px;
            }
        }

        .banner-slider{
            .item-caption{   
                h2{
                    font-size: 48px;
                    line-height: 50px;
                }
    
                p{
                    font-size: 20px;
                    line-height: 30px;
                }
                .item-btn{
                    padding: 16px 70px;
                }
            }
            
    
            
        }
        .banner-top-section{
            padding:3% 8% 3%;
            .banner-first-section{
                .banner-left-section {
                   .comm-name {
                        font-size: 50px;
                        letter-spacing: -3px;
                    }
                }
            }
            .banner-second-section{
                .twoforms{
                    .tour_filled_btn{
                        line-height: 25px;
                    }
                }
                .twoform{
                    .priorityform1{
                        padding: 15px 14px!important;
                    }
                    .qmi_button{
                        min-width: 194px;
                        font-size: 15px!important;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        

    }
    @include max-width(1366px) {
        .banner-img-section{
            height: 825px;
            .container {
                height: 825px;
            }
        }
        
    }
    @include max-width($macbook) {
        .banner-img-section{
            height: 773px;
            .container {
                height: 773px;
            }
        }
    }
    @include max-width($desktop) {
        .banner-top-section{
            .banner-second-section{
                .twoform{
                    .priorityform1{
                        padding: 15px 14px!important;
                    }
                    .qmi_button{
                        min-width: 176px;
                        font-size: 10px!important;
                        letter-spacing: 1px;
                    }
                }
                .twoforms{
                    .tour_filled_btn_first{
                        min-width: 224px;
                        font-size: 14px!important;
                    }
        
                    .tour_filled_btn{
                       min-width: 243px;
                    }
                }
            }
        }
        
    }
    @include max-width($tablet-landscape) {
        .banner-img-section{
            height: 619px;
            .container {
                height: 619px;
            }
        }
        .banner-top-section{
            .banner-first-section{
                .comm-info{
                    justify-content: space-between;
                    li{
                        width: 100%;
                        padding: 5px 10px; 
                        margin-right: 0;
                        span.info-value{
                            font-size: 16px;
                        }
                    }
                }
            }
            .banner-second-section{
                margin-top: 35px;
                .twoforms{
                    .tour_filled_btn_first{
                        min-width: 176px;
                        font-size: 14px!important;
                        line-height: 26px;
                        padding: 11px 20px;
                    }
                    .tour_filled_btn{
                        min-width: 176px;
                        padding: 11px 20px;
                        font-size: 12px!important;
                    }
                }
            }
        }
        .banner-slider{
            .item-caption{
                h2{
                    font-size: 35px;
                }
            }
            .owl-nav{
                position: absolute ;
                top: 45% ;
                transform: translateY(-50%);
            }
        }
    }
    @include max-width($iphonex-landscape) {
        .banner-img-section{
            height: 599px;
            .container {
                height: 599px;
            }
        }
        .banner-slider{
            .owl-nav{
                button{
                    width: 38px;
                    height: 38px;
                    &:after{
                        right: 16px;
                       }
                }
                .owl-prev{
                    &:after{
                        left: 15px;
                    }
                }
            }
            .item-caption{
                left: 12%;
                width: 75%;
            }
        }
        .banner-top-section{
            .banner-first-section{
                width: 100%;
                .banner-left-section{
                    position: relative;
                    display:block;
                    width: 100%;
                    padding-bottom: 0%;
                    .comm-name {
                         font-size: 45px;
                         max-width: 100%;
                     }
                     .comm-price {
                         font-size: 18px;
                         max-width: 100%;
                     }
                   
                }
                .comm-info{
                    width: 100%;
                    flex-direction: column;
                    li{
                        width: 100%;
                        display: flex;
                        border-right: unset;
                        border-bottom: 1px solid #dfdfdf;
                        justify-content: space-between;
                        padding: 10px 0;
                        margin-right: 0;
                        span.info-value {
                            font-size: 20px;
                            order: 1;
                        }
                        span.info-name {
                            font-size: 12px;
                        }
                    }
                }
            }
            .banner-second-section{
                width: 100%;
                margin: 0;
                .banner-bottom-section{
                    padding: 5% 0 !important;
                    ul{
                        text-align: left;
                        width: 100%;
                        .comm-status{
                            display: none;
                        }
                    }
                }
                .schedule_tour_div {
                    text-align: left;
                }
                .twoforms{
                    justify-content: flex-start;
                }
                .twoform{
                    justify-content: flex-start;
                }
            }
        }
    }
    @include max-width($tablet) {
        .banner-img-section{
            height: 464px;
            .container {
                height: 464px;
            }
        }
        .banner-top-section{
            .banner-second-section{
                width: 100%;
                margin: 0;
                .banner-bottom-section{
                    padding: 5% 0 !important;
                    ul{
                        text-align: left;
                        width: 100%;
                        .comm-status{
                            display: none;
                        }
                    }
                }
                .schedule_tour_div {
                    text-align: left;
                }
                .twoforms{
                    justify-content: flex-start;
                }
            }
        }
    }
    @include max-width($iphone4-landscape) {
        .banner-img-section{
            height: 328px;
            .container {
                height: 328px;
            }
            .brand-logo {
                height:10%;
            }
        }
        .banner-slider{
            .item-caption{
                left: 15%;
                h2{
                    font-size: 25px;
                }
                p{
                    font-size: 14px;
                }
            }
            .owl-nav{
                .owl-prev{
                    left:5px;
                }
                .owl-next{
                    right: 5px;
                }
            }
        }
        .banner-top-section{
            padding: 8% 5%;
            .banner-first-section{
                .banner-left-section {
                   .comm-name {
                        font-size: 34px;
                    }
                    .comm-price {
                        font-size: 16px;
                    }
                }
            }
            .banner-second-section{
                .banner-bottom-section{
                    padding: 8% 0px!important;
                    ul{
                        li{
                            font-size: 14px;
                            &.city{
                            font-size: 14px;
                            }
                        }
                        .driving-directions{
                            font-size: 14px;
                        }
                    }
                }
                .twoforms{
                    flex-direction: column;
                    .tour_filled_btn_first{
                        width: 100%;
                    }
                    .tour_filled_btn{
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 0;
                    }
                }
                .twoform{
                    flex-direction: column;
                    .priorityform1{
                        padding: 15px 14px!important;
                    }
                    .qmi_button{
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}