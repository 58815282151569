@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.sub-navbar{
    background: $white;
    // padding-left: 5%;
    padding: 14px 7% 0;
    // padding-top:14px;

    .navbar {
        border-bottom: 1px solid #E0E0E0;
        padding: 0;
        .left-nav.navbar-nav {
            flex-wrap: wrap;
        }  
        .nav-link{
            font-size: 20px;
            color:$gray-new;
            font-family: $arquitecta-bold;
            padding: 15px 0 12px 0;
            margin-right: 25px;
            text-transform: uppercase;
            line-height: 35px;
            cursor: pointer;
            &:hover,&.active{
                color:$black-new;
                border-bottom: 3px solid $orange;
            }
        }
        @include max-width($desktop-large) {
            .nav-link{
                font-size: 16px;
            }
        }
        @include max-width($tablet-landscape) {
            background: $white;
            width: 100%;
            border-bottom: unset;
            .left-nav.navbar-nav {
                width: 100%;
            } 
            .nav-link{
                font-size: 20px;
                padding: 7px 0;
                margin-right: 0px;
                border-bottom: 2px solid $lightgray;
                &:hover,&.active{
                    border-bottom: 2px solid $orange;
                }
             }
      
        }
        @include max-width($iphone4-landscape) {
            .nav-link{
                margin-right: 0px;
            }
        }
    }

    @include max-width($desktop-xl) {
        padding: 14px 8% 0;
    }

    @include max-width($desktop-large) {
        padding: 0 9%;
    }
    
    @include max-width($tablet-landscape) {
       // padding-top: 75px;
    }
    @include max-width($iphone4-landscape) {
        padding: 0 5%;
    }
}

.utility-nav{
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    display: flex;
    z-index: 999;
    padding: 15px 0;
    padding-bottom: 20px;
    background: $white;
    .utility-nav-info {
        width: 75%;
        display: flex;
        a{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:hover{
                text-decoration: none;
            }
            .utility-call{
                height: 22px;
                width: 25px;
            }
            .utility-map {
                width: 22px;
                height: 22px;
            }
            .utility-subscribe {
                width: 21px;
                height: 21px;
            }
            img.utility-tour {
                width: 19px;
                height: 22px;
            }
            span{
                font-size: 10px;
                color:$orange;
                margin-top: 5px;
                text-transform: uppercase;
            }
            &:first-child:nth-last-child(4), &:first-child:nth-last-child(4) ~ a {
                width: 25% !important;
            }
            &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ a {
                width: 33.3333% !important;
            }
            &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ a {
                width: 50% !important;
            }
            &:first-child:nth-last-child(1) {
                width: 100% !important;
            }
        }
    }
    .utility-specialist-img {
        position: relative;
        width: 25%;
        img{
            width: 72px;
            height: 72px;
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            cursor: pointer;
        }
        .pulse{
            background: $orange;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            position: absolute;
            top: -26px;
            left: 58%;
            transform: scale(.8);
            cursor: pointer;
            border: 2px solid $white;
            animation: pulse 2s infinite;
        }
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
        
            70% {
                transform: scale(0.80);
            }
        
            100% {
                transform: scale(1);
            }
        } 
        @include max-width($iphone6-landscape) {
            .pulse{
                left: 60%;
            }
        }
    }
    .divide-utility:first-child:nth-last-child(1) {
        width: 100% !important;
    }
}
.nhs-desktop{
    position: fixed;
    right: 7%;
    bottom: 12%;
    z-index: 12;
    .nhs-agent{
        position: fixed;
        right: 2%;
        bottom: 5%;
        z-index: 9;
        cursor: pointer;
        img{
            width: 73px;
            height: 73px;
            top: -25px;
            right: 5%;
            border: 3px solid $white;
            border-radius: 50%;
        }
        .pulse{
            background: $orange;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            position: absolute;
            top: -6px;
            left: 10px;
            z-index: 10;
            transform: scale(.8);
            cursor: pointer;
            border: 2px solid $white;
            animation: pulse 2s infinite;
        }
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
        
            70% {
                transform: scale(0.80);
            }
        
            100% {
                transform: scale(1);
            }
        } 
   
    }
    @include max-width($desktop-xl){
        bottom: 14%;
        right: 9%;
    }
    @include max-width($desktop-large) {
        right: 9%;
        bottom: 13.5%;
        .nhs-agent{
            img{
                width: 63px;
                height: 63px;
            }
        }
    }
    @include max-width($tablet-landscape) {
        bottom: 12%;
        right: 11%;
    }
    @include max-width($iphonex-landscape) {
        right: 13%;
        bottom: 19%;
    }
    @include max-width($tablet) {
        right: 14%;
        bottom: 10%;
    }
  
}


