@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";
.flexible-sec {
    max-width: 1640px !important;
  }
.flexiable-section {
  background-color: $white;
  padding: 6% 5%;
  max-width: 100%;
  
  .flexiable-content {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    &:nth-child(even) {
      .flexibale-img {
        order: 1;
      }
    }
    .flexibale-img {
      min-height: 756px;
      width: 50%;
      background: lightgray;
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    .flexiable-info {
      width: 50%;
      padding: 6% 12% 6% 6%;
      border: 1px solid #d2d0d0;
      .heading-section {
        text-align: left;
        width: 100%;
        margin: unset;
      }
      .title-info {
        font-size: 20px;
        line-height: 24px;
        color: $black-new;
        margin-bottom: 25px;
      }
      .learn-more {
        @include orange-learn-more;
        background-color: $white;
        transition: all 0.5s ease;
        &:hover {
          text-decoration: none;
          background-color: $orange;
          color: $white;
        }
      }
    }

    .owl-stage .owl-item {
      width: 315px;
    }
    @include max-width($desktop-xl) {
      .flexibale-img {
        min-height: 614px;
      }
      .flexiable-info {
        .heading-section {
          .main-title {
            font-size: 52px;
            line-height: 60px;
            letter-spacing: -2px;
          }
        }
      }
    }
    @include max-width($desktop-large) {
      .flexibale-img {
        min-height: 531px;
      }
      .flexiable-info {
        .learn-more {
          font-size: 18px;
          letter-spacing: -1px;
          line-height: 24px;
        }
        .heading-section {
          .main-title {
            font-size: 40px;
            line-height: 48px;
          }
        }
      }
    }
    @include max-width($macbook) {
      .flexibale-img {
        height: 504px;
        min-height: auto;
      }
    }

    @include max-width($tablet-landscape) {
      display: block;
      height: 100%;
      position: relative;
      border: 1px solid #d2d0d0;
      &.left-img {
        .flexibale-img {
          order: 0;
        }
      }
      .flexibale-img {
        height: 274px;
        width: 100%;
        border: unset;
        min-height: auto;
      }
      .flexiable-info {
        width: 100%;
        padding: 12% 6% 24%;
        border: unset;
        .learn-more {
          position: absolute;
          bottom: 4%;
        }
      }
    }
  }

  @include max-width($tablet-landscape) {
    .owl-stage {
      display: flex !important;
    }
    .flexiable-info {
      .heading-section {
        .main-title {
          font-size: 52px;
          line-height: 54px;
        }
      }
    }
  }
  @include max-width($iphonex-landscape) {
    padding: 10% 5%;
  }
  @include max-width($iphone4-landscape) {
    padding: 15% 10%;
    .owl-stage {
      padding-left: 0px !important;
    }
  }
}
