@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

header {
  float: none;
  width: 100%;
  padding: 26px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  // background-image: linear-gradient(to right, rgba(270,270,270,1), rgba(0,0,0,0));
  background: white;
  transition: all 1s;

  .headerlogoarea {
    float: left;
    width: 100%;
    .hamburgerBtn {
      float: left;
      position: relative;
      height: 76px;
      width: 76px;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;
      cursor: pointer;
      padding: 22px;
      pointer-events: all;
      display: flex;
      border: 1px solid #d5d5d5;

      > span {
        width: 100%;
        height: 2px;
        background: #545454;
        transition: all 0.5s ease;
        &.text {
          background: none;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 1.2px;
          color: #545454;
          text-transform: uppercase;
          margin: 0 0 0 -4px;
          font-family: $arquitecta-heavy;
        }
      }

      &.active > span:first-child {
        transform: rotate(-45deg) translate(-10px, 7px);
      }

      &.active > span:nth-child(2) {
        opacity: 0;
      }

      &.active > span:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -4px);
      }
    }

    .logoWrapper {
      float: left;
      padding: 0 0 0 24px;
      img {
        width: 65%;
        margin: 15px 0 0;
      }
    }
  }

  .menu-items {
    position: absolute;
    z-index: 999999;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background: #ffffff;
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 3% 7.8% 5%;
    overflow: scroll;
    height: 90vh;

    .menu-listing {
      flex: 25%;
      text-align: left;
      padding: 0 10px 0 0;
      .primary-menu {
        margin-bottom: 33px;
      //  padding-top: 97px;

        ul {
          margin-left: 0px;
          padding-left: 0px;
          li {
            list-style: disc;
            color: #febf3b;
            margin-bottom: 25px;

            // &.header_login_button{
            //   > a{
            //     cursor: unset;
            //     &:hover{
            //       text-decoration: none;
            //     }
            //   }
            // }

            &.btn {
              list-style: none;
              .tour-schedule{
                text-align: center;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 3.2px;
                color: #e5721f;
                text-transform: uppercase;
                border: 1px solid #e5721f;
                opacity: 1;
                padding: 14.5px 65.7px;
                display: inline-block;
                margin: 0px 0 0 -30px;
                transition: all 0.5s ease;
                font-family: $atten-font;
            font-weight: $font-atten-book;

                text-decoration: none;
                &:hover {
                  color: $white;
                  background: #e5721f;
                }
              }
            }

            a {
              text-align: left;
              font-size: 20px;
              line-height: 36px;
              letter-spacing: 0px;
              color: #545454;
              opacity: 1;
            }

            ul li {
              list-style: none;
              margin-bottom: 0px;
              a {
                font-size: 16px;
                letter-spacing: 0px;
                color: #969696;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .latest-home-listing {
      flex: 60.33%;
      .heading {
        text-align: left;
        flex: 100%;
        margin: 0 0 57px;
        h2 {
          line-height: 50px;
          font-size: 68px;
          letter-spacing: 0px;
          color: #545454;
          opacity: 1;
          font-weight: 400;
        }
      }

      .highlighted-home {
        display: flex;
        flex: 100%;

        .home {
          flex: 33.3%;
          text-align: left;
          margin: 0 40px 0 0px;
          position: relative;
          height: 425px;
          // a{
          //     float: left;
          //     width: 100%;
          // }

          &:last-child {
            margin-right: 0;
          }

          img {
            float: left;
            max-width: 100%;
          }

          h2 {
            float: left;
            width: 100%;
            text-align: left;
            font-size: 28px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #545454;
            opacity: 1;
            font-weight: 400;
            padding: 30px 0 5px;
            span {
              width: 100%;
              float: left;
            }
          }

          span.tagline {
            text-align: left;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0px;
            color: #545454;
            opacity: 1;
            float: left;
            width: 100%;
          }

          .arrowRight {
            border-color: #e5721f;
            position: absolute;
            top: 15%;
            right: 21px;
            border: 1px solid #e5721f;
            height: 45px;
            width: 45px;
            display: none;

            &:after {
              background: #e5721f;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              height: 2px;
              width: 16px;
              content: " ";
              position: absolute;
              top: 50%;
              left: 45%;
            }

            &:before {
              width: 8px;
              height: 8px;
              border-top: 2px solid #e5721f;
              border-right: 2px solid #e5721f;
              -webkit-transform: translate(-1px, -50%) rotate(45deg);
              transform: translate(-1px, -50%) rotate(45deg);
              content: " ";
              position: absolute;
              top: 50%;
              left: 45%;
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  @include max-width($desktop-xl) {
    .headerlogoarea {
      .hamburgerBtn {
        margin-left: 10px;
      }
    }

    .menu-items {
      padding: 2% 9% 5%;
      .menu-listing {
        .primary-menu {
          ul li {
            &.btn {
              .tour-schedule{
                padding: 14.5px 45px;
              }
            }
          }
        }
      }
      .latest-home-listing {
        .heading {
          h2 {
            line-height: 40px;
            font-size: 55px;
          }
        }
        .highlighted-home {
          .home {
            height: auto;
            h2 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @include max-width(1200px) {
    .menu-items {
      .menu-listing {
        .primary-menu {
          ul li {
            &.btn .tour-schedule {
              padding: 14.5px 34px;
            }
          }
        }
      }
    }
  }

  @include max-width($iphonex-landscape) {
    .headerlogoarea {
      .hamburgerBtn {
        margin-left: 5px;
      }
    }

    .menu-items {
      flex-flow: column;
      .menu-listing {
        order:1;
        .primary-menu {
          margin-bottom: 20px;
          padding-top: 29px;
        }
      }
      .latest-home-listing {
        padding: 7% 0 16%;
        order:0;
        .heading {
          margin: 0 0 37px;
          h2 {
            line-height: 50px;
            font-size: 52px;
          }
        }

        .highlighted-home {
          .home {
            margin: 0 20px 0 0px;
            h2 {
              font-size: 20px;
              padding: 15px 0 5px;
              line-height: 28px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @include max-width($iphone6-plus-landscape) {
    .headerlogoarea {
      .hamburgerBtn {
        height: 55px;
        width: 55px;
        padding: 15px;

        > span {
          &.text {
            margin: 0 0 0 -6px;
          }
        }

        &.active > span.text {
          line-height: 12px;
        }

        &.active > span:first-child {
          transform: rotate(-45deg) translate(-6px, 5px);
        }

        &.active > span:nth-child(3) {
          transform: rotate(45deg) translate(-5px, -5px);
        }
      }

      .logoWrapper {
        float: left;
        padding: 0 0 0 24px;
        width: 75%;
        img {
          width: 135px;
          margin: 10px 0 0;
        }
      }
    }

    .menu-items {
      top: 62px;
      padding: 4.5% 0%;

      .menu-listing {
        .primary-menu {
          margin-bottom: 20px;
          padding: 29px 40px 50px;
          ul li {
            margin-bottom: 23px;
            a {
              font-size: 16px;
              line-height: 26px;
            }
            &.btn {
              width: 100%;
              .tour-schedule {
                width: 100%;
                font-size: 14px;
                padding: 8.5px 20.7px;
                // margin: 0 0 0 -58px;
              }
            }
          }
        }
      }

      .latest-home-listing {
        flex: 75%;
        padding: 7% 0 2%;
        .heading {
          text-align: left;
          flex: 100%;
          margin: 0px 0 12px;
          padding: 0 20px;
          h2 {
            line-height: 42px;
            font-size: 42px;
          }
        }

        .highlighted-home {
          display: inline-block;
          .home {
            flex: 33.3%;
            height: auto;
            text-align: left;
            margin: 0 38px 0 0px;
            border-bottom: 1px solid #ededed;
            padding: 4px 20px 24px;
            display: inline-block;
            width: 100%;
            h2 {
              font-size: 19px;
              line-height: 22px;
              padding: 10px 0 5px;
            }
            span.tagline {
              font-size: 14px;
              line-height: 16px;
            }
            img {
              display: none;
            }

            .arrowRight {
              display: block;
            }
          }
        }
      }
    }
  }
  
}
