@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.agent_button{
    padding: 5%;
    background-color: $bglightgray;
    text-align: center;
    .sectiontitle-main-div{
        padding-top: 0;
    }
    a{
        @include green-filled-button;

        &:hover,&:focus{
            color: $black-new;
        }
    }
}
.agent_section{

    width: 40%;
    margin: auto;
    padding: 5%;
    color: $black-new;

    .agent_form_heading{
        font-size: 40px;
        margin: 0;
    }

    input,select{
        height: 52px;
        font-size: 16px;
        line-height: 52px;
        margin-bottom: 15px;
        padding: 0 15px;
        color: $black-new;
        width: 100%;
        background: $white;
        border: 1px solid $white;
        outline: unset;
        font-family: $font-primary;
    }
    #submitbutton{
        margin-top: 20px;
        width: 100%;
        @include green-filled-button;
    }

    .agent_form_disclaimer {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        margin-top: 25px;
        color: $black-new;
        a{
          &.form_privacy{
            text-decoration: underline;
            &:hover{ 
              color: $orange;
              text-decoration: underline;
            }
          }
          &:hover{   
            color: $orange;
            text-decoration: underline;
          }
        }
    }

    @include max-width($desktop-large) {
        .agent_form_heading{
            font-size: 35px;
        }
        .agent_form_disclaimer {
            font-size: 11px;
        }
    }
    @include max-width($tablet-landscape) {
        width: 50%;
    }
    @include max-width($iphone6-plus-landscape) {
        width: 65%;
    }
    @include max-width($iphone4-landscape) {
        width: 100%;
    }
}

.divSuccess{
    margin: 0;
    p{
        font-size: 20px;
        margin: 0;
    }
}
.divLoading {
    margin-bottom: 20px;
    text-align: center;
    display: block;
    border: 1px solid #ccc;
    padding: 15px;
    background: #f5f5f5;
    color: $black-new;
}
.parsley-errors-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
    .parsley-custom-error-message{
        border-left: 6px solid $orange;
        padding: 10px;
        background: #f5f5f5;
        color: $black-new;
        margin-bottom: 15px;
    }
    .parsley-required{
        border-left: 6px solid $orange;
        padding: 10px;
        background: #f5f5f5;
        color: $black-new;
        margin-bottom: 15px;
    } 
    .parsley-type{
        border-left: 6px solid $orange;
        padding: 10px;
        background: #f5f5f5;
        color: $orange;
        margin-bottom: 15px;
    }
}
.divSuccess {
    padding: 15px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
}
.errorDiv {
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
}