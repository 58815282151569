@import "../../assets/scss/partials/variables";
@import "../../assets/scss/partials/mixins";
@import "../../assets/scss/partials/fonts";

.heading-section {
  text-align: center;
  width: 48%;
  margin: 0;
  .sub-title {
    padding-top: 5px;
    text-transform: uppercase;
    font-family: $arquitecta-bold;
    font-size: 16px;
    letter-spacing: 4.8px;
    color: $gray-new;
    line-height: 25px;
    margin: 0;
    color: #e5721f;
  }
  .main-title {
    font-family: $font-primary;
    font-size: 48px;
    color: #545454;
    padding: 18px 0 17px;
    word-break: break-word;
    margin: 0;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 50px;
    width: 100%;
  }
  .orange-color {
    color: $orange;
  }
  .gray-color {
    color: $gray-new;
  }
  .white-color {
    color: $white;
  }
  .new-black-color {
    color: $black-new;
  }
  @include max-width($desktop-xl) {
    .main-title {
      font-size: 45px;
      line-height: 58px;
      letter-spacing: 0px;
    }
  }
  @include max-width($desktop-large) {
    .main-title {
      font-size: 48px;
      line-height: 50px;
    }
    .sub-title {
      font-size: 14px;
      letter-spacing: 4.2px;
      line-height:25px;
    }
  }
  @include max-width($tablet-landscape) {
    width: 55%;
    .main-title {
      font-size: 32px;
      line-height: 36px;
    }
    // .sub-title {
    //   font-size: 12px;
    //   letter-spacing: 4.8px;
    //   line-height: 16px;
    // }
  }
  @include max-width($tablet) {
    width: 80%;
  }
  @include max-width($iphone4-landscape) {
    width: 100%;
  }
}
.spring-section {
  background-color: $orange;
  padding: 7% 5%;
  overflow: hidden;
  .heading-section {
    text-align: left;
    width: 100%;
    p,
    h2 {
      color: $white;
      width: 50%;
    }
    .main-title {
      font-size: 48px;
      line-height: 50px;
      letter-spacing: 0px;
    }
  }
  .text-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .title-info {
    color: $black-new;
    width: 50%;
    font-size: 20px;
    margin: 0;
    line-height: 24px;
    // padding-top: 30px;
    p {
      margin: 0;
      color: $white;
    }
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  .learn-more {
    @include white-learn-more;
    float: right;
    font-size: 16px !important;
    letter-spacing: 3.2px;
    line-height: 25px;
    min-width: 314px;
    background-color: $white;
    &:hover {
      color: $white;
      background-color: $orange;
    }
    &.no-link {
      pointer-events: none;
    }
  }
  @include max-width($desktop-xl) {
    .heading-section {
      .main-title {
        font-size: 52px;
        line-height: 60px;
        letter-spacing: -2px;
      }
    }
  }
  @include max-width($desktop-large) {
    .title-info {
      padding-top: 0;
    }
    .heading-section {
      .main-title {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .learn-more {
      min-width: 250px;
      font-size: 13px !important;
      letter-spacing: 2px;
    }
  }
  @include max-width($tablet-landscape) {
    .heading-section {
      .main-title {
        font-size: 52px;
        line-height: 54px;
      }
    }
  }
  @include max-width($iphonex-landscape) {
    padding: 10% 5%;
    .heading-section {
      .main-title {
        font-size: 40px;
        line-height: 42px;
      }
      p,
      h2 {
        width: 100%;
      }
    }
    .title-info {
      width: 100%;
      padding-bottom: 20px;
    }
    .text-flex {
      display: block;
    }
    .learn-more {
      float: none;
      margin-top: 5%;
      margin-bottom: 5px;
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .heading-section {
      .main-title {
        font-size: 32px;
        line-height: 34px;
      }
    }
  }
  @include max-width($iphone6-landscape) {
    //padding: 15% 5px;
  }
}
.testimonals-main {
  position: relative;
  // float: left;
  width: 100%;
  .owl-stage {
    display: flex;
    &:after {
      display: none;
    }
  }
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    margin: 0;
    margin-top: -17px;
    button {
      border-radius: 50% !important;
      position: absolute;
      margin: 0;
      outline: none;
      &:after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
        top: 43%;
        right: 20px;
      }
      span {
        display: none;
      }
    }
    .owl-prev {
      left: 0;
      &:after {
        transform: rotate(135deg);
        left: 20px;
      }
    }
    .owl-next {
      right: 0;
    }
  }
  .owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    outline: none;
    .owl-dot {
      outline: none;
      span {
        background: #869791 !important;
        outline: none;
      }
      &.active {
        span {
          background: $white !important;
        }
      }
    }
  }
  @include max-width($desktop-large) {
    .owl-nav {
      button {
        &:after {
          width: 15px;
          height: 15px;
          right: 10px;
        }
      }
      .owl-prev {
        &:after {
          left: 10px;
        }
      }
    }
  }
  @include max-width($iphone4-landscape) {
    .owl-nav {
      button {
        &:after {
          width: 10px;
          height: 10px;
          right: 2px;
        }
      }
      .owl-prev {
        &:after {
          left: 2px;
        }
      }
    }
  }
}
.testimonals-section {
  background-color: $orange;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .heading-section {
    text-align: left;
    width: 100%;
    margin: 0;
    p,
    h2 {
      color: $white;
      width: 100%;
    }
    @include max-width($tablet-landscape) {
      .main-title {
        font-size: 52px;
        line-height: 54px;
      }
    }
  }
  .small-text {
    padding-top: 5px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 14px;
    letter-spacing: 5.6px;
    color: $white;
    line-height: 19px;
    margin: 0;
    @include max-width($tablet-landscape) {
      font-size: 12px;
      letter-spacing: 4.8px;
      line-height: 16px;
    }
  }
}
.faq-section {
  padding: 5%;
  // float: left;
  width: 100%;
  .sectiontitle-main-div {
    padding: unset;
    .sectiontitle-headline {
      font-size: 68px;
      color: $black-new;
      padding-bottom: 20px;
    }
  }
  .faq-section-subtext {
    font-size: 20px;
    line-height: 30px;
    color: $black-new;
  }
  .accordion {
    padding-top: 5%;
    width: 70%;
    margin: auto;
    .card {
      border: none;
      &:last-child {
        .collapse,
        .collapsing {
          .card-body {
            padding: 0;
          }
        }
      }
      .card-header {
        background: transparent;
        padding: 0;
        border: none;
        button {
          text-decoration: none !important;
          width: 100%;
          padding: 0;
          box-shadow: none;
          border: none;
          .ques-heading {
            display: flex;
            width: 100%;
            text-align: left;
            justify-content: space-between;
            color: $black-new;
            align-items: center;
            margin: 0 0 20px;
            p {
              margin: 0;
              font-size: 20px;
              font-family: $font-primary;
            }
            i {
              font-size: 25px;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }

      .collapse,
      .collapsing {
        order: 1;
        &.show ~ .card-header {
          .ques-heading {
            i {
              transform: rotate(-90deg);
            }
          }
        }
        .card-body {
          padding: 0 0 20px 0;
        }
      }

      .collapsing ~ .card-header {
        .ques-heading {
          i {
            transform: rotate(-90deg);
            transition: transform 0.5s ease-in-out;
          }
        }
      }
    }
  }

  @include max-width($desktop-large) {
    .sectiontitle-main-div {
      .sectiontitle-headline {
        font-size: 40px;
      }
    }
  }

  @include max-width($iphonex-landscape) {
    width: 100%;
    padding: 15% 5%;
    .accordion {
      width: 100%;
      .card {
        .card-header {
          button {
            .ques-heading {
              p {
                font-size: 16px;
              }
              i {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  @include max-width($iphone6-plus-landscape) {
    .accordion {
      width: 100%;
    }
  }
  @include max-width($iphone4-landscape) {
    padding: 5% 5%;
  }
}
